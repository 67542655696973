@import "../../theme/variables.scss";

.hero {
  display: flex; // safari fix for element height
  flex-direction: column; // safari fix for element height
  position: relative;
  font-size: 16px;
  font-weight: 300;
  color: white;
  background-size: 0;

  @include for-size(tablet-landscape-up) {
    margin-top: 0;
    min-height: 700px;
    overflow: hidden;
    background: $darkBlue2;
  }

  // background for small screen
  &:after {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    top: 100px;
    right: 10px;
    bottom: 0;
    left: 10px;
    border-radius: 30px 30px 10px 10px;
    background: $darkBlue2;

    @include for-size(tablet-landscape-up) {
      display: none;
    }
  }

  &--reverse-layout {
    &:after {
      @include for-size(phone-only) {
        top: 0;
      }
    }
  }
}

.bg {
  display: none;

  @include for-size(tablet-landscape-up) {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.content {
  display: flex;
  flex-grow: 1; // safari fix for element height
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: auto;
  width: $siteMaxWidth;
  height: 100%;
  max-width: 100%;

  @include for-size(tablet-landscape-up) {
    align-items: flex-start;
    min-height: 600px;
  }

  &--reverse-layout {
    @include for-size(phone-only) {
      flex-direction: column-reverse;
    }
  }
}

.text {
  padding: 0 $gutterSmall $gutterSmall;
  width: calc(600px + 2 * #{$gutterLarge});
  max-width: calc(100% - 2 * #{$gutterSmall});
  min-height: 100px;

  @include for-size(tablet-portrait-up) {
    padding: $gutterLarge $gutterMedium;
  }

  @include for-size(tablet-landscape-up) {
    padding: $gutterExtraLarge $gutterLarge;
  }

  &--reverse-content {
    @include for-size(phone-only) {
      padding: $gutterSemiLarge $gutterSmall 0;
    }

    &.text--gap-medium {
      @include for-size(phone-only) {
        margin-top: 0;
        margin-bottom: $gutterMedium;
      }
    }

    &.text--gap-semi-large {
      @include for-size(phone-only) {
        margin-top: 0;
        margin-bottom: $gutterSemiLarge;
      }
    }
  }

  &--gap-medium {
    @include for-size(phone-only) {
      margin-top: $gutterMedium;
    }
  }

  &--gap-semi-large {
    @include for-size(phone-only) {
      margin-top: $gutterSemiLarge;
    }
  }
}

.illustration {
  display: flex;
  justify-content: center;
  width: 100%;
  pointer-events: none;

  @include for-size(tablet-landscape-up) {
    position: absolute;
    right: 0;
    bottom: 0;
    margin-top: auto;
    width: 30%;
  }
}

.cta {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: $gutterLarge;

  @include for-size(tablet-portrait-up) {
    flex-direction: row;
    justify-content: space-between;
  }

  > * {
    &:nth-child(n + 2) {
      margin-top: $gutterMedium;
    }
  }

  > *:nth-child(2) {
    @include for-size(tablet-portait-up) {
      margin-bottom: $gutterMedium;
    }
  }

  > :not(:last-child) {
    @include for-size(tablet-portrait-up) {
      align-self: flex-end;
      margin-right: $gutterSmall;
    }
  }

  &--row {
    flex-direction: row;

    @include for-size(tablet-portait-up) {
      flex-direction: column;
    }

    @include for-size(phone-only) {
      flex-direction: column;
    }
  }

  &--reverse-layout {
    @include for-size(phone-only) {
      flex-direction: column-reverse;
    }
  }

  &--reverse-buttons {
    flex-direction: column-reverse;

    > *:nth-child(n + 2) {
      @include for-size(phone-only) {
        margin-top: 0;
        margin-bottom: $gutterMedium;
      }
    }
  }
}

.button-wrap {
  display: grid;
  gap: $gutterMedium;
  width: 100%;

  @include for-size(tablet-portrait-up) {
    grid-template-columns: 1fr 1fr;
  }

  @include for-size(tablet-landscape-up) {
    grid-template-columns: unset;
  }

  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    justify-content: start;
  }

  @include for-size(desktop-up) {
    grid-template-columns: 1fr 1fr;
  }
}

.disclaimer {
  margin: $gutterSmall 0;
  font-size: 12px;
  font-weight: 300;
  font-style: italic;
  opacity: 0.7;

  @include for-size(phone-only) {
    order: -1;
  }
}
