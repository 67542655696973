@import "../../theme/variables.scss";

.menu-wrap {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: fixed;
  z-index: 500;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(180deg, #feb362 0%, #fe7a2f 100%);
}

.flame-icon {
  position: absolute;
  top: 30%;
  width: 100vw;
  overflow: visible;
}

.menu {
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 600;
  right: 0;
  padding: $gutterMedium;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.menu-items-wrap {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  margin-bottom: $gutterLarge;
  margin-left: $gutterSemiLarge;
}

.counter {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: $gutterSmall;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: #fff;
  background: rgb(255 255 255 / 30%);
}
