@import "../../../theme/variables";

.image-wrap {
  min-width: 380px;
}

.heading {
  font-size: 20px;
  line-height: normal;
  font-weight: bold;

  @include for-size(tablet-landscape-up) {
    font-size: 30px;
  }
}

.list {
  margin: 0;
  padding: 0;
  color: $darkBlue2;
  line-height: 19px;

  @include for-size(phone-only) {
    font-size: 14px;
    line-height: 17px;
  }
}

.item {
  display: flex;
  margin-bottom: $gutterSemiLarge;
}

.icon {
  flex: 0 0 15px;
  margin: 0 $gutterSmall 0 $gutterMedium;

  @include for-size(tablet-landscape-up) {
    margin: 0 $gutterSmall 0 $gutterSemiLarge;
  }
}
