@import "../../theme/variables.scss";

.button {
  height: 55px;
}

.video {
  &-information {
    display: flex;
    flex-direction: column;
    margin: 0 $gutterSmall;
    text-align: left;
    white-space: nowrap;

    &__title {
      color: $yellow;
      font-size: 14px;
      line-height: 17px;
    }

    &__length {
      color: #fff;
      font-size: 14px;
      font-weight: 300;
      line-height: 17px;
    }
  }
}

.videoModal {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  outline: none;
  width: 100%;
  transform: translate(-50%, -50%);
  color: #fff;
  background: rgb(255 255 255 / 0%);

  @include for-size(tablet-portrait-up) {
    width: 90%;
  }

  @include for-size(tablet-landscape-up) {
    width: 80%;
  }

  @include for-size(desktop-up) {
    width: 60%;
  }
}

.iFrameContainer {
  position: relative;
  padding-bottom: 56.25%;
  width: 100%;
  height: 0;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.modalHeader {
  display: flex;
  justify-content: flex-end;

  span {
    display: flex;
    align-items: center;
    padding: 16px 4px;
    font-size: 16px;
    cursor: pointer;

    svg {
      margin-bottom: -3px;
      margin-left: 16px;
    }
  }
}

.modalBackground {
  position: fixed;
  z-index: 3;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgb(0 0 0 / 60%);
}

.align-left {
  align-self: flex-start;
}
