@import "../../../theme/variables";

.wrap {
  margin-bottom: 140px;
  width: $mobileWidth;
  max-width: 100%;
  line-height: 30px;

  @include for-size(tablet-landscape-up) {
    width: $siteMaxWidth;
  }
}

.columns {
  gap: $gutterLarge !important;

  @include for-size(tablet-portrait-up) {
    gap: 70px !important;
  }

  @include for-size(desktop-up) {
    gap: 85px 120px !important;
  }
}
