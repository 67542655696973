@import "../../theme/variables.scss";

// Link to make burger open and close
//https://codepen.io/erikterwan/pen/EVzeRP

.header-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: 1480px;
  margin: 0 auto;
  width: 100%;
  padding: 0 40px;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: sticky;
  z-index: 50;
  top: 0;
  gap: 8px;
  height: 60px;
  background: #fff;

  @include for-size(tablet-landscape-up) {
    height: 80px;
  }

  &--primary,
  &--has-navigation {
    justify-content: space-between;
  }

  &--secondary,
  &--tertiary {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &--gutter-medium {
    margin-bottom: $gutterMedium;
  }
}

.header-link {
  text-decoration: none;
  color: $darkBlue;
  transition: color 0.2s ease-in-out;

  &:hover {
    color: $sFOrange;
    transition: color 0.2s ease-in-out;
  }
}

.nav-link-wrap {
  display: none;

  @include for-size(tablet-landscape-up) {
    display: block;
    flex: 1;
  }

  > :nth-child(n + 2) {
    margin-left: $gutterLarge;
  }
}

.active-link {
  position: relative;

  &:after {
    content: "";
    position: absolute;
    top: calc(100% + 4px);
    left: 0;
    width: 100%;
    height: 2px;
    background: $colorDarkBlue;
  }
}

.logo {
  width: 69px;
  height: 29px;

  @include for-size(tablet-portrait-up) {
    width: 93px;
    height: 39px;
  }

  @include for-size(big-desktop-up) {
    width: 120px;
    height: 51px;
  }

  &--secondary {
    width: 90px;
    height: 50px;
  }
}

.header-icons {
  display: none;

  @include for-size(tablet-landscape-up) {
    display: flex;
    justify-content: right;
    flex: 1;
  }

  & > *:nth-child(n + 2) {
    margin-left: 30px;
  }
}

.login,
.sign-up {
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: 10px;
  font-weight: bold;
  color: $darkBlue2;
  text-transform: uppercase;
  stroke: white;
  transition: color 0.2s ease-in-out;

  &:hover {
    color: $sFOrange;
    transition: color 0.2s ease-in-out;

    .login-icon,
    .sign-up-icon {
      stroke: $sFOrange;
      transition: stroke 0.2s ease-in-out;
    }
  }
}

.login-icon,
.sign-up-icon {
  margin: 6px;
  width: 24px;
  height: 24px;
  stroke: $darkBlue2;
  transition: stroke 0.2s ease-in-out;
}

.burger-wrap {
  display: flex;
  align-items: center;

  @include for-size(tablet-landscape-up) {
    display: none;
  }
}

.logout-link {
  display: block;
  position: absolute;
  top: 50%;
  right: $gutterSmall;
  transform: translateY(-50%);
  text-decoration: none;

  @include for-size(tablet-landscape-up) {
    right: $gutterLarge;
  }
}
