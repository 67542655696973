@import "../../theme/variables";

.wrap {
  padding: 15px $gutterLarge 15px $gutterSemiLarge;
  width: 100%;
  color: $darkBlue;
}

.title {
  margin-right: 5px;
  margin-left: $gutterMedium;
  font-weight: bold;

  &--SECONDARY {
    color: $lightBlue2;
  }
}

.ribbon {
  fill: $offWhite2 !important;
}

.details-button {
  margin-left: 50px;
}
