@import "../../../../theme/variables";

.totals {
  color: $darkBlue;
}

.shipping-country {
  display: flex;
  align-items: center;
  margin-bottom: $gutterMedium;

  label {
    margin-bottom: 0;
  }

  .select {
    flex-grow: 1;
    margin-left: $gutterMedium;
  }
}
