@import "../../theme/variables";

.wrap {
  display: grid;
  color: $lightGrey;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: $semiBold;
  letter-spacing: 3px;
}

.bottom-line {
  &:after {
    content: "";
    width: 100%;
    height: 1px;
    margin: 8px 0;
    max-width: 400px;
    background-color: $offWhite5;
  }
}
