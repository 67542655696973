@import "../../theme/variables";

.product-badge {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 5px;
  height: 30px;

  &--YELLOW {
    color: $yellow;
    background: #fff9eb;
  }

  &--GREEN {
    color: $green2;
    background: #eafaf4;
  }
}

.product-badge-text {
  font-weight: $extraBold;
  text-transform: uppercase;
  letter-spacing: 0;
  font-size: 12px;
  color: inherit;
}
