@import "../../theme/variables.scss";

.wrap {
  display: flex;
  flex-direction: column;

  ::-webkit-scrollbar {
    border-radius: 5px;
    width: 10px;
    height: 10px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 5px;
    background: $offWhite4;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: $lightGrey;
  }
}

.back-button {
  position: absolute;
  left: $gutterMedium;
  transform: translateY(50%);
}

.panel {
  margin: $gutterLarge 0;
  border-radius: unset;
  overflow: auto;
}

.content {
  flex-grow: 1;
  margin: 0 $gutterSmall 0 0;

  @include for-size(phone-only) {
    margin-right: 0;
    margin-left: 0;
  }

  ::-webkit-scrollbar {
    border-radius: 5px;
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 5px;
    background: $offWhite4;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: $lightGrey;
  }
}

.button {
  @include for-size(tablet-portrait-up) {
    flex-basis: 100%;
  }

  & + & {
    @include for-size(phone-only) {
      margin-top: $gutterSmall;
    }

    @include for-size(tablet-portrait-up) {
      margin-left: $gutterSmall;
    }
  }
}

.preferences-container {
  overflow-y: auto;
  margin-bottom: $gutterMedium;
  max-height: 400px;
}

.cookie-description {
  padding-right: $gutterSmall;
}
