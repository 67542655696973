@import "../../../../theme/variables";

.titlebar {
  margin-bottom: 0;
}

.tabs-wrap {
  display: flex;
  margin-right: $gutterSmall;
  margin-bottom: 50px;
  margin-left: $gutterSmall;

  @include for-size(tablet-portrait-up) {
    margin-right: $gutterMedium;
    margin-left: $gutterMedium;
  }
}

.title-wrap {
  display: none;

  @include for-size(tablet-landscape-up) {
    display: grid;
    grid-template-columns: 1fr 1fr 0.5fr 0.5fr;
    grid-gap: $gutterSmall;
    padding: 0 $gutterLarge;
    width: 900px;
    max-width: 100%;
    text-align: left;
  }
}

.title {
  text-transform: uppercase;
  font-size: 12px;
  color: $lightBlue2;
}

.title-offset {
  margin-left: calc(#{$gutterLarge} + 25px);
}

.listing {
  display: grid;
  grid-gap: $gutterSmall;
  justify-content: center;
  align-items: center;
  border-radius: $radiusLarge;
  padding: $gutterMedium $gutterLarge;
  width: 900px;
  max-width: 100%;
  text-align: center;
  color: $secondaryText;
  background: $offWhite2;

  @include for-size(tablet-landscape-up) {
    grid-template-columns: 1fr 1fr 0.5fr 0.5fr;
    text-align: left;
  }

  & + & {
    margin-top: $gutterMedium;
  }
}

.name-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: $light;

  @include for-size(tablet-landscape-up) {
    flex-direction: row;
  }
}

.status-icon {
  margin-bottom: $gutterSmall;

  @include for-size(tablet-landscape-up) {
    margin-right: $gutterLarge;
    margin-bottom: 0;
  }
}

.email {
  font-size: 12px;
  font-weight: $semiBold;
  color: $lightBlue2;
}

.date-wrap {
  display: flex;
  gap: 5px;
  flex-direction: column;
  justify-content: center;
  font-size: 14px;
}

.date {
  display: flex;
  align-items: center;
}

.date-icon {
  margin-right: $gutterSmall;
}

.activity {
  font-size: 14px;
  font-weight: $light;
}
