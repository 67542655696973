@import "./src/theme/variables";

.language-label {
  margin-right: 5px;
  font-size: 12px;
  line-height: 20px;
  font-weight: $light;
  color: #fff;
  opacity: 0.5;
}

.language-dropdown {
  margin: 0;
  border: none;
  font-weight: $medium;
  font-size: 12px;
  line-height: 20px;
  color: #fff;
  opacity: 0.5;
  background: none;

  &::-ms-expand {
    display: none;
  }

  &:hover {
    border-color: $lightGrey;
  }

  &:focus {
    outline: none;
    color: $primaryText;
    border-color: $lightGrey;
  }
}

.language {
  font-weight: $normal;
}

.link-wrap {
  display: flex;
  flex-direction: column;
  flex-shrink: 0; // fix safari overflow bug on small screens
  align-items: center;
  margin-bottom: $gutterMedium;
}

.link {
  margin: 0;
  text-decoration: none;
  font-size: 12px;
  line-height: 20px;
  font-weight: $light;
  color: #fff;
  opacity: 0.5;

  a {
    color: #fff; // overwrite FooterItem default
  }
}
