@import "../../theme/variables.scss";

$tooltipRadius: 8px;

.content {
  &:not(.opened) {
    z-index: -1000;
    transform: translate(
      0
    ) !important; // solves edge-case where a list of tooltip reference elements are inside a wrap with overflow scroll and the content box starts creating scrollbar on whole view.
    // display: none; // don't use this rule because it breaks the positioning of react-popper
    // width: 0; // this rule can't be used as well, popper needs to know the width and height of the object
    // height: 0; // this rule can't be used as well, popper needs to know the width and height of the object
    opacity: 0;
    user-select: none;
  }

  &[data-popper-placement="top"] {
    &.opened {
      margin-bottom: 8px;
    }

    .arrow {
      bottom: 0;
      padding: 0 $tooltipRadius;

      &:after {
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        border-color: $darkBlue transparent transparent transparent;
      }
    }
  }

  &[data-popper-placement="right"] {
    &.opened {
      margin-left: 8px;
    }

    .arrow {
      left: 0;
      padding: $tooltipRadius 0;

      &:after {
        top: 50%;
        right: 100%;
        transform: translateY(-50%);
        border-color: transparent $darkBlue transparent transparent;
      }
    }
  }

  &[data-popper-placement="bottom"] {
    &.opened {
      margin-top: 8px;
    }

    .arrow {
      top: 0;
      border-radius: $tooltipRadius;
      padding: 0 $tooltipRadius;

      &:after {
        bottom: 100%;
        left: 50%;
        transform: translateX(-50%);
        border-color: transparent transparent $darkBlue transparent;
      }
    }
  }

  &[data-popper-placement="left"] {
    &.opened {
      margin-right: 8px;
    }

    .arrow {
      right: 0;
      padding: $tooltipRadius 0;

      &:after {
        top: 50%;
        left: 100%;
        transform: translateY(-50%);
        border-color: transparent transparent transparent $darkBlue;
      }
    }
  }
}

// opened state
.opened {
  z-index: 100;
  box-shadow: 0 0 20px -1px rgb(46 48 51 / 15%);
  border-radius: $tooltipRadius;
  padding: $gutterSemiLarge;
  height: auto !important; // fix to overwrite field-related rule
  line-height: 16px;
  color: #fff;
  background-color: $darkBlue;
}

.arrow {
  width: calc(#{$tooltipRadius} * 2 + 8px);
  height: calc(#{$tooltipRadius} * 2 + 8px);

  &:after {
    content: "";
    position: absolute;
    border-width: 6px;
    border-style: solid;
  }
}
