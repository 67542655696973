@import "./variables.scss";

@font-face {
  font-family: Signpainter;
  font-style: normal;
  src: url("./fonts/signpainter-housescript-webfont.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/DMSans-Regular.woff") format("woff");
}

@font-face {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  src: url("./fonts/DMSans-Medium.woff") format("woff");
}

@font-face {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  src: url("./fonts/DMSans-Bold.woff") format("woff");
}

@font-face {
  font-family: BebasNeueBold;
  font-style: normal;
  font-weight: 700;
  src: url("./fonts/BebasNeue-Bold.woff") format("woff");
}

@font-face {
  font-family: Didot;
  font-style: normal;
  font-weight: 700;
  src: url("./fonts/Didot-Regular.woff") format("woff");
}

@font-face {
  font-family: "Portrait Script";
  src: url("./fonts/portraitscript-regular-webfont.woff2") format("woff2"),
    url("./fonts/portraitscript-regular-webfont.woff") format("woff"),
    url("./fonts/portraitscript-regular-webfont.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Portrait Script Alt";
  src: url("./fonts/portraitscript-alts-bold-webfont.woff2") format("woff2"),
    url("./fonts/portraitscript-alts-bold-webfont.woff") format("woff"),
    url("./fonts/portraitscript-alts-bold-webfont.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Portrait Script Alt";
  src: url("./fonts/portraitscript-alts-webfont.woff2") format("woff2"),
    url("./fonts/portraitscript-alts-webfont.woff") format("woff"),
    url("./fonts/portraitscript-alts-webfont.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Portrait Script";
  src: url("./fonts/portraitscript-bold-webfont.woff2") format("woff2"),
    url("./fonts/portraitscript-bold-webfont.woff") format("woff"),
    url("./fonts/portraitscript-bold-webfont.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Portrait Script Bounce";
  src: url("./fonts/portraitscript-bounce-bold-webfont.woff2") format("woff2"),
    url("./fonts/portraitscript-bounce-bold-webfont.woff") format("woff"),
    url("./fonts/portraitscript-bounce-bold-webfont.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Portrait Script Bounce";
  src: url("./fonts/portraitscript-bounce-webfont.woff2") format("woff2"),
    url("./fonts/portraitscript-bounce-webfont.woff") format("woff"),
    url("./fonts/portraitscript-bounce-webfont.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

// source: https://google-webfonts-helper.herokuapp.com/fonts/montserrat?subsets=cyrillic,cyrillic-ext,latin,latin-ext
@font-face {
  font-family: Montserrat;
  src: url("fonts/montserrat/montserrat-v23-latin-ext_latin_cyrillic-ext_cyrillic-200.woff2") format("woff2");
  font-weight: 200;
}

@font-face {
  font-family: Montserrat;
  src: url("fonts/montserrat/montserrat-v23-latin-ext_latin_cyrillic-ext_cyrillic-200italic.woff2") format("woff2");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: Montserrat;
  src: url("fonts/montserrat/montserrat-v23-latin-ext_latin_cyrillic-ext_cyrillic-300.woff2") format("woff2");
  font-weight: 300;
}

@font-face {
  font-family: Montserrat;
  src: url("fonts/montserrat/montserrat-v23-latin-ext_latin_cyrillic-ext_cyrillic-300italic.woff2") format("woff2");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: Montserrat;
  src: url("fonts/montserrat/montserrat-v23-latin-ext_latin_cyrillic-ext_cyrillic-regular.woff2") format("woff2");
  font-weight: 400;
}

@font-face {
  font-family: Montserrat;
  src: url("fonts/montserrat/montserrat-v23-latin-ext_latin_cyrillic-ext_cyrillic-italic.woff2") format("woff2");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: Montserrat;
  src: url("fonts/montserrat/montserrat-v23-latin-ext_latin_cyrillic-ext_cyrillic-500.woff2") format("woff2");
  font-weight: 500;
}

@font-face {
  font-family: Montserrat;
  src: url("fonts/montserrat/montserrat-v23-latin-ext_latin_cyrillic-ext_cyrillic-500italic.woff2") format("woff2");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: Montserrat;
  src: url("fonts/montserrat/montserrat-v23-latin-ext_latin_cyrillic-ext_cyrillic-600.woff2") format("woff2");
  font-weight: 600;
}

@font-face {
  font-family: Montserrat;
  src: url("fonts/montserrat/montserrat-v23-latin-ext_latin_cyrillic-ext_cyrillic-600italic.woff2") format("woff2");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: Montserrat;
  src: url("fonts/montserrat/montserrat-v23-latin-ext_latin_cyrillic-ext_cyrillic-700.woff2") format("woff2");
  font-weight: 700;
}

@font-face {
  font-family: Montserrat;
  src: url("fonts/montserrat/montserrat-v23-latin-ext_latin_cyrillic-ext_cyrillic-700italic.woff2") format("woff2");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: Montserrat;
  src: url("fonts/montserrat/montserrat-v23-latin-ext_latin_cyrillic-ext_cyrillic-800.woff2") format("woff2");
  font-weight: 800;
}

@font-face {
  font-family: Montserrat;
  src: url("fonts/montserrat/montserrat-v23-latin-ext_latin_cyrillic-ext_cyrillic-900.woff2") format("woff2");
  font-weight: 900;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

.appBody {
  display: flex; // fix sticky footer in ie11
  margin: 0;
  padding: 0;

  &.has-modal {
    //make background unscrollable when modal is open
    overflow: hidden;
  }
}

#appRoot {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
}
