@import "../../../theme/variables";

.wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-top: $gutterMedium;
  padding-bottom: $gutterMedium;
  min-height: 690px;
  background-color: black;

  * {
    color: #fff;
  }
}

.banner-wrap {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0 auto;
  max-width: 1400px;
  overflow: hidden;
}

.banner {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  max-width: 1000px;
  opacity: 0.3;
  @include for-size(phone-only) {
    display: none;
  }

  @include for-size(tablet-landscape-up) {
    left: calc(0% + 200px);
  }

  @include for-size(desktop-up) {
    position: absolute;
    left: unset;
    width: 100%;
    opacity: 1;
  }
}

.mobile-banner {
  width: 100%;
}

.content {
  z-index: 1;
  margin-top: 20px;
}

.subtitle {
  color: #979797;
}

.title {
  line-height: normal;
  margin-bottom: 32px;

  strong {
    background: radial-gradient(circle farthest-corner at center right, #ff871c 0%, #cf4c13 200%);
    /* stylelint-disable-next-line property-no-vendor-prefix */
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.text {
  margin-bottom: 32px;
  text-shadow: 0 0 4px black;
  font-weight: $light;
}

.button-wrap {
  @include for-size(tablet-landscape-up) {
    flex-direction: column;
  }
  @include for-size(desktop-up) {
    flex-direction: row;
  }
}

.product-selector {
  align-self: flex-start;
  margin-bottom: $gutterMedium;
  width: 100%;

  @include for-size(tablet-portrait-up) {
    flex-basis: calc(50% - #{$gutterMedium} / 2);
    flex-shrink: 0;
  }

  @include for-size(desktop-up) {
    margin-bottom: 0;
  }
}

.cart-button {
  margin-bottom: 24px;
  align-self: flex-start;
  padding: 24px !important;
  width: 100%;

  transition: background-position-x 0.5s, color 0.2s;
  background: linear-gradient(85deg, white, white 25%, #ff871c 50%, #ffc542 100%) no-repeat 0% 0% / 400%;

  @include for-size(tablet-portrait-up) {
    flex-shrink: 0;
  }

  @include for-size(desktop-up) {
    max-width: calc(50%);
  }

  &:hover {
    background-position: 100% 0%;
    background-size: 200% 200%;
    color: white;
    fill: white;

    .pricing,
    .pricing-old,
    .cart-button-label {
      color: white;

      > svg {
        fill: $white;
      }
    }

    .pricing-old {
      opacity: 0.5;
    }
  }
}

.cart-button-label {
  font-weight: $semiBold;
  color: $darkBlue2;
}

.custom-add-to-cart {
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
  font-weight: $semiBold;
  color: $darkBlue2;
  font-size: 16px;

  .separator {
    width: 1px;
    height: 24px;
    background-color: $offWhite;
  }

  .pricing {
    display: grid;
    color: $sFOrange;
    font-size: 14px;

    &-old {
      color: $lightGrey;
      text-decoration: line-through;
    }
  }

  > svg {
    fill: $lightGrey;
  }
}

.custom-add-to-cart-default {
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
  font-weight: $semiBold;
  color: #fff;
  font-size: 16px;

  .separator {
    width: 1px;
    height: 24px;
    background-color: $offWhite;
  }

  .pricing {
    display: grid;
    color: $sFOrange;
    font-size: 14px;

    &-old {
      color: $lightGrey;
      text-decoration: line-through;
    }
  }

  > svg {
    fill: $lightGrey;
  }
}

.pricing-note {
  font-size: 12px;
  font-weight: $semiBold;
  text-transform: uppercase;
}

.link {
  gap: 8px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: $semiBold;
  color: $yellow;
  fill: $yellow;
}
