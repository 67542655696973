@import "../../theme/variables";

.title {
  max-width: 550px;
  font-size: 30px;
  text-align: center;
  font-style: italic;
  font-weight: $light;
  color: $darkBlue;

  strong {
    font-weight: bold;
  }
}

.description {
  margin-bottom: $gutterLarge;
  width: 500px;
  max-width: 100%;
  text-align: center;
  line-height: 30px;
  font-weight: $light;
  color: $lightBlue2;
}

.period {
  text-align: center;
  font-weight: bold;
  color: $lightBlue2;
  transition: color 0.3s ease;

  &--selected {
    color: $yellow;
  }
}

.switch {
  margin: 0 $gutterMedium;
}

svg.button-icon {
  width: 29px;
  height: 20px;
  opacity: 0.6;
}

.button-content {
  margin: 0 auto;
  padding-left: 20px;
}

.sum {
  font-size: 15px;
  line-height: normal;
  opacity: 0.5;
}

.decline-button {
  color: $lightBlue2;
  background: #f2f3f7;
}
