@import "../../../../../theme/variables";

.button {
  flex-grow: 1;
  height: 56px;
  max-width: 250px;
  text-transform: uppercase;

  & + & {
    margin-left: $gutterMedium;

    @include for-size(tablet-portrait-up) {
      margin-left: $gutterLarge;
    }
  }
}
