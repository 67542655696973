@import "./src/theme/variables";

.mobile-menu-item {
  display: flex;
  align-items: center;
  margin-right: auto; // prevent link from stretching
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  text-decoration: none;
  color: #fff !important;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: $gutterSemiLarge;
  }

  &--open {
    margin-bottom: $gutterSmall !important;

    & .icon-wrap {
      opacity: 1;
    }
  }
}

.icon-wrap {
  margin-right: $gutterSmall;
  width: 15px;
  height: 15px;
  min-width: 15px;
  min-height: 15px;
  opacity: 0.4;
  fill: #fff;
}

.arrow {
  padding: 0 8px;
  width: 26px;
  height: 10px;
  fill: #fff;

  & path {
    fill: white;
  }

  &--up {
    transform: rotate(180deg);
  }

  &--down {
    transform: rotate(0deg);
  }
}
