@import "../../theme/variables.scss";

.media-section {
  display: flex;
  flex-direction: column;
}

.image {
  width: 100%;
}
