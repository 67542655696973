@import "../../theme/variables.scss";

.button {
  &:hover {
    background: $blue;
  }
}

.forgotten-password-link {
  font-size: 14px;
  font-weight: $semiBold;
  font-style: italic;
  color: $yellow;
}

.container-wrap {
  height: 100%;
  min-height: 500px;
  background: black;
  background-size: cover;

  @include for-size(tablet-portrait-up) {
    background-position: center 65%;
    min-height: 660px;
  }
}

.content-wrap {
  display: flex;
  justify-content: center;
  z-index: 1;
  padding: 40px;
  width: 600px;
  font-size: 16px;
  letter-spacing: 0;
  font-weight: bold;
  color: $primaryText;

  @include for-size(phone-only) {
    padding: 20px;
    width: 100%;
    font-size: 14px;
    line-height: 18px;
    font-weight: bold;
  }

  @include for-size(big-desktop-up) {
    width: 600px;
    font-size: 18px;
  }
}

.container {
  display: flex;
  align-items: center;
  position: relative;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  max-width: 1480px;
  min-height: 400px;
  @include for-size(phone-only) {
    height: auto;
  }
  @include for-size(tablet-portrait-up) {
    min-height: 660px;
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.3;
    background: url("../home-view/hero-section/assets/desktop_banner.png") no-repeat top right;
    background-size: cover;
    background-position-x: -25px;

    @include for-size(phone-only) {
      background: none;
    }

    @include for-size(tablet-portrait-up) {
      left: 0;
      background-size: cover;
      background-position-x: 10vw;
    }

    @include for-size(desktop-up) {
      background-position-x: 100%;
      background-size: contain;
      opacity: 1;
    }
  }

  .vertical-line-wrap {
    display: none;

    @include for-size(tablet-landscape-up) {
      display: block;
      position: absolute;
      right: -160px;
      bottom: 60px;
      height: 14px;
      transform: rotate(-90deg);
    }
  }

  .vertical-line-inner-wrap {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    height: 14px;
  }

  .vertical-line {
    display: block;
    margin-right: $gutterSemiLarge;
    border-radius: 5px;
    width: 100px;
    height: 3px;
    background-color: #fff;
  }

  .vertical-line-text {
    top: 0;
    left: 0;
    white-space: nowrap;
    width: 137px;
    height: 15px;
    color: #fff;
    font-size: 12px;
    line-height: 14px;
    font-weight: $extraBold;
  }
}
