@import "../../../theme/variables";

.wrap {
  position: relative; // required to contain loader
  min-height: 100px; // make loader always visible
}

.note {
  width: 900px;
  max-width: 100%;
  text-align: center;

  &--expiration {
    background: #31a4fb;
  }

  &--grace-period {
    background: #fb313f;
  }
}

.expiry-notification-title {
  font-size: 16px;
  padding: $gutterSmall 0;
  font-weight: 600;

  @include for-size(tablet-portrait-up) {
    font-size: 24px;
  }
}

.forex {
  width: 87px;
  height: 61px;
}

.business-fee {
  width: 59px;
}

.bank {
  width: 61px;
  height: 79px;
}

.view {
  width: 100%;
}

.title {
  font-size: 30px;
  text-align: center;
  font-weight: $semiBold;
  color: $darkGrey;
}

.wallet-label {
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
}

.wallet-item,
.burned-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  box-shadow: 0 1px 3px color-mix(in srgb, black 10%, transparent);
  border-radius: 8px;
  padding: 10px 15px;
  background: #f9f9f9;
}

.wallet-address,
.burned-address,
.burned-type,
.wallet-type {
  margin-bottom: 5px;
  font-size: 1rem;
  color: #555;
}

.wallet-address strong,
.burned-address strong,
.burned-type strong,
.wallet-type strong {
  font-weight: 600;
  color: #222;
}

.responsive-container {
  display: flex;
  gap: 15px;
  align-items: center;

  @include for-size(phone-only) {
    gap: 10px;
    flex-direction: column;
    text-align: center;
  }
}

.logo {
  flex-shrink: 0;
  width: 100px;

  @include for-size(phone-only) {
    align-self: center;
    width: 80px;
  }
}

.text {
  flex-grow: 1;
  font-size: 16px;
  line-height: 1.6;

  @include for-size(phone-only) {
    text-align: justify;
    font-size: 14px;
    line-height: 1.4;
  }
}

.button {
  flex-shrink: 0;

  @include for-size(phone-only) {
    align-self: center;
    padding: 10px 15px;
    font-size: 16px;
  }
}

.balance {
  margin-bottom: 15px;
  font-size: 18px;
  text-align: center;
}

.action {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  @include for-size(phone-only) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.action-text {
  margin-left: 10px;
  text-align: left;
  font-size: 14px;
  color: #000;

  @include for-size(phone-only) {
    margin-left: 0;
    text-align: left;
  }
}
