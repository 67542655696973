@import "../../theme//variables.scss";

$imageSize: 120px;

.wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-top: calc(#{$imageSize} / 2);

  &--primary {
    padding-top: 0;

    @include for-size(tablet-landscape-up) {
      padding-top: calc(#{$imageSize} / 2);
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $darkBlue2;

  @include for-size(tablet-landscape-up) {
    padding: calc(#{$imageSize} / 2 + #{$gutterMedium}) calc(50px + (#{$gutterSmall} + 25px)) $gutterMedium;
  }

  &--primary {
    padding: calc(#{$gutterSemiLarge} + 25px) 25px $gutterSemiLarge; // 25px for quote sign
    text-align: center;

    @include for-size(tablet-landscape-up) {
      padding-top: calc(#{$imageSize} / 2 + #{$gutterMedium});
      text-align: left;
    }
  }

  &--secondary {
    border-radius: 35px;
    padding: calc(#{$imageSize} / 2 + #{$gutterMedium}) calc(#{$gutterSmall} + 25px) $gutterSemiLarge;
    color: #fff;
    background: $darkBlue2;

    @include for-size(tablet-portrait-up) {
      padding: calc(#{$imageSize} / 2 + #{$gutterMedium}) 100px $gutterSemiLarge;
    }
  }
}

.quote {
  position: relative;
  margin: 0;
  margin-bottom: $gutterLarge;
  max-width: 750px;
  font-size: 16px;
  line-height: 30px;
  font-weight: $light;

  @include for-size(tablet-landscape-up) {
    margin-bottom: $gutterSmall;
  }

  &:before,
  &:after {
    position: absolute;
    font-size: 50px;
    line-height: 0;
    font-weight: bold;
    color: $sFOrange;
  }

  &:before {
    content: open-quote;
    right: 100%;
    bottom: 100%;

    @include for-size(tablet-landscape-up) {
      right: calc(100% + #{$gutterSmall});
    }
  }

  &:after {
    content: close-quote;
    top: calc(100% + 10px);
    left: 100%;

    @include for-size(tablet-landscape-up) {
      left: calc(100% + #{$gutterSmall});
    }
  }
}

.image {
  position: absolute;
  z-index: 1;
  top: 0;
  box-shadow: 0 8px 16px -8px rgb(27 31 59 / 100%);
  border: 5px solid #fff;
  border-radius: 50%;
  width: $imageSize;
  height: $imageSize;

  &--primary {
    position: static;
    margin-bottom: $gutterSemiLarge;
    transform: none;

    @include for-size(tablet-landscape-up) {
      position: absolute;
      margin-bottom: 0;
    }
  }
}

.author {
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: bold;
  line-height: normal;
  color: $sFOrange;
}

.description {
  font-size: 14px;
  line-height: normal;
  text-align: center;
  color: $lightBlue2;
}
