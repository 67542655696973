@import "../../theme/variables";

.wrap {
  max-width: 100%;

  &--with-background {
    position: relative;
    padding-top: 70px;

    @include for-size(tablet-landscape-up) {
      padding-top: 100px;
    }
  }

  a,
  button {
    line-height: normal;
  }
}

.content {
  width: $siteMaxWidth;
  max-width: 100%;

  &--reverse {
    flex-direction: column-reverse;

    @include for-size(tablet-landscape-up) {
      flex-direction: row;
    }
  }
}

.reverse {
  &--SMALL_SCREEN {
    flex-direction: column-reverse;

    @include for-size(tablet-landscape-up) {
      flex-direction: row;
    }

    .image-wrap.gap {
      &--GAP_20 {
        margin-top: 0;
        margin-bottom: $gutterMedium;

        @include for-size(tablet-landscape-up) {
          margin-bottom: 0;
        }
      }

      &--GAP_40 {
        margin-top: 0;
        margin-bottom: $gutterLarge;

        @include for-size(tablet-landscape-up) {
          margin-bottom: 0;
        }
      }

      &--GAP_60 {
        margin-top: 0;
        margin-bottom: $gutterExtraLarge;

        @include for-size(tablet-landscape-up) {
          margin-bottom: 0;
        }
      }
    }
  }

  &--LARGE_SCREEN {
    @include for-size(tablet-landscape-up) {
      flex-direction: row-reverse;
    }

    .image-wrap.gap {
      &--GAP_20 {
        @include for-size(tablet-landscape-up) {
          margin-top: 0;
          margin-right: $gutterMedium;
          margin-left: 0;
        }
      }

      &--GAP_40 {
        @include for-size(tablet-landscape-up) {
          margin-top: 0;
          margin-right: $gutterLarge;
          margin-left: 0;
        }
      }

      &--GAP_60 {
        @include for-size(tablet-landscape-up) {
          margin-top: 0;
          margin-right: $gutterExtraLarge;
          margin-left: 0;
        }
      }
    }
  }

  &--ALL_SCREENS {
    flex-direction: column-reverse;

    @include for-size(tablet-landscape-up) {
      flex-direction: row-reverse;
    }

    .image-wrap.gap {
      &--GAP_20 {
        margin-top: 0;
        margin-bottom: $gutterMedium;

        @include for-size(tablet-landscape-up) {
          margin-right: $gutterMedium;
          margin-bottom: 0;
          margin-left: 0;
        }
      }

      &--GAP_40 {
        margin-top: 0;
        margin-bottom: $gutterLarge;

        @include for-size(tablet-landscape-up) {
          margin-right: $gutterLarge;
          margin-bottom: 0;
          margin-left: 0;
        }
      }

      &--GAP_60 {
        margin-top: 0;
        margin-bottom: $gutterExtraLarge;

        @include for-size(tablet-landscape-up) {
          margin-right: $gutterExtraLarge;
          margin-bottom: 0;
          margin-left: 0;
        }
      }
    }
  }
}

.section {
  width: $mobileWidth;
  max-width: 100%;
  line-height: $standardLineHeight;

  @include for-size(tablet-landscape-up) {
    max-width: 50%;
  }
}

.size {
  &--GAP_20 {
    @include for-size(tablet-landscape-up) {
      max-width: calc(50% - #{$gutterMedium} / 2);
    }
  }

  &--GAP_40 {
    @include for-size(tablet-landscape-up) {
      max-width: calc(50% - #{$gutterLarge} / 2);
    }
  }

  &--GAP_60 {
    @include for-size(tablet-landscape-up) {
      max-width: calc(50% - #{$gutterExtraLarge} / 2);
    }
  }
}

.image-wrap {
  width: 665px;
  max-width: 100%;

  @include for-size(tablet-landscape-up) {
    max-width: 50%;
  }

  // don't want to affect deeper level images
  > img {
    flex-shrink: 0;
    width: 100%;
  }
}

.gap {
  &--GAP_20 {
    margin-top: $gutterMedium;

    @include for-size(tablet-landscape-up) {
      margin-top: 0;
      margin-left: $gutterMedium;
    }
  }

  &--GAP_40 {
    margin-top: $gutterLarge;

    @include for-size(tablet-landscape-up) {
      margin-top: 0;
      margin-left: $gutterLarge;
    }
  }

  &--GAP_60 {
    margin-top: $gutterExtraLarge;

    @include for-size(tablet-landscape-up) {
      margin-top: 0;
      margin-left: $gutterExtraLarge;
    }
  }
}
