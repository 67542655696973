@import "../../theme/variables.scss";

.columns {
  display: grid;
}

.debug {
  outline: 2px solid rgb(220 53 69 / 70%);

  > * {
    outline: 2px solid rgb(220 53 69 / 70%);
  }
}
