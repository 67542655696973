@import "../../theme/variables";

.wrap {
  border-bottom: 1px solid $offWhite4;
  padding: $gutterMedium;

  @include for-size(tablet-landscape-up) {
    margin: 0 $gutterMedium;
    padding: $gutterMedium $gutterSemiLarge;
  }

  &:first-child {
    border-top: 1px solid $offWhite4;
  }
}

.label-content {
  @include for-size(tablet-portrait-max) {
    align-items: flex-start;
    width: 100%;
  }
}

.brand {
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  margin-bottom: $gutterMedium;
  width: 64px;
  height: 40px;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  color: $lightGrey2;
  background: $offWhite2;

  @include for-size(tablet-landscape-up) {
    margin-right: $gutterMedium;
    margin-bottom: 0;
  }
}

.nr {
  display: block;
  font-size: 12px;
  font-weight: bold;
}

.date {
  align-self: flex-end;
  margin-left: $gutterMedium;
  font-size: 12px;

  &--expired {
    color: $lightRed;
  }
}

.body {
  margin-top: $gutterMedium;
}
