@import "../../../theme/variables";

.grid {
  display: grid;

  @include for-size(tablet-portrait-up) {
    grid-template-columns: 1fr 2fr;
  }
}

.row {
  background: $offWhite2;

  @include for-size(tablet-portrait-up) {
    margin-bottom: $gutterMedium;
  }
}

.label {
  border-radius: $radiusMedium $radiusMedium 0 0;
  padding: $gutterMedium $gutterSemiLarge $gutterSmall $gutterSemiLarge;

  @include for-size(tablet-portrait-up) {
    border-radius: $radiusMedium 0 0 $radiusMedium;
    padding: $gutterMedium $gutterMedium $gutterMedium $gutterSemiLarge;
  }
}

.data {
  border-radius: 0 0 $radiusMedium $radiusMedium;
  padding: $gutterSmall $gutterSemiLarge $gutterMedium $gutterSemiLarge;
  color: $lightBlue2;
  font-style: italic;

  @include for-size(phone-only) {
    margin-bottom: $gutterMedium;
  }

  @include for-size(tablet-portrait-up) {
    border-radius: 0 $radiusMedium $radiusMedium 0;
    padding: $gutterMedium $gutterSemiLarge $gutterMedium 0;
  }
}

.code {
  &--redeemed {
    text-decoration: line-through;
  }
}

.option-button {
  border-radius: $radiusMedium;
  padding-top: 5px;
  padding-bottom: 5px;
  min-height: 60px;
  font-size: 14px;

  & + & {
    @include for-size(tablet-portrait-up) {
      margin-left: $gutterMedium;
    }
  }
}

.close-button {
  min-height: 60px;
}
