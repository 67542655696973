@import "../../../theme/variables";

.relative-wrap {
  position: relative;
}

.wrap {
  margin: 0;
  margin-bottom: 75px;
  max-width: unset;
}

.content {
  padding-top: 75px;
  max-width: $siteMaxWidth;
}

.caption {
  margin-bottom: $gutterSemiLarge;
  text-align: center;
  font-size: 18px;
  color: $sFOrange;
  font-weight: bold;

  @include for-size(tablet-portrait-up) {
    font-size: 20px;
  }
}

.heading {
  margin: 0 auto 50px;
  padding: 0;
  width: 750px;
  max-width: 100%;
  font-size: 24px;
  line-height: 40px;
  font-weight: $light;
  font-style: italic;

  @include for-size(tablet-portrait-up) {
    margin: 0 auto 90px;
    font-size: 30px;
  }

  strong {
    font-weight: bold;
    color: $colorDarkBlue;
  }
}

.emoji {
  margin-left: 5px;
  width: auto;
  height: 30px;
}

.media-wrap {
  align-items: center;
}

.media-content {
  max-width: 300px;
}

.circle-image-wrap {
  margin: 0 auto $gutterLarge;
  box-shadow: inset 0 6px 10px 0 rgb(119 137 173 / 30%);
  border-radius: 50%;
  width: 265px;
  height: 265px;
  overflow: hidden;
  mask-image: radial-gradient(white, black); // fix for iOS overflow hidden + border radius not working
}

.circle-image {
  top: 70px;
}

.section-heading {
  margin-bottom: $gutterSmall;
  font-size: 20px;
  font-weight: bold;
}

.description {
  font-size: 16px;
  line-height: 30px;
  color: $lightBlue2;
}
