@import "../../theme/variables";

.content {
  flex-grow: 1;

  @include for-size(phone-only) {
    margin-right: 0;
    margin-left: 0;
  }
}

.button {
  @include for-size(phone-only) {
    margin-top: $gutterMedium;
  }
}
