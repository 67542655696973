@import "../../../theme/variables";

.button {
  outline: 1px solid;
  width: 100%;
  font-size: 18px;

  @include for-size(tablet-portrait-up) {
    width: 250px;
  }

  & + & {
    margin-top: $gutterSemiLarge;

    @include for-size(tablet-portrait-up) {
      margin-top: 0;
      margin-left: $gutterMedium;
    }
  }
}

.close-button {
  text-transform: uppercase;

  @include for-size(tablet-landscape-up) {
    margin-left: $gutterMedium;
  }
}
