// media query mixin
// https://medium.freecodecamp.org/the-100-correct-way-to-do-css-breakpoints-88d6a5ba1862

// sizes
$siteMaxWidth: 1400px;
$mobileWidth: 600px;
$appSidePadding: 7.8125%;
$mobileSitePadding: 20px;
$productItemWidthS: 100px;
$productItemWidthM: 240px;
$productItemWidthL: 380px;
$fieldHeight: 50px;
$buttonHeightDesktop: 80px;
$buttonHeightMobile: 60px;
$buttonHeightSmall: 40px;
$buttonHeightExtraSmall: 30px;
$fieldLabelHeight: 20px;
$panelImageOverflow: 50px;
$checkBoxHeight: 24px;
$navBarGutter: 60px;
$loaderWidthSmall: 20px;
$loaderWidthMedium: 60px;
$contentOverFlow: 80px;
$envelopeWidth: 1000px;
$standardLineHeight: 30px;

// sizes -- checkbox
$checkBoxHeightSecondary: 16px;
$checkBoxGapSecondary: 13px;

// sizes -- user toolbar
$userToolbarHeight: 40px;

// sizes -- header
$headerHeightSmall: 60px;
$headerHeightBig: 80px;

// sizes -- global gutter
$gutterSmall: 10px;
$gutterMedium: 20px;
$gutterSemiLarge: 30px;
$gutterLarge: 40px;
$gutterExtraLarge: 60px;

// colors
$white: #fff;
$offWhite: #e2e2ea;
$offWhite2: #fafafb;
$offWhite3: #eaebef;
$offWhite4: #f1f1f5;
$offWhite5: #f5f5f5;
$lightGrey: #92929d;
$lightGrey2: #b5b5be;
$primaryText: #333;
$secondaryText: #4d5066;
$darkGrey: #44444f;
$darkBlue: #1b1f3b;
$darkBlue2: #292f48;
$green: #00d383;
$green2: #3dd598;
$blue: #0062ff;
$lightBlue: #2284ff;
$lightBlue2: #7789ad;
$red: #d30000;
$lightRed: #fc5a5a;
$sFOrange: #ff871c;
$yellow: #d87333;
$yellowDark: #cf4c13;
$lightYellow: #ffe19b;
$colorDarkBlue: #1b1f3b;

$bannerGradientLight: #d87333;
$bannerGradientDark: #c5682e;

// radiuses
$radiusExtraSmall: 3px;
$radiusSmall: 5px;
$radiusMedium: 10px;
$radiusLarge: 15px;
$radiusExtraLarge: 20px;

// font weights
$extraLight: 200;
$light: 300;
$normal: 400;
$medium: 500;
$semiBold: 600;
$bold: 700;
$extraBold: 800;

@mixin for-size($range) {
  $phone-upper-boundary: 600px;
  $tablet-portrait-upper-boundary: 900px;
  $tablet-landscape-upper-boundary: 1200px;
  $desktop-upper-boundary: 1800px;

  @if $range == phone-only {
    @media (max-width: #{$phone-upper-boundary - 1}) {
      @content;
    }
  } @else if $range == tablet-portrait-up {
    @media (min-width: $phone-upper-boundary) {
      @content;
    }
  } @else if $range == tablet-landscape-up {
    @media (min-width: $tablet-portrait-upper-boundary) {
      @content;
    }
  } @else if $range == desktop-up {
    @media (min-width: $tablet-landscape-upper-boundary) {
      @content;
    }
  } @else if $range == big-desktop-up {
    @media (min-width: $desktop-upper-boundary) {
      @content;
    }
  } @else if $range == tablet-portrait-max {
    @media (max-width: #{$tablet-portrait-upper-boundary - 1}) {
      @content;
    }
  } @else if $range == tablet-landscape-max {
    @media (max-width: #{$tablet-landscape-upper-boundary - 1}) {
      @content;
    }
  } @else {
    @media (min-width: #{$range}) {
      @content;
    }
  }
}

@include for-size("phone-only") {
  $appSidePadding: 0;
}

@include for-size("tablet-portrait-up") {
  $appSidePadding: 9%;
}
