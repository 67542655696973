@import "../../../theme/variables";

.header {
  margin-bottom: 20px;
  padding: 80px 40px 40px;
  text-align: center;
  background: linear-gradient($offWhite2, #fdfdfd);

  &--no-registration {
    @include for-size(tablet-portrait-up) {
      padding-bottom: 80px;
    }
  }
}

.title {
  margin-bottom: 0;
  font-size: 30px;

  @include for-size(tablet-landscape-up) {
    font-size: 50px;
  }
}

.label {
  margin-top: $gutterSemiLarge;
  font-size: 14px;
  color: $lightBlue2;
  text-align: center;
}

.link {
  display: inline;
  margin-left: 5px;
  font-style: italic;
}

.form {
  width: 340px;
  max-width: 100%;
}
