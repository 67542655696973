@import "../../theme/variables";

.products-wrap {
  position: relative;
  @include for-size(tablet-portrait-up) {
    margin-right: 80px;
    margin-left: 80px;
    width: 1200px;
    max-width: calc(100% - 160px);
  }

  @include for-size(tablet-landscape-up) {
    margin-right: auto;
    margin-left: auto;
  }
}

.products {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  gap: 32px;
  padding: 100px;
  width: 100%;
  height: 100%;
  min-height: 400px;
  text-align: center;
  background: linear-gradient(180deg, #292f48 21.62%, #000 78.54%);

  @include for-size(phone-only) {
    height: 100%;
    min-height: 450px;
    padding: 100px 40px;
  }

  @include for-size(tablet-portrait-up) {
    margin: 0 80px;
    border-radius: 21px;
  }

  &:before {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    border-radius: 10px 10px 0 0;
    width: 100%;
    height: 10px;
    transform: translateX(-50%);
    border: 2px solid $offWhite4;
    border-bottom: transparent;

    @include for-size(tablet-portrait-up) {
      border-radius: 20px 20px 0 0;
      width: calc(100% - 32%);
      height: 30px;
    }
  }

  &:after {
    @include for-size(tablet-portrait-up) {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      border-radius: 0 0 20px 20px;
      width: calc(100% - 32%);
      height: 30px;
      transform: translateX(-50%);
      border-top: transparent;
      border: 2px solid $offWhite4;
    }
  }
}

.icon {
  width: 40px;
}

.background {
  @include for-size(phone-only) {
    display: none;
  }

  @include for-size(tablet-portrait-up) {
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    opacity: 0.054;
    fill: $sFOrange;
  }
}

.sub-title {
  color: white !important;
  font-size: 24px;
  font-weight: $medium;
  letter-spacing: 7px;
}

.title {
  display: flex;
  flex-wrap: wrap;
  font-weight: bold;
  line-height: 60px;

  @include for-size(phone-only) {
    font-size: 30px;
    line-height: 35px;
  }

  &--highlighted {
    color: $yellow;
  }
}

.content {
  max-width: 600px;
}

.link {
  gap: 24px;
  color: $darkBlue;
  min-height: 70px;
  white-space: nowrap;
  transition: background-position-x 0.5s, background-size 1s, color 0.2s;
  background: linear-gradient(85deg, white, white 25%, #ff871c 50%, #ffc542 100%) no-repeat 0% 0% / 400%;

  &:hover {
    background-position: 100% 0%;
    background-size: 200% 200%;
    color: white;
    fill: white;
  }
}

.congratulations {
  span {
    color: $yellow;
  }
}
