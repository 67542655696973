@import "../../theme/variables";

.panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: $radiusMedium;
  padding: 50px $gutterSmall 0;
  width: 900px;
  max-width: 100%;
  min-height: 300px;
}

.color {
  &--ORANGE_GRADIENT {
    background: $sFOrange;
    background: linear-gradient(180deg, $bannerGradientLight 0%, $bannerGradientDark 100%);
  }

  &--DARK_BLUE {
    background: $darkBlue;
  }
}

.title {
  font-size: 30px;
  font-weight: $light;
  word-break: break-word;
}

.field-button-wrap {
  margin-bottom: 55px;
  box-shadow: 0 10px 20px -10px rgb(0 0 0 / 50%);

  input:disabled {
    opacity: 1; // removes opacity on iOS
  }
}

.field-button {
  cursor: pointer;

  @include for-size(tablet-portrait-up) {
    min-width: 130px;
  }
}

.button-text {
  font-weight: $medium;
  text-transform: uppercase;
}

.share-button-wrap {
  width: 690px;
  max-width: 100%;
}

.share-button {
  box-shadow: 0 10px 20px -10px rgb(0 0 0 / 50%);
  width: calc(100% - #{$gutterSmall} * 2);
  transform: translateY(50%);
  color: $lightBlue2;
  background: #fff;

  @include for-size(tablet-portrait-up) {
    width: auto;
  }

  & + & {
    margin-top: $gutterSmall;

    @include for-size(tablet-portrait-up) {
      margin-top: 0;
      margin-left: $gutterSmall;
    }
  }
}
