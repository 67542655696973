@import "../../../theme/variables";

// large yellow circle overflow
.section-wrap {
  padding-bottom: 100px;
  overflow: hidden;
}

.title {
  line-height: 60px;

  @include for-size(tablet-portrait-max) {
    line-height: 35px;
    font-size: 30px;
  }
}

.wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  @include for-size(tablet-landscape-up) {
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    margin: 90px auto auto;
  }
}

//Three yellow dots

.yellow-dots {
  position: absolute;
  top: 200px;
  left: 150px;
}

.panel {
  z-index: 1;
  max-width: 440px;

  @include for-size(tablet-portrait-up) {
    min-height: 550px;
  }

  & + & {
    margin-top: $gutterSemiLarge;

    @include for-size(tablet-landscape-up) {
      margin-top: 0;
      margin-left: $gutterMedium;
    }
  }

  &--transform {
    @include for-size(tablet-landscape-up) {
      transform: translateY(20px);
    }
  }
}

.panel-title {
  margin-bottom: 0;
  font-size: 40px;
  line-height: 25px;

  strong {
    font-size: 22px;
  }
}

.check-list {
  margin: 0 0 $gutterLarge;
  padding: 0;
  list-style: none;
}

.check-list-item {
  font-size: 12px;
  line-height: 20px;

  strong {
    font-size: 16px;
  }
}

//Large yellow circle
.yellow-circle {
  position: absolute;
  top: 50%;
  left: 37%;
  transform: translateY(-55%);

  @include for-size(phone-only) {
    left: 0;
    transform: translate(-10%, -55%);
  }
}
