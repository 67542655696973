@import "../../theme//variables.scss";

.hero {
  @include for-size(phone-only) {
    margin-top: $gutterSmall;
    overflow: hidden;
  }

  @include for-size(tablet-portrait-up) {
    background: transparent;
  }
}

.hero-illustration {
  @include for-size(phone-only) {
    width: 165%;
    transform: translateX(2.7%);
  }

  @include for-size(tablet-portrait-up) {
    margin-top: $gutterMedium;
  }

  @include for-size(tablet-landscape-up) {
    margin-top: 0;
    transform: scale(2) translateX(-15%);
    transform-origin: left bottom;
  }
}

.heading {
  font-size: 30px;

  @include for-size(tablet-landscape-up) {
    font-size: 40px;
  }
}

.text {
  line-height: 30px;
}

// credentials

.credentials-wrap {
  position: relative;
  padding-top: 60px;
  padding-bottom: 60px;

  @include for-size(tablet-landscape-up) {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .credentials-label {
    margin-bottom: $gutterSmall;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: $normal;
    color: $lightGrey;
    letter-spacing: 5px;
  }

  .credentials {
    width: 100%;
    max-width: 500px;
  }

  .credentials-separator {
    display: none;

    @include for-size(tablet-landscape-up) {
      display: block;
    }
  }
}

// testimonial 1

.testimonial-section-content {
  position: relative;
  margin-bottom: 70px;

  @include for-size(tablet-landscape-up) {
    margin-bottom: 100px;
  }
}

// testimonail 2

.testimonial-content {
  display: flex;
  justify-content: center;
  position: relative;
  padding-top: 42px; // background image offset
  width: $siteMaxWidth;
  max-width: 100%;

  @media only screen and (max-width: 493px) {
    overflow: hidden; // prevent image with min-width creating x-scroll
  }

  @include for-size(tablet-portrait-up) {
    padding-top: 90px; // background image offset
  }

  @include for-size(tablet-landscape-up) {
    padding-top: 160px; // background image offset
  }

  .testimonial-background {
    position: absolute;
    z-index: -1;
    top: 0;
    width: 100%;
    min-width: 473px;
    max-width: 1120px;
  }
}

.upgrade-modal-title {
  font-size: 30px;
  text-align: center;
  font-weight: $semiBold;
  color: $darkGrey;
}
