@import "../../../theme/variables";

.container-wrap {
  height: 100%;
  min-height: 400px;
  background: black;
  background-size: cover;

  @include for-size(tablet-portrait-up) {
    background-position: center 65%;
    min-height: 660px;
  }
}

.header {
  color: white;

  strong {
    color: black;
    background: radial-gradient(
      circle farthest-corner at top right,
      #ff871c 0%,
      #cf4c13 200%
    ); // Copied straight from the design document
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }
}

.mobile-banner {
  max-width: 100%;
}

.container {
  display: flex;
  align-items: center;
  position: relative;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  max-width: 1480px;
  min-height: 400px;
  @include for-size(phone-only) {
    margin-top: -100px;
    height: auto;
  }
  @include for-size(tablet-portrait-up) {
    min-height: 660px;
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.3;
    background: url("./assets/desktop_banner.png") no-repeat top right;
    background-size: cover;
    background-position-x: -25px;

    @include for-size(phone-only) {
      background: none;
    }

    @include for-size(tablet-portrait-up) {
      left: 0;
      background-size: cover;
      background-position-x: 10vw;
    }

    @include for-size(desktop-up) {
      background-position-x: 100%;
      background-size: contain;
      opacity: 1;
    }
  }

  .vertical-line-wrap {
    display: none;

    @include for-size(tablet-landscape-up) {
      display: block;
      position: absolute;
      right: -160px;
      bottom: 60px;
      height: 14px;
      transform: rotate(-90deg);
    }
  }

  .vertical-line-inner-wrap {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    height: 14px;
  }

  .vertical-line {
    display: block;
    margin-right: $gutterSemiLarge;
    border-radius: 5px;
    width: 100px;
    height: 3px;
    background-color: #fff;
  }

  .vertical-line-text {
    top: 0;
    left: 0;
    white-space: nowrap;
    width: 137px;
    height: 15px;
    color: #fff;
    font-size: 12px;
    line-height: 14px;
    font-weight: $extraBold;
  }
}

.content-wrap {
  z-index: 1;
  max-width: 700px;
  font-size: 16px;
  letter-spacing: 0;
  font-weight: bold;
  color: $primaryText;
  padding: 40px;

  @include for-size(phone-only) {
    font-size: 14px;
    line-height: 18px;
    font-weight: bold;
    padding: 20px;
  }

  @include for-size(big-desktop-up) {
    font-size: 18px;
  }
}

.text {
  line-height: 30px;
  max-width: 400px;
  font-weight: 300;
  color: white;
}

.button {
  margin-top: 25px;
  color: #1b1f3b;
  transition: background-position-x 0.5s, background-size 1s, color 0.2s;
  background: linear-gradient(85deg, white, white 25%, #ff871c 50%, #ffc542 100%) no-repeat 0% 0% / 400%;

  @include for-size(phone-only) {
    width: 100%;
  }

  @include for-size(tablet-portrait-up) {
    display: inline-flex;
    padding: 20px 60px;
  }

  &:hover {
    background-position: 100% 0%;
    background-size: 200% 200%;
    color: white;
  }
}
