@import "../../theme/variables.scss";

.loading {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: $gutterMedium;
  text-align: center;
}

.loadingIndicator {
  margin-bottom: $gutterLarge;
}

.loadingText {
  margin-top: $gutterMedium;
  opacity: 0;
  animation: fadeIn 1s;
  animation-delay: 2s;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
