@import "../../theme/variables";

.label {
  $arrowWidth: 6px;

  position: relative;
  margin-left: calc(#{$arrowWidth} + 5px);
  border-radius: 3px;
  padding: 4px $gutterMedium;
  font-size: 12px;
  color: $green2;
  font-weight: bold;
  background: rgba($green2, 0.1);

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    right: 100%;
    width: 0;
    height: 0;
    transform: translateY(-50%);
    border-style: solid;
    border-width: 5px $arrowWidth 5px 0;
    border-color: transparent rgba($green2, 0.1) transparent transparent;
  }
}
