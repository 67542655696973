@import "../../../../../theme/variables";

.option {
  max-width: 540px;
  min-height: 60px;
  color: $darkBlue2;
  background: #f2f3f7;

  @include for-size(tablet-portrait-up) {
    padding-left: $gutterLarge;
  }

  &:hover {
    color: $darkBlue2;
    background: $yellow;

    @include for-size(tablet-portrait-up) {
      padding-left: $gutterLarge;
    }
  }

  & + & {
    margin-top: $gutterMedium;
  }

  strong {
    font-weight: $semiBold;
  }
}

.arrow-icon {
  transform: rotate(180deg);
}

.button-text {
  width: 100%;
  text-align: left;
  font-weight: $light;
}

.close-modal-button {
  margin-top: $gutterLarge;
  margin-right: auto;
  margin-left: auto;
  min-width: 280px;
  font-size: 18px;
}
