@import "../../theme/variables";

.h1 {
  font-size: 28px;
  line-height: 35px;
  font-weight: 700;
  text-shadow: 0 12px 11px rgb(0 0 0 / 20%);

  @include for-size(tablet-portrait-up) {
    font-size: 44px;
    line-height: 48px;
  }

  @include for-size(tablet-landscape-up) {
    font-size: 60px;
    line-height: 60px;
  }

  @include for-size(big-desktop-up) {
    font-size: 60px;
  }
}

.h2 {
  font-size: 40px;
  font-weight: bold;
  color: $darkBlue;

  @include for-size(tablet-landscape-up) {
    font-size: 50px;
  }
}

.h2.header--thin {
  font-size: 40px;

  @include for-size(tablet-landscape-up) {
    font-size: 60px;
  }
}

.h3 {
  font-size: 20px;
  font-weight: $extraBold;
  color: $darkBlue;

  @include for-size(tablet-portrait-up) {
    font-size: 40px;
  }
}

.h4 {
  font-size: 20px;
  color: $darkBlue;

  @include for-size(tablet-portrait-up) {
    font-size: 30px;
  }
}

.h5 {
  font-size: 20px;
  font-weight: $light;
  color: $sFOrange;

  @include for-size(tablet-portrait-up) {
    font-size: 30px;
  }
}

.h6 {
  font-size: 16px;
  color: $darkBlue;
}

.header {
  &--thin {
    font-weight: 200;
  }

  &--white {
    color: #fff;
  }

  &--gutter-small {
    margin-bottom: $gutterSmall;
  }

  &--gutter-medium {
    margin-bottom: $gutterMedium;
  }

  &--gutter-semi-large {
    margin-bottom: $gutterSemiLarge;
  }

  &--gutter-large {
    margin-bottom: $gutterLarge;
  }

  &--gutter-extra-large {
    margin-bottom: $gutterExtraLarge;
  }
}

// center

.center {
  text-align: center;

  &--MOBILE {
    @include for-size(phone-only) {
      text-align: center;
    }
  }

  &--TABLET_PORTRAIT_UP {
    @include for-size(tablet-portrait-up) {
      text-align: center;
    }
  }

  &--TABLET_LANDSCAPE_UP {
    @include for-size(tablet-landscape-up) {
      text-align: center;
    }
  }

  &--DESKTOP_UP {
    @include for-size(desktop-up) {
      text-align: center;
    }
  }

  &--TABLET_PORTRAIT_MAX {
    @include for-size(tablet-portrait-max) {
      text-align: center;
    }
  }

  &--TABLET_LANDSCAPE_MAX {
    @include for-size(tablet-landscape-max) {
      text-align: center;
    }
  }
}
