@import "./src/theme/variables";

.link-wrap {
  text-align: center;
}

.link {
  + .link {
    margin-left: $gutterMedium;
  }
}

.icon {
  opacity: 0.3;
  fill: #fff;
}
