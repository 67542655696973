@import "../../../../theme/variables";

.button {
  justify-self: center;
  width: 110px;

  @include for-size(tablet-landscape-up) {
    justify-self: unset;
    width: auto;
  }
}

.title {
  font-size: 30px;
  text-align: center;
  font-weight: $semiBold;
  color: $darkGrey;
}

.caption {
  margin-right: auto;
  margin-bottom: $gutterSemiLarge;
  margin-left: auto;
  max-width: 500px;
  line-height: 30px;
  font-weight: $light;
  text-align: center;
  color: $lightBlue2;
}
