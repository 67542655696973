@import "../../theme/variables";

.wrap {
  max-width: 340px;
}

.wrap-with-discount {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: $radiusSmall;
  padding: 5px $gutterMedium;
  height: $fieldHeight;
  font-weight: $semiBold;
  color: #fff;
  background: $blue;
}

.button {
  justify-self: center;
  align-self: center;
  margin-right: 5px;
  text-transform: uppercase;
  font-size: 16px;
}

.button-close {
  margin-left: $gutterMedium;
  border-radius: 50%;
  padding: 0;
  width: 16px;
  height: 16px;
  min-height: unset;
  transform: rotate(45deg);
  background: $blue;
}

.close-icon {
  fill: rgb(255 255 255 / 50%);
}
