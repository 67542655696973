@import "../../../../theme/variables";

.container {
  margin: 5px 0;
  color: $lightGrey2;
  font-size: 12px;
  font-weight: $light;
  line-height: 14px;

  @include for-size(tablet-landscape-up) {
    margin: 0;
  }

  &:not(:last-child) {
    &:after {
      @include for-size(tablet-landscape-up) {
        content: "|";
        padding: 0 4px;
      }
    }
  }
}

.link {
  color: $lightGrey2;
  text-decoration: none;
}
