@import "../../theme/variables.scss";

// https://github.com/loadingio/css-spinner/blob/master/hourglass.html

@keyframes loader {
  0% {
    transform: rotateZ(0);
  }

  100% {
    transform: rotateZ(360deg);
  }
}

.loader {
  display: inline-block;
  position: relative;
  opacity: 0;
  transition: opacity 300ms ease;

  &:after {
    content: " ";
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    border: 10px solid;
    border-radius: 50%;
    width: 0;
    height: 0;
    background: center center no-repeat;
    background-size: cover;
  }

  &--small {
    width: $loaderWidthSmall;
    height: $loaderWidthSmall;

    &:after {
      border-width: calc(#{$loaderWidthSmall} / 2);
    }
  }

  &--medium {
    width: $loaderWidthMedium;
    height: $loaderWidthMedium;

    &:after {
      border-width: calc(#{$loaderWidthMedium} / 2);
    }
  }

  &--cover {
    position: absolute;
    z-index: 100;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: rgb(255 255 255 / 60%);
    pointer-events: none;
  }

  &--white-background {
    background: rgb(255 255 255 / 100%);
  }

  &--fixed {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.color {
  &--WHITE {
    &:after {
      border-color: #fff transparent;
    }
  }

  &--ORANGE {
    &:after {
      border-color: $sFOrange transparent;
    }
  }
}

.visible {
  opacity: 1;

  &:after {
    animation: lds-hourglass 1s linear infinite;
  }
}

.visible .cover {
  pointer-events: all;
}

@keyframes lds-hourglass {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  50% {
    transform: rotate(180deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  100% {
    transform: rotate(360deg);
  }
}
