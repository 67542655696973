@import "../../theme/variables";

$semiLargeTitlebar: 200px;

.titlebar {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: relative;
  padding: $gutterMedium $gutterLarge;
  width: 100%;

  @include for-size(tablet-landscape-up) {
    padding: $gutterMedium 150px; // large side padding prevents back button overflow by title
  }

  &--primary {
    background: $sFOrange;
    background: linear-gradient(180deg, $bannerGradientLight 0%, $bannerGradientDark 100%);
  }

  &--secondary {
    background: $blue;
  }

  &--tertiary {
    background: $sFOrange;
    background: linear-gradient(180deg, $bannerGradientLight 0%, $bannerGradientDark 100%);
  }

  &--quaternary {
    justify-content: space-between;
    color: #fff;
    font-size: 14px;
    background: $darkBlue;

    @include for-size(tablet-landscape-up) {
      padding: 0 $appSidePadding;
    }
  }

  &--quinary {
    background: $yellow;
  }

  &--large {
    @include for-size(tablet-landscape-up) {
      min-height: 330px;
    }
  }

  &--gutter-small {
    margin-bottom: $gutterMedium;
  }

  &--gutter-large {
    margin-bottom: 60px;
  }

  * {
    z-index: 10; // overwrite background z-index
  }

  a {
    text-decoration: none;
  }
}

.height {
  &--SMALL {
    min-height: 40px;
  }

  &--MEDIUM {
    min-height: 100px;
  }

  &--SEMI_LARGE {
    min-height: 150px;
  }
}

.title {
  &--compact {
    font-size: 20px !important;

    @include for-size(tablet-landscape-up) {
      font-size: 30px !important;
    }
  }
}

.titlebar-background-wrap {
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0 10%;
  overflow: hidden;
}

.titlebar-background-icon {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  transform: translateY(-50%);
}

.back-link {
  display: flex;
  align-items: center;
  position: absolute;
  top: 50%;
  left: $gutterMedium;
  transform: translateY(-50%);
  color: #fff;

  &--quaternary {
    position: static;
    transform: none;
  }
}

.format-text {
  &--UNSET {
    text-transform: unset;
  }

  &--UPPERCASE {
    text-transform: uppercase;
  }
}

.back-link-icon {
  width: 20px;
  height: 20px;
  fill: #fff;

  @include for-size(tablet-landscape-up) {
    margin-right: $gutterMedium;
    width: 30px;
    height: 30px;
  }

  &--quaternary {
    margin-right: $gutterMedium;
    width: 30px;
    height: 28px;
    opacity: 0.5;
    fill: #fff;
  }
}

.back-link-text {
  display: none;

  @include for-size(tablet-landscape-up) {
    display: inline;
  }

  &--quaternary {
    display: block;
    font-size: 14px;
    color: #fff;
  }
}

.image {
  position: absolute;
  top: $gutterMedium;
  width: 150px;
  height: 150px;

  &--semi-large {
    top: 100%;
    width: auto;
    height: $semiLargeTitlebar;
    transform: translateY(-50%);
  }
}
