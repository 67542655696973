@import "../../theme//variables.scss";

.call-to-action-icon {
  display: grid;
  justify-items: center;
  gap: 16px;
  color: white;
  text-transform: uppercase;
  font-weight: $medium;
  letter-spacing: 7px;
}

.get-started {
  padding: 24px 64px !important;
  color: black;
  font-weight: $bold;
  transition: background-position-x 0.5s, color 0.2s;
  background: linear-gradient(85deg, white, white 25%, #ff871c 50%, #ffc542 100%) no-repeat 0% 0% / 400%;

  &:hover {
    background-position: 100% 0%;
    background-size: 200% 200%;
    color: white;
  }
}
