@import "../../theme/variables";

.benefits-wrap {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: $gutterMedium;
  max-width: 560px;
}

.benefit {
  display: grid;
  grid-template-columns: 2fr 6fr;
  align-items: center;
  margin-top: $gutterSmall;
  width: 260px;
  height: 55px;
  color: $sFOrange;
}

.benefit-icon {
  width: 30px;
  height: 30px;
  fill: $yellow;
  justify-self: center;
}
