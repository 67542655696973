@import "../../theme/variables";

.wrap {
  max-width: 100%;
}

.panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  box-shadow: 0 20px 20px -20px $lightBlue2;
  border-radius: $radiusMedium;
  padding: $gutterMedium $gutterSemiLarge;
  width: 600px;
  max-width: 100%;
  min-height: 95px;
  color: #fff;
  background: $darkBlue2;

  @include for-size(tablet-portrait-up) {
    flex-direction: row;
    align-items: stretch;
    padding: 15px $gutterSemiLarge;
  }
}

.gutter {
  &--small {
    margin-bottom: $gutterSmall;
  }

  &--medium {
    margin-bottom: $gutterMedium;
  }

  &--semi-large {
    margin-bottom: $gutterSemiLarge;
  }

  &--large {
    margin-bottom: $gutterLarge;
  }

  &--extra-large {
    margin-bottom: $gutterExtraLarge;
  }
}

.offer-section {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include for-size(tablet-portrait-up) {
    flex-direction: row;
  }
}

.title {
  line-height: 20px;
  text-align: center;

  @include for-size(tablet-portrait-up) {
    text-align: left;
  }

  strong {
    color: $yellow;
  }

  &--thin {
    strong {
      font-weight: inherit;
    }
  }
}

.icon-wrap {
  $shadowSize: 6px;

  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  margin-bottom: $gutterSemiLarge;
  box-shadow: 0 0 0 $shadowSize rgb(255 255 255 / 35%);
  border-radius: 50%;
  width: 48px;
  height: 48px;
  background: #fff;

  @include for-size(tablet-portrait-up) {
    margin-right: calc(#{$gutterMedium} + #{$shadowSize});
    margin-bottom: 0;
  }

  img {
    width: 60%;
  }
}

.formatted-text {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  margin-top: $gutterMedium;
  border-top: 1px solid $yellow;
  padding-top: $gutterMedium;
  width: 170px;

  @include for-size(tablet-portrait-up) {
    margin-top: 0;
    margin-left: $gutterSemiLarge;
    border-top: none;
    border-left: 1px solid $yellow;
    padding-top: 0;
    padding-left: $gutterSemiLarge;
  }

  &--primary {
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    text-decoration: line-through;
    color: $lightRed;

    @include for-size(tablet-portrait-up) {
      text-align: left;
    }

    strong {
      display: inline-block;
      margin-top: 5px;
      color: $green2;
    }
  }

  &--secondary {
    display: flex;
    justify-content: center;
    text-align: center;

    strong {
      display: block;
      margin-top: 5px;
      font-size: 12px;
      font-weight: $light;
      color: $lightBlue2;
    }
  }

  &--tertiary {
    display: flex;
    justify-content: center;
    text-align: center;

    strong {
      display: block;
      margin-top: 5px;
      font-size: 12px;
      text-transform: uppercase;
      color: $yellow;
    }
  }
}

.receipt {
  z-index: -1;
  margin: 0 auto;
  padding: $gutterMedium $gutterSemiLarge;
  width: 460px;
  max-width: calc(100% - (2 * #{$gutterMedium}));
  font-weight: $light;
  background: url("./assets/wave.svg") bottom / 30px repeat-x, linear-gradient(#fff 0, #f2f3f7 100%);
}

.receipt-line {
  padding: 15px 0;

  & + & {
    border-top: 1px solid $offWhite;
  }
}

.receipt-total {
  text-transform: uppercase;
  font-weight: $extraBold;
}

.total-value {
  font-weight: bold;
  color: $green2;
}
