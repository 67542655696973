@import "../../../theme/variables";

.header {
  margin-bottom: 20px;
  padding: 80px 40px 40px;
  text-align: center;
  background: linear-gradient($offWhite2, #fdfdfd);
}

.title {
  font-size: 30px;

  @include for-size(tablet-landscape-up) {
    font-size: 50px;
  }
}

.label {
  display: inline-block;
  font-size: 14px;
  color: $lightBlue2;
  text-align: center;
}

.link {
  display: inline;
  margin-left: 5px;
  font-style: italic;
}

.field-wrap {
  margin-right: auto;
  margin-left: auto;
  max-width: 780px;
}
