@import "../../../theme/variables";

.title {
  line-height: normal;
  font-weight: bold;
}

.content {
  display: grid;
  gap: 32px;
  font-weight: $light;
}

.cart-wrapper {
  display: grid;
  gap: 8px;
  justify-items: start;
}

.cart-button {
  margin-bottom: 0;
  background: linear-gradient(75deg, #000, #292f48 25%, #ff871c 40%, #ffc542 100%) no-repeat 0% 0% / 400%;
}

.cart-button-label {
  color: white;

  > svg {
    fill: white;
  }
}

.discount-label {
  display: flex;
  align-items: center;
  gap: $gutterSmall;
  color: white;
  font-size: 12px;
  font-weight: $semiBold;
  letter-spacing: 3px;
  padding: 4px 16px;
  text-transform: uppercase;
  border-radius: 4px;
  background: linear-gradient(92deg, #ff871c 0.02%, #ffc542 99.65%);

  > svg {
    opacity: 0.5;
    fill: white;
  }
}

.bottom-separator {
  bottom: -80px; // Matches view gutter
}

.pricing-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.pricing-table td:first-child,
.pricing-table th:first-child {
  white-space: nowrap;
}

/* Desktop Table */
.table-container {
  display: block;
}

.pricing-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.pricing-table th,
.pricing-table td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: left;
}

.pricing-table th {
  background-color: #333;
  color: white;
}

.pricing-card {
  border: 1px solid #ddd;
  padding: 15px;
  margin-bottom: 15px;
  box-shadow: 2px 2px 10px rgb(0 0 0 / 10%);
  border-radius: 8px;
  background-color: #f9f9f9;
}

.pricing-card h2 {
  margin-bottom: 10px;
}
