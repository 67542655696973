@import "../../theme/variables";

.wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: $gutterSmall;
  padding: $gutterSmall;
  text-transform: uppercase;

  @include for-size(phone-only) {
    font-size: 12px;
  }
}

.countdown {
  text-align: center;
}
