@import "../../../theme/variables";

$giftCardPriceBottomShadow: 6px;

.title {
  text-align: center;
  font-size: 20px;
  font-weight: $light;
  color: $lightBlue2;
}

.price-wrap {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: $gutterMedium;
  margin-bottom: calc(#{$gutterSemiLarge} - #{$giftCardPriceBottomShadow});
  padding-bottom: $giftCardPriceBottomShadow;

  @include for-size(tablet-portrait-up) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.price {
  box-shadow: 0 14px $giftCardPriceBottomShadow -4px $offWhite4;
  border: 2px solid #fff;
  border-radius: $radiusLarge;
  height: 52px;
  min-width: 82px;
  background: #f2f3f7;

  &:hover {
    box-shadow: 0 14px $giftCardPriceBottomShadow -4px rgba($darkBlue, 0.2);
    cursor: pointer;
  }

  &:disabled {
    pointer-events: none;
  }

  &--selected:not(:disabled) {
    box-shadow: 0 14px $giftCardPriceBottomShadow -4px rgba($darkBlue, 0.2);
    color: #fff;
    background: #000;
  }
}

.field {
  width: 350px;
  max-width: 100%;
}

.purchase-button {
  margin-bottom: 50px;
  min-width: 280px;

  @include for-size(tablet-landscape-up) {
    margin-bottom: 100px;
  }
}

.image {
  max-width: 100%;
}
