@import "../../theme/variables";

.list-item {
  display: flex;
  font-style: italic;
  font-weight: $light;

  @include for-size(tablet-landscape-up) {
    align-items: center;
  }

  & + & {
    margin-top: $gutterMedium;
  }
}

.checkmark-icon {
  flex-shrink: 0;
  margin-right: 15px;
  width: 29px;
  height: 16px;
  fill: $yellow;
}
