@import "../../theme/variables";

.accent {
  strong {
    font-weight: inherit;
  }

  &--orange {
    strong {
      color: $sFOrange;
    }
  }

  &--yellow {
    strong {
      color: $yellow;
    }
  }

  &--green {
    strong {
      color: $green2;
    }
  }

  &--light-blue {
    strong {
      color: $lightBlue;
    }
  }

  &--white {
    strong {
      color: #fff;
    }
  }

  &--wrap {
    strong {
      display: block;
    }
  }

  &--bold {
    strong {
      font-weight: bold;
    }
  }
}
