@import "../../theme/variables";

.wrap {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.icon-wrap {
  margin-right: 15px;

  svg {
    width: 26px;
    height: 26px;
    fill: $lightBlue2;
  }
}

.wrap-text {
  white-space: pre-wrap;
}

.label {
  font-size: 12px;
  font-weight: $light;
  color: $darkBlue;
}

.value {
  font-size: 14px;
  font-weight: bold;
  color: $yellow;
}

.addendum {
  margin-left: 5px;
  font-weight: $normal;
  color: $lightBlue2;
}
