@import "../../theme/variables";

.container {
  margin: 0 auto;
  padding: $gutterSmall;
  max-width: 100%;

  @include for-size(tablet-portrait-up) {
    padding: $gutterExtraLarge $gutterSmall;
  }

  &--with-title {
    padding: $gutterLarge $gutterSmall;
  }

  &--secondary {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: $gutterSmall 5%;
    background: linear-gradient(180deg, $bannerGradientLight 0%, $bannerGradientDark 100%);

    @include for-size(phone-only) {
      flex-direction: column;
      padding: $appSidePadding 5%;
    }
  }

  &--center {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &--small {
    width: 600px;
  }

  &--medium {
    width: 800px;
  }

  &--large {
    width: 1150px;
  }

  &--grow {
    height: 100%;
  }
}

.title {
  margin-bottom: $gutterSemiLarge;

  @include for-size(tablet-portrait-up) {
    margin-bottom: $gutterLarge;
  }

  // nested syntax helps overwrite default .h2.header--thin
  &.title--secondary {
    font-size: 30px;
    font-style: italic;
    font-weight: $light;

    @include for-size(tablet-landscape-up) {
      font-size: 40px;
    }
  }
}
