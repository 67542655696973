@import "../../theme/variables";

.link {
  display: block;
  max-width: 100%;
  text-decoration: none;

  & + & {
    margin-top: $gutterSemiLarge;
  }
}

.wrap {
  margin-right: auto;
  margin-left: auto;
  border-radius: 15px;
  padding: $gutterLarge $gutterSmall $gutterSemiLarge;
  width: 740px;
  max-width: 100%;
  background: $offWhite2;

  @include for-size(tablet-portrait-up) {
    padding: 50px $gutterSemiLarge 50px 50px;
  }
}

.section {
  margin-bottom: $gutterSemiLarge;

  @include for-size(tablet-portrait-up) {
    margin-right: $gutterMedium;
    margin-bottom: 0;
  }
}

.logo-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: $gutterSemiLarge;

  @include for-size(tablet-portrait-up) {
    margin-right: 50px;
    margin-bottom: 0;
  }

  * {
    max-width: 50px;
    max-height: 50px;
  }

  > * + * {
    margin-top: $gutterSmall;
  }
}

.main {
  line-height: 20px;
  font-size: 14px;
  font-weight: $light;
  color: $lightBlue2;
}

.tooltip {
  padding: $gutterMedium;
  max-width: 320px;
  background-color: rgba($darkBlue, 0.9) !important;
}

.tooltip-arrow {
  opacity: 0.9;
}

.tooltip-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.gift-icon {
  display: block;
  margin: 0 auto 15px;
}

.tooltip-title {
  color: #fff;
  font-style: italic;
  font-size: 14px;
  text-align: center;
  line-height: 17px;
}

.tooltip-text {
  max-width: 320px;
  font-size: 12px;
  line-height: 17px;
  font-weight: $light;
  color: $offWhite;

  strong {
    &:nth-child(1) {
      color: $green2;
    }

    &:nth-child(2) {
      color: $yellow;
    }
  }
}

.title {
  display: flex;
  flex-direction: column;
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  color: $darkGrey;

  @include for-size(tablet-portrait-up) {
    text-align: left;
  }

  @include for-size(tablet-landscape-up) {
    flex-direction: row;
    align-items: center;
  }
}

.label-wrap {
  font-weight: $light;
  font-size: 12px;

  @include for-size(tablet-landscape-up) {
    margin-left: 4px;
  }
}

.separator {
  display: none;

  @include for-size(tablet-landscape-up) {
    display: inline;
  }
}

.label {
  font-weight: bold;
  text-transform: uppercase;
  color: $sFOrange;
}

.arrow {
  display: none;

  @include for-size(tablet-portrait-up) {
    display: block;
    flex-shrink: 0;
    margin-left: $gutterSemiLarge;
    transform: rotate(180deg);
  }
}
