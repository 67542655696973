@import "../../../theme//variables.scss";

.subscriptions {
  position: relative; // to container loader
  margin: 0 auto; // center content
  width: $siteMaxWidth;
  max-width: 100%;
  min-height: 100px; // make loader always visible

  ul {
    margin: 0;
    padding: 0;
    width: 100%;
  }
}
