@import "../../theme/variables.scss";

.content-section {
  &--center-text {
    text-align: center;
  }

  &--reverse {
    .wrap {
      flex-direction: column-reverse;

      @include for-size(tablet-landscape-up) {
        flex-direction: row-reverse;
      }
    }
  }

  &--side-space {
    padding-right: $gutterSmall;
    padding-left: $gutterSmall;

    @include for-size(tablet-portrait-up) {
      padding-right: $gutterMedium;
      padding-left: $gutterMedium;
    }

    @include for-size(tablet-landscape-up) {
      padding-right: $gutterLarge;
      padding-left: $gutterLarge;
    }

    @include for-size(tablet-land-up) {
      padding-right: $gutterLarge;
      padding-left: $gutterLarge;
    }
  }
}

/* stylelint-disable-next-line */
.wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 100%;
  max-width: $siteMaxWidth;
  font-size: 16px;
  line-height: 30px;

  @include for-size(tablet-landscape-up) {
    flex-direction: row;
  }
}

.main {
  width: 100%;
}

.illustration {
  width: 100%;
  max-width: 665px;

  @include for-size(tablet-landscape-up) {
    width: 50%;
  }
}

.gap {
  &--small {
    margin-bottom: $gutterSmall;

    @include for-size(tablet-landscape-up) {
      margin-right: $gutterSmall;
      margin-bottom: 0;
    }
  }

  &--small-reverse {
    margin-top: $gutterSmall;

    @include for-size(tablet-landscape-up) {
      margin-top: 0;
      margin-left: $gutterSmall;
    }
  }

  &--medium {
    margin-bottom: $gutterMedium;

    @include for-size(tablet-landscape-up) {
      margin-right: $gutterMedium;
      margin-bottom: 0;
    }
  }

  &--medium-reverse {
    margin-top: $gutterMedium;

    @include for-size(tablet-landscape-up) {
      margin-top: 0;
      margin-left: $gutterMedium;
    }
  }

  &--large {
    margin-bottom: $gutterLarge;

    @include for-size(tablet-landscape-up) {
      margin-right: $gutterLarge;
      margin-bottom: 0;
    }
  }

  &--large-reverse {
    margin-top: $gutterLarge;

    @include for-size(tablet-landscape-up) {
      margin-top: 0;
      margin-left: $gutterLarge;
    }
  }

  &--extra-large {
    margin-bottom: $gutterExtraLarge;

    @include for-size(tablet-landscape-up) {
      margin-right: $gutterExtraLarge;
      margin-bottom: 0;
    }
  }

  &--extra-large-reverse {
    margin-top: $gutterExtraLarge;

    @include for-size(tablet-landscape-up) {
      margin-top: 0;
      margin-left: $gutterExtraLarge;
    }
  }
}
