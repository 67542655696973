@import "../../../../theme/variables";

$giftCardOffset: 80px;
$giftCardBottomShadow: 40px;
$bgCircleLarge: 1292px;
$titleBarHeightLarge: 340px;
$titleBarHeightSmall: 250px;
$topUpPanelOffset: 0;

.src-content-container {
  display: flex;
  align-items: flex-start; // Aligns both elements at the top
  justify-content: space-between; // Keeps space between them
  gap: 20px; // Adds spacing between text and panel

  @media (max-width: 768px) {
    flex-direction: column; // Stack items vertically on small screens
    align-items: center; // Center align content
  }
}

.text-container {
  flex: 1; // Makes text take up remaining space
  max-width: 70%; // Adjust width if needed

  p {
    margin: 20px 10px 20px 0;
    line-height: 1.5;
  }

  @media (max-width: 768px) {
    max-width: 100%;
  }
}

.wrap {
  position: relative; // to container loader
  margin: 0 auto; // center content
  width: $siteMaxWidth;
  max-width: 100%;
  min-height: 100px; // make loader always visible
}

.title {
  display: block;
  font-size: 16px;
  font-weight: $semiBold;
  color: $darkGrey;
}

.main-title {
  line-height: normal;
  font-weight: bold;
}

.main-content {
  display: grid;
  gap: 32px;
  font-weight: $light;
}

.separator {
  display: flex;
  align-items: center;
  justify-content: center;
}

.separator-line {
  width: 2px;
  height: 40px;
  background-color: #e2e2ea;
}

.modal-title {
  font-size: 30px;
  color: $darkGrey;
}

.modal-caption {
  line-height: 30px;
  color: $lightBlue2;
  text-align: center;
}

.game-items {
  display: flex;
  align-items: center;
  gap: 20px;
  max-width: 100%;
  flex-wrap: wrap;
}

.banner-wrap {
  flex: 0 0 auto;
  max-width: 250px;
}

.banner {
  width: 100%;
  height: auto;
  border-radius: 12px;
  box-shadow: 2px 2px 8px rgb(0 0 0 / 10%);
  border: 2px solid #ddd;
}

.game-items p {
  flex: 1;
  font-size: 16px;
  line-height: 1.5;
}

@media (max-width: 768px) {
  .game-items {
    flex-direction: column;
    text-align: center;
  }

  .banner-wrap {
    max-width: 220px;
  }
}
