@import "../../../theme/variables";

.wrap {
  position: relative;
  z-index: 0;
  padding-top: 95px;
  padding-bottom: 120px;
  background: url("../assets/IconCircles.svg") no-repeat 135px 190px / 85px 100px,
    url("../assets/IconCircle.svg") no-repeat calc(100% + 165px) -160px / 595px $darkBlue;

  * {
    color: #fff;
  }
}

.content {
  margin: 0 auto;
  width: $siteMaxWidth;
  max-width: 100%;
}

.header {
  margin-bottom: 90px;
}

.header-section {
  width: $mobileWidth;
  max-width: 100%;

  @include for-size(tablet-landscape-up) {
    width: 50%;
  }
}

.title {
  text-align: center;

  @include for-size(tablet-landscape-up) {
    padding-right: 95px;
    text-align: left;
  }
}

.caption {
  line-height: 30px;
  color: rgb(255 255 255 / 50%);
  text-align: center;
  font-weight: $medium;

  @include for-size(tablet-landscape-up) {
    max-width: 300px;
    text-align: left;
  }
}

.separator {
  opacity: 0.2;
}

.package {
  grid-gap: 20px !important;
  padding: 50px 0;

  @include for-size(tablet-portrait-max) {
    grid-template-columns: 1fr 1fr !important;
    gap: 40px 20px !important;
  }

  @include for-size(tablet-portrait-up) {
    grid-gap: 40px 20px !important;
  }

  @include for-size(phone-only) {
    grid-template-columns: 1fr !important;
    justify-items: center;
  }

  @include for-size(tablet-landscape-up) {
    grid-gap: 20px !important;
  }
}

.package-section {
  font-size: 14px;
}

.package-name {
  font-size: 22px;
  font-weight: bold;
}

.highlighted {
  color: $yellow;
}

.feature {
  display: flex;
  align-items: center;

  & + & {
    margin-top: 15px;
  }
}

.country-dropdown {
  margin-top: $gutterMedium;
  margin-left: 39px; // feature width + feature margin
}

.country-list-trigger {
  $size: 7px;

  position: relative;
  padding-right: 20px;
  font-weight: bold;
  text-align: left;
  color: $yellow;

  &:after {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    border-top: $size solid $yellow;
    border-right: $size solid transparent;
    border-left: $size solid transparent;
    width: 0;
    height: 0;
    transform: translateY(-50%);
  }

  &--open {
    &:after {
      border-top: 0;
      border-bottom: $size solid $yellow;
    }
  }
}

.contry-list {
  margin-top: $gutterMedium;
  padding-left: 0;
  list-style: none;
}

.country {
  margin-bottom: $gutterSmall;
  font-weight: $light;
  color: $lightBlue2;
}

.feature-icon {
  flex-shrink: 0;
  margin-right: 15px;
}

.price {
  font-size: 48px;
  font-weight: bold;
}

.cart-button {
  align-self: center;
  justify-self: center;
}
