@import "../../../theme/variables";

.error {
  margin-bottom: $gutterMedium;
  text-align: center;
  color: $lightRed;
  font-style: italic;
  font-weight: $light;
}

.button {
  width: 100%;

  @include for-size(tablet-portrait-up) {
    width: 250px;
    max-width: 100%;
  }

  & + & {
    margin-top: $gutterMedium;

    @include for-size(tablet-portrait-up) {
      margin-top: 0;
      margin-left: $gutterMedium;
    }
  }
}

.summary-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 17px;
  text-align: center;

  &--total {
    margin-bottom: $gutterMedium;
    color: $sFOrange;
  }

  &--available-coins {
    margin-bottom: $gutterLarge;
    color: $lightBlue2;
  }
}

.icon {
  &--total {
    fill: $sFOrange;
  }

  &--available-coins {
    fill: $lightBlue2;
  }
}
