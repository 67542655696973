@import "../../theme/variables.scss";

.selector-wrapper {
  position: relative;

  .selector {
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 2;
    margin-top: 5px;
    border-radius: 3px;
    width: 100%;
    transform: scale(1, 0.9) translateY(-20px);
    overflow: hidden;
    opacity: 0;
    transition: opacity 0.3s, transform 0.3s;
    pointer-events: none;
    will-change: transform opacity;

    &--open {
      transform: none;
      opacity: 1;
      pointer-events: all;
    }
  }
}

.selected-item-button {
  display: flex;
  align-items: center;
  border-radius: 3px;
  width: 100%;
  min-height: $fieldHeight;
  font-size: 18px;
  font-weight: bold;

  span {
    margin-right: auto;
  }

  &--selector-item {
    display: flex;
    align-items: center;
    margin: 0;
    border-radius: 0;
    width: 100%;
    min-height: 55px;
    font-size: 18px;
    font-weight: bold;

    span {
      margin-right: auto;
    }

    p {
      margin-right: $gutterMedium;
    }

    &:hover {
      background-color: $darkBlue2;
    }

    &:not(:first-of-type) {
      border-top: 1px solid $darkBlue2;
    }
  }

  .arrow-down {
    margin-left: $gutterSmall;
    opacity: 1;
    fill: $sFOrange;

    &--reverse {
      transform: scale(1, -1);
    }
  }
}

.selected-item {
  &--name {
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
  }

  &--price {
    display: block;
    margin-left: 15px;
    font-size: 14px;
    line-height: 17px;
    font-weight: 300;
    white-space: nowrap;
  }
}
