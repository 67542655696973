@import "../../theme//variables.scss";

.validation-form {
  display: flex;
  flex-direction: column;

  @include for-size(desktop-up) {
    flex-direction: row;
  }
}

.button {
  margin-top: $gutterMedium;

  @include for-size(desktop-up) {
    margin-top: 0;
    margin-left: 5px;
  }

  &:hover {
    background-color: $blue;
  }
}

.invitation-paragraph {
  font-size: 14px;
  color: $darkGrey;
  line-height: 22px;

  &--highlight {
    margin-right: 5px;
  }

  &--with-gutter {
    margin-bottom: $gutterMedium;
  }
}

.link {
  margin: $gutterMedium 0;
  font-size: 14px;
  font-weight: 600;
  font-style: italic;
  color: $yellow;

  @include for-size(phone-only) {
    text-align: center;
  }
}
