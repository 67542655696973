@import "../../theme//variables.scss";

.wrap {
  position: relative;
  box-shadow: inset 0 1px 27px 0 #7789ad73;
  border: 1px solid rgb(119 137 173 / 15%);
  border-radius: 14px;
  width: 900px;
  max-width: 100%;

  & + & {
    margin-top: $gutterLarge;
  }

  &--attention {
    background-color: rgb(255 174 0 / 15%);
  }
}

.main {
  padding: $gutterSemiLarge 0 $gutterMedium 0;

  @include for-size(tablet-portrait-up) {
    padding: $gutterSemiLarge $gutterMedium;
  }

  @include for-size(tablet-landscape-up) {
    padding: $gutterLarge $gutterSemiLarge $gutterLarge 50px;
    height: 170px;
  }
}

.title-wrap {
  margin: 0 $gutterMedium $gutterMedium;

  @include for-size(tablet-portrait-up) {
    justify-content: flex-start;
    padding-left: 25%;
    width: 100%;
  }

  @include for-size(tablet-landscape-up) {
    justify-content: stretch;
    margin: 0 $gutterMedium 0 0;
    padding-left: 0;
    width: auto;
  }
}

.button-wrap {
  @include for-size(phone-only) {
    margin-bottom: $gutterSmall;
  }

  @include for-size(desktop-up) {
    margin-bottom: 0;
  }
}

.subscribers-wrap {
  margin-bottom: $gutterMedium;

  @include for-size(tablet-portrait-up) {
    margin-right: $gutterSemiLarge;
    margin-bottom: 0;
  }
}

.customers-link {
  margin-bottom: $gutterMedium;

  @include for-size(tablet-portrait-up) {
    margin-right: $gutterMedium;
    margin-bottom: 0;
  }
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: $gutterMedium;
  width: 90px;

  @include for-size(tablet-portrait-up) {
    margin-right: $gutterLarge;
    margin-bottom: 0;
  }
}

.title-content {
  @include for-size(phone-only) {
    width: 100%;
  }

  @include for-size(tablet-portrait-up) {
    justify-self: start;
  }
}

.header {
  text-align: center;
  font-size: 30px;
  font-weight: $extraLight;
  color: $darkBlue;

  @include for-size(tablet-portrait-up) {
    text-align: left;
  }

  @include for-size(tablet-landscape-up) {
    font-size: 26px;
  }

  @include for-size(desktop-up) {
    font-size: 30px;
  }
}

$iconSize: 13px;
$iconOffset: 8px;

.status {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  padding-left: calc(#{$iconSize} + #{$iconOffset});
  font-size: 12px;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    box-shadow: -3px 3px 6px 0 $offWhite;
    border: 1px solid;
    border-radius: 50%;
    width: $iconSize;
    height: $iconSize;
    background: #fff;
  }

  &--active {
    &:before {
      border-color: $blue;
    }
  }

  &--inactive {
    &:before {
      border-color: $sFOrange;
    }
  }

  .status-title {
    margin-right: 5px;
    font-weight: bold;
    font-style: italic;
    color: $darkBlue;

    &--attention {
      color: $red;
    }
  }

  .price {
    position: relative;
    padding-left: 6px;
    font-weight: $light;
    color: $lightBlue2;

    &:before {
      content: "|";
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &--free {
    position: relative;
    padding-left: 5px;
    font-weight: $extraBold;
    text-transform: uppercase;
    color: $sFOrange;

    &:before {
      content: "|";
      position: absolute;
      top: 0;
      left: 0;
      font-weight: $light;
      color: $lightBlue2;
    }
  }
}

.price-disclaimer {
  margin-top: 5px;
  margin-left: $iconSize + $iconOffset;
  font-size: 12px;
  font-weight: 300;
  font-style: italic;
  opacity: 0.7;
}

.producer {
  position: relative;
  margin: $gutterSemiLarge $gutterMedium 0 $gutterMedium;
  font-size: 10px;
  font-weight: 500;
  font-style: italic;
  color: #b4bedc;

  @include for-size(phone-only) {
    margin-top: 0;
  }

  @include for-size(tablet-landscape-up) {
    position: absolute;
    bottom: 0;
    left: 0;
    margin: 0 $gutterSmall $gutterSmall 25px;
  }
}

.bottom-message {
  position: relative;
  margin: $gutterSemiLarge $gutterMedium 0 $gutterMedium;
  font-size: 14px;
  font-weight: 600;
  font-style: italic;
  color: #666;

  @include for-size(phone-only) {
    margin-top: 0;
  }

  @include for-size(tablet-landscape-up) {
    position: absolute;
    bottom: 10px;
    left: 10px;
    margin: 0 $gutterSmall $gutterSmall 25px;
  }

  &.light {
    font-weight: 400;
  }
}

.landing-page-link {
  flex-shrink: 0;
  margin-bottom: $gutterMedium;
  font-style: italic;
  color: $lightBlue2;

  @include for-size(phone-only) {
    width: 100%;
    min-height: 40px;
    background: #f1f1f5;
  }

  @include for-size(tablet-portrait-up) {
    margin-right: $gutterSmall;
    margin-bottom: 0;
    box-shadow: 0 10px 20px -10px rgb(0 0 0 / 50%);
  }
}

.cart-button {
  font-weight: bold;
  font-size: 14px;

  @include for-size(phone-only) {
    margin-bottom: $gutterMedium;
    border-radius: 0;
    width: 100%;
    min-height: 40px;
    font-size: 12px;
    text-transform: uppercase;
    font-style: italic;
  }

  @include for-size(tablet-portrait-up) {
    border-radius: $radiusSmall;
  }
}

.cart-icon {
  opacity: 0.3;
}

.section {
  margin: 0 0 $gutterMedium;
  border-top: 1px dashed $offWhite;
  padding: $gutterSemiLarge 0 $gutterMedium 0;

  @include for-size(tablet-portrait-up) {
    margin: 0 $gutterMedium;
    padding: $gutterSemiLarge $gutterMedium;
  }

  @include for-size(tablet-landscape-up) {
    padding: $gutterSemiLarge $gutterSmall $gutterLarge $gutterSemiLarge;
  }
}

.section-content {
  flex-shrink: 1;
  margin: 0 $gutterMedium $gutterSemiLarge;
  border-left: 3px solid $sFOrange;
  padding: 5px $gutterSemiLarge 5px $gutterSmall;
  font-size: 12px;
  line-height: 18px;
  font-weight: $light;

  @include for-size(tablet-portrait-up) {
    margin: 0 0 $gutterSemiLarge;
  }

  @include for-size(tablet-landscape-up) {
    margin: 0;
  }
}

.expiration-date {
  margin-top: $gutterMedium;
  color: $sFOrange;
}

.exclusive-offer-button {
  @include for-size(phone-only) {
    width: 100% !important;
    font-style: italic;
    text-transform: uppercase;
  }

  @include for-size(tablet-portrait-up) {
    flex-shrink: 0;
    align-self: flex-start;
  }
}
