@import "../../theme/variables";

.user-toolbar {
  flex-basis: 40px;
  padding: 0 $appSidePadding;
  font-size: 12px;
  background: $darkBlue;

  * {
    color: $lightGrey2;
  }
}

.user-toolbar-cta {
  @include for-size(phone-only) {
    display: flex;
    align-items: center;
    min-height: 60px;
  }
}

.tools {
  display: flex;
}

.addon {
  margin-left: $gutterSmall;
  font-style: italic;
  color: $offWhite;
  fill: $offWhite;
}

.section {
  margin-left: 30px;

  @include for-size(tablet-landscape-up) {
    margin-left: 50px;
  }
}

.invitation-cta-text {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 12px;
  line-height: 24px;

  @include for-size(phone-only) {
    height: 48px;
  }

  &--highlighted {
    font-weight: bold;
    font-style: italic;
    color: $yellow;
    white-space: pre-wrap;
  }
}

.text {
  display: none;

  @include for-size(tablet-landscape-up) {
    display: inline-block;
    font-size: 12px;
  }
}

.emoji {
  width: auto;
  height: 12px;
}
