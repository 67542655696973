@import "../../theme/variables.scss";

.title {
  margin: $gutterMedium 0;
}

.grid-wrap {
  display: grid;
  grid-template-rows: repeat(8, auto);
  grid-template-areas: "name " "name-data" "period " "period-data" "purpose " "purpose-data" "mandatory " "mandatory-data";
  gap: $gutterSmall;
  border: 1px solid black;

  @include for-size(tablet-landscape-up) {
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: repeat(4, 1fr);
    grid-template-areas: "name period purpose mandatory" "name-data period-data purpose-data mandatory-data";
    gap: $gutterSmall;
  }
}

.grid-title {
  font-weight: bold;
}

.grid-name {
  grid-area: name;
}

.grid-period {
  grid-area: period;
}

.grid-purpose {
  grid-area: purpose;
}

.grid-mandatory {
  grid-area: mandatory;
}

.grid-name-data {
  grid-area: name-data;
}

.grid-period-data {
  grid-area: period-data;
}

.grid-purpose-data {
  grid-area: purpose-data;
}

.grid-mandatory-data {
  grid-area: mandatory-data;
}
