@import "../../theme//variables.scss";

.menuContainer {
  position: relative;

  &:hover {
    .dropdown {
      display: block;
      position: absolute;
      z-index: 99;
      top: 100%;
      right: 0;
      padding-top: $gutterMedium;
    }
  }

  a {
    text-decoration: none;
  }
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    .label {
      color: $sFOrange;
      transition: color 0.2s ease-in-out;
    }

    .icon {
      fill: $sFOrange;
      transition: fill 0.2s ease-in-out;
    }
  }

  &--active {
    .label {
      color: $sFOrange;
    }

    .icon {
      fill: $sFOrange;
    }
  }
}

.label {
  font-size: 10px;
  font-weight: bold;
  color: $darkBlue;
  transition: color 0.2s ease-in-out;

  @include for-size(phone-only) {
    display: none;
  }
}

.icon {
  margin: 6px;
  width: 33px;
  height: 33px;
  fill: $darkBlue;
  transition: fill 0.2s ease-in-out;
}

.dropdown {
  display: none;
}

.shadow {
  box-shadow: 0 -8px 29px 0 rgb(229 229 229 / 50%);
}

.menu {
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-radius: 5px 5px 0 0;
  background: #fff;
}

.link {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid $offWhite;
  width: 150px;
  height: 120px;
  color: #000;
  text-transform: uppercase;

  &:nth-child(odd) {
    border-right: 1px solid $offWhite;
  }

  svg {
    margin: 6px;
    width: 33px;
    height: 33px;
    fill: $sFOrange;
  }

  &--disabled {
    $disabledColor: rgb(173 173 173);

    pointer-events: none;
    color: $disabledColor;

    svg {
      fill: $disabledColor;
    }
  }
}

.link-label {
  font-size: 10px;
  font-weight: bold;
}

.logout {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 0 5px 5px;
  height: 36px;
  font-size: 10px;
  text-decoration: none;
  color: $white;
  font-weight: bold;
  background: $offWhite;
}
