@import "../../theme/variables";

.wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  z-index: 9999;
  right: 0;
  bottom: 0;
  box-shadow: 0 0 8px 0 rgb(0 0 0 / 40%);
  border-top-left-radius: $radiusSmall;
  padding: $gutterMedium;
  width: 100%;
  background: rgb(255 255 255 / 90%);

  @include for-size(tablet-portrait-up) {
    flex-direction: row;
    width: auto;
  }
}

.notification {
  margin-bottom: $gutterMedium;
  font-size: 14px;
  font-weight: $semiBold;
  text-align: center;

  @include for-size(tablet-portrait-up) {
    margin-right: $gutterMedium;
    margin-bottom: 0;
    max-width: 200px;
    text-align: left;
  }
}

.icon {
  opacity: 0.6;
}
