@import "../../../theme/variables";

.wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: $gutterLarge;
  padding-top: $gutterMedium;
  padding-bottom: $gutterMedium;
  min-height: 690px;

  * {
    color: #fff;
  }

  > * {
    flex-grow: 1;
  }
}

.banner-wrap {
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
}

.banner {
  position: absolute;
  right: 0;
  object-fit: fill;
  min-width: 100%;
  min-height: 100%;
}

.title {
  line-height: normal;
}

.text {
  margin-bottom: $gutterMedium;
}

.button-wrap {
  @include for-size(tablet-landscape-up) {
    flex-direction: column;
  }
  @include for-size(desktop-up) {
    flex-direction: row;
  }
}

.product-selector {
  align-self: flex-start;
  margin-bottom: $gutterMedium;
  width: 100%;

  @include for-size(tablet-portrait-up) {
    flex-basis: calc(50% - #{$gutterMedium} / 2);
    flex-shrink: 0;
  }

  @include for-size(desktop-up) {
    margin-bottom: 0;
  }
}

.cart-button {
  align-self: flex-start;
  width: 100%;

  @include for-size(tablet-portrait-up) {
    flex-basis: calc(50% - #{$gutterMedium} / 2);
    flex-shrink: 0;
  }

  @include for-size(desktop-up) {
    max-width: calc(50% - 20px);
  }
}
