@import "../../theme/variables";

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 15px;
  text-align: center;

  &--PRIMARY {
    min-width: 160px;
    font-size: 14px;
    font-weight: bold;

    @include for-size(phone-only) {
      margin-bottom: $gutterMedium;
      border-radius: 0 !important;
      width: 100%;
      min-height: 40px !important;
      font-size: 12px;
      font-style: italic;
      text-transform: uppercase;
    }
  }

  &--SECONDARY {
    width: 100%;
    min-height: 55px;
    font-size: 18px;
    font-weight: $medium;
  }
}

.link {
  padding: 5px 25px;
}

.label {
  position: relative;
  line-height: 23px;
  text-align: center;
}

.icon {
  position: absolute;
  top: 50%;
  right: 0;
  margin-left: 0 !important;
  transform: translateY(-50%);

  &--PRIMARY {
    opacity: 0.3;
    fill: #fff;
  }

  &--SECONDARY {
    fill: $sFOrange;
  }
}

.modal-title {
  font-size: 30px;
  color: $darkGrey;
}

.modal-caption {
  line-height: 30px;
  color: $lightBlue2;
  text-align: center;
}

.form-title {
  margin-bottom: $gutterMedium;
}

.package {
  & + & {
    margin-top: $gutterMedium;
  }
}

.package-name {
  color: #fff;
  font-weight: $light;
}

.package-price {
  color: $lightBlue2;
  font-weight: $light;
}

.cart-button-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}
