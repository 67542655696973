@import "../../theme//variables.scss";

.button {
  align-self: flex-start;
  margin-bottom: $gutterMedium;
}

.highlight {
  color: $sFOrange;
}
