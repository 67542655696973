@import "../../theme/variables";

.wrap {
  $fieldRadius: calc(#{$fieldHeight} / 2);

  display: flex;
  position: relative;
  z-index: 0;
  margin: 0 auto;
  border-radius: $fieldRadius;
  width: 600px;
  max-width: 100%;

  .input {
    border: none;
    border-radius: $fieldRadius 0 0 $fieldRadius;
    padding-left: $gutterLarge;

    @include for-size(tablet-portrait-up) {
      padding-left: $gutterExtraLarge;
    }

    &::placeholder {
      color: $lightBlue2;
      font-weight: $normal;
    }
  }

  .button {
    margin-bottom: auto;
    margin-left: 0; // removes unwanted gap in iOS
    border: none;
    border-radius: 0 $fieldRadius $fieldRadius 0;
    padding: 5px $gutterMedium;
    height: $fieldHeight;
    font-size: 16px;
    color: #fff;

    @include for-size(tablet-portrait-up) {
      padding: 5px $gutterSemiLarge;
    }

    &:focus {
      outline: none;
    }

    &--ORANGE {
      background: $sFOrange;
    }

    &--DARK_BLUE {
      background: $darkBlue;
    }
  }
}

.input-wrap {
  border: none;
  height: 50px;
}

.icon {
  position: absolute;
  top: calc(#{$fieldHeight} / 2);
  left: 15px;
  transform: translateY(-50%);

  @include for-size(tablet-portrait-up) {
    left: $gutterSemiLarge;
  }
}

.field {
  flex-grow: 1;
  z-index: -1;
}
