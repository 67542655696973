@import "../../theme/variables";

.wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 0 $gutterMedium;
  background: $offWhite2;

  @include for-size(tablet-portrait-up) {
    padding: 50px $gutterSmall 0;
  }

  @include for-size(tablet-landscape-up) {
    padding: 50px $gutterMedium 0;
  }

  &--single-child {
    padding: 50px 0 $gutterMedium;

    @include for-size(tablet-portrait-up) {
      padding: 50px $gutterMedium $gutterMedium;
    }

    @include for-size(tablet-landscape-up) {
      padding: 50px $gutterLarge $gutterMedium;
    }
  }
}

.carousel {
  max-width: 100%;

  :global(.slider-wrapper) {
    width: 940px;
    max-width: 100%;
  }
}

.icon {
  margin-bottom: $gutterMedium;
}

.title {
  margin-bottom: 50px;
  padding: 0 $gutterSmall;
  font-size: 20px;
  text-align: center;
  color: $lightBlue2;
}
