@import "../../../theme/variables";

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  max-width: 100%;
}

.wrap {
  width: $siteMaxWidth;
  max-width: 100%;

  &--reverse {
    @include for-size(tablet-landscape-up) {
      flex-direction: row-reverse;
    }

    .image-wrap {
      @include for-size(tablet-landscape-up) {
        margin-right: 0;
        margin-left: $gutterLarge;
      }
    }
  }
}

.content {
  @include for-size(tablet-landscape-up) {
    max-width: calc(50% - #{$gutterLarge} / 2);
  }
}

.content-container {
  @include for-size(phone-only) {
    padding: 20px 10px;
  }
}

.image-wrap {
  margin-bottom: $gutterMedium;
  width: 665px;
  max-width: 100%;

  @include for-size(tablet-landscape-up) {
    margin-right: $gutterLarge;
    margin-bottom: 0;
    max-width: calc(50% - #{$gutterLarge} / 2);
  }
}

.title-logo {
  margin: $gutterSmall 0 0 0;

  @include for-size(tablet-landscape-up) {
    margin: 0 $gutterSmall 0 0;
  }

  @include for-size(desktop-up) {
    margin: 0 $gutterMedium 0 0;
  }
}

.head {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  white-space: nowrap;
  margin-bottom: $gutterMedium;

  @include for-size(phone-only) {
    margin: $gutterMedium 0;
  }

  @include for-size(tablet-portrait-up) {
    margin: 0 0 $gutterSmall 0;
  }

  @include for-size(tablet-landscape-up) {
    flex-direction: row;
    margin: 0 auto $gutterSmall 0;
  }
}

.title-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include for-size(tablet-landscape-up) {
    flex-direction: row;
    margin-right: auto;
  }
}

.title-text {
  margin: 0;
  color: $darkBlue;
  font-family: Montserrat, sans-serif;

  @include for-size(phone-only) {
    font-size: 20px;
  }

  @include for-size(tablet-portrait-up) {
    margin-bottom: $gutterSmall;
    font-size: 24px;
  }

  @include for-size(tablet-landscape-up) {
    margin: 0 auto 0 0;
    padding-right: $gutterSmall;
  }

  @include for-size(desktop-up) {
    padding: 0;
    font-size: 30px;
  }

  @include for-size(big-desktop-up) {
    font-size: 36px;
  }
}

.button-wrap {
  display: flex;
  justify-content: center;

  @include for-size(phone-only) {
    flex-direction: column;
    align-items: center;
  }

  @include for-size(tablet-portrait-up) {
    justify-content: flex-start;
  }

  > * + * {
    margin-top: $gutterLarge;

    @include for-size(tablet-portrait-up) {
      margin-top: 0;
      margin-left: $gutterMedium;
    }

    @include for-size(desktop-up) {
      margin-left: $gutterLarge;
    }
  }
}

.button {
  width: 100%;
  min-height: 55px;

  &--secondary {
    box-shadow: 0 10px 20px -10px rgb(0 0 0 / 50%);
    min-height: 55px;
    color: #000;
    font-style: italic;
  }
}

.cart-icon {
  width: 22px;
  height: 22px;
  fill: $sFOrange !important;
}

.badge {
  min-width: 130px;
}
