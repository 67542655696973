@import "../../theme/variables.scss";

.button {
  padding: 24px 0 15px;

  span {
    display: block;
    font-size: 24px;
    font-weight: 300;
    color: #fff;
  }
}

.productContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $white;

  @include for-size("phone-only") {
    margin-top: 0;
    margin-bottom: 100px;
  }

  @include for-size("tablet-portrait-up") {
    margin-top: 60px;
    margin-bottom: 100px;
  }

  .inner {
    display: flex;
    justify-content: center;

    @include for-size("phone-only") {
      flex-direction: column;
      align-items: center;
      padding: 0;
      width: 100%;
    }

    @include for-size("tablet-portrait-up") {
      flex-direction: column;
      align-items: center;
      padding: 0 $appSidePadding;
    }

    @include for-size("desktop-up") {
      flex-direction: row;
      padding: 0 $appSidePadding;
    }

    .column {
      display: flex;
      flex-grow: 1;
      flex-direction: row;
      justify-content: space-evenly;

      @include for-size("phone-only") {
        margin: 0;
        margin-bottom: 20px;
      }

      @include for-size("tablet-portrait-up") {
        margin: 20px;
        width: 100%;
      }

      @include for-size("desktop-up") {
        max-width: 50%;
      }
    }

    .product {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      @include for-size("phone-only") {
        padding: 0 $mobileSitePadding;
      }

      .rating {
        display: flex;
        flex-direction: row;
        align-items: center;

        .reviews {
          padding-left: 9px;
          font-size: 14px;
          line-height: 17px;
          font-weight: 300;
          color: #696974;
        }
      }

      .pricing {
        display: flex;
        justify-content: space-between;
        width: 100%;
        font-weight: 300;

        @include for-size("phone-only") {
          flex-direction: column;
          align-items: flex-end;
          margin: 20px 0;
        }

        @include for-size("tablet-portrait-up") {
          flex-direction: row;
          align-items: center;
        }

        .regular {
          font-size: 19px;
          line-height: 23px;
          text-decoration: line-through;
          color: $yellow;
        }

        .discount {
          font-size: 26px;
          line-height: 31px;
          color: #d5d5dc;

          span {
            color: $blue;
          }
        }
      }

      .description {
        width: 100%;
        font-size: 16px;
        line-height: 40px;
        font-weight: 300;
        color: $lightGrey;

        @include for-size("phone-only") {
          margin-top: 50px;
        }

        @include for-size("tablet-portrait-up") {
          margin-top: 25px;
        }
      }

      .options {
        display: flex;
        justify-content: flex-start;
        width: 100%;

        @include for-size("phone-only") {
          flex-direction: column;
        }

        @include for-size("tablet-portrait-up") {
          flex-direction: column;
          margin-top: 40px;
        }

        @include for-size("desktop-up") {
          flex-direction: row;
          margin-top: 40px;
        }

        div {
          display: flex;
          flex-grow: 1;

          &:first-child {
            @include for-size("desktop-up") {
              margin-right: 20px;
            }
          }
        }
      }

      .cartOptions {
        align-items: center;

        @include for-size("phone-only") {
          display: block;
          flex-direction: column;
          justify-content: center;
          margin-top: 12px;
          width: 100%;
        }

        @include for-size("tablet-portrait-up") {
          display: block;
          flex-direction: column;
          justify-content: center;
          margin-top: 12px;
          width: 100%;
        }

        @include for-size("desktop-up") {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin-top: 30px;
        }

        .cart {
          display: flex;

          @include for-size("phone-only") {
            flex-direction: column;
            align-items: center;
            justify-content: flex-end;
          }

          // Add to cart
          input {
            border: none;
            border-radius: 40px;
            height: 80px;
            font-size: 24px;
            line-height: 29px;
            font-weight: 300;
            text-align: center;
            text-decoration: none;
            color: $white;
            background-color: $blue;

            @include for-size("phone-only") {
              margin-top: 12px;
              width: 100%;
            }

            @include for-size("tablet-portrait-up") {
              margin-top: 12px;
              width: 100%;
              min-width: 400px;
            }

            @include for-size("desktop-up") {
              margin-top: 0;
              width: 480px;
            }

            svg {
              opacity: 0.6;
            }
          }
        }
      }

      // Styling for "Hurry!" label
      .hurry {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        position: relative;
        margin: 30px 0;
        width: 100%;
        font-size: 20px;
        line-height: 19px;
        font-family: Signpainter, sans-serif;
        font-display: swap;
        color: $yellow;

        @include for-size("phone-only") {
          justify-content: center;
        }

        @include for-size("tablet-portrait-up") {
          justify-content: flex-end;
        }

        & > div {
          position: absolute;
        }

        svg {
          position: absolute;
          transform: scaleX(-1) rotate(12deg);

          @include for-size("phone-only") {
            left: 0;
          }

          @include for-size("tablet-portrait-up") {
            right: 200px;
          }
        }
      }
    }
  }
}

.hidden-mobile {
  @include for-size("phone-only") {
    display: none;
  }
}

.visible-mobile {
  @include for-size("tablet-portrait-up") {
    display: none;
  }
}
