@import "../../theme/variables";

.field-wrap {
  max-width: 680px;
}

.error {
  margin: $gutterMedium 0;
  border-radius: $radiusSmall;
  padding: $gutterMedium;
  color: $red;
  background-color: rgb(206 17 38 / 5%);
}

.submit-button {
  margin-top: $gutterMedium;
}
