@import "../../theme/variables.scss";

.home {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
}

.product-description-text {
  display: flex;
  flex-direction: column;
  margin-bottom: $gutterMedium;
  font-size: 14px;
  line-height: 24px;
  font-weight: $extraLight;

  @include for-size(tablet-portrait-up) {
    font-size: 16px;
    line-height: 30px;
    font-weight: $light;
  }

  & + & {
    margin-top: $gutterSemiLarge;
  }

  b {
    color: $sFOrange;
  }
}

.forex-cta {
  font-weight: bold;
}

.membership-cta {
  font-weight: bold;
}

.bank-cta {
  font-weight: $extraBold;
}

.forex-cta-highlighted {
  color: $sFOrange;
}

.product-section-img {
  display: flex;
  height: 506px;
}

.product-details-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 600px;

  @include for-size(phone-only) {
    padding: 0 50px;
  }
}

.product-radio-wrap {
  display: flex;
}

.product-radio {
  margin-top: 5px;
  margin-right: $gutterSmall;
  width: 16px;
  height: 16px;
}

.product-radio-label {
  display: inline-block;
}

.price-disclaimer {
  display: block;
  margin-bottom: $gutterSmall;
  font-size: 12px;
  line-height: 1;
  font-weight: 300;
  font-style: italic;
  opacity: 0.7;
}

.upgrade-modal-title {
  font-size: 30px;
  text-align: center;
  font-weight: $semiBold;
  color: $darkGrey;
}

.view-logo {
  width: 75px;
}

.membership-logo {
  width: 75px;
}

.sign-up-wrap {
  position: relative;
  padding-top: 80px;
  padding-bottom: 60px;

  @include for-size(tablet-portrait-up) {
    padding: 150px $gutterMedium 0;
  }

  @include for-size(tablet-landscape-up) {
    display: flex;
    justify-content: center;
    padding: 150px $gutterLarge 0;
  }
}
