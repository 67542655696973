@import "../../../theme/variables.scss";

.form {
  width: 100%;
  max-width: 625px;
}

.field {
  width: 100%;

  & + & {
    margin-top: $gutterMedium;
  }

  input,
  select {
    color: $lightBlue2;
  }
}

.modal-content {
  max-width: calc(100% - #{$gutterMedium} * 2);
}

.password-field {
  margin-bottom: $gutterMedium;
  width: calc(626px - #{$gutterSmall} * 2);
  max-width: 100%;

  @include for-size(tablet-portrait-up) {
    width: calc(626px - #{$gutterMedium} * 2);
    max-width: 100%;
  }

  @include for-size(tablet-landscape-up) {
    width: calc(626px - #{$gutterLarge} * 2);
    max-width: 100%;
  }
}

.modal-description {
  margin-bottom: $gutterMedium;
}
