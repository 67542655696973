@import "../../theme/variables.scss";

.hero-title {
  font-size: 30px;

  @include for-size(tablet-portrait-up) {
    font-size: 40px;
  }
}

.steps-wrap {
  position: relative;
  padding-top: 57px; // image-related magic number

  @include for-size(tablet-landscape-up) {
    margin-bottom: 0;
  }
}

.steps {
  margin: 0;
  padding: 0;
  list-style: none;
  font-weight: 600;
  text-align: center;

  @include for-size(tablet-portrait-up) {
    text-align: left;
  }

  strong {
    color: $sFOrange;
  }

  &-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: $gutterLarge;

    @include for-size(tablet-portrait-up) {
      flex-direction: row;
    }
  }

  &-icon {
    flex: 0 0 55px;
    box-shadow: 0 10px 20px 0 rgb(255 197 66 / 20%);
    border: 5px solid #fff;
    border-radius: 50%;
    padding: 10px;
    width: 55px;
    height: 55px;
    background-color: $yellow;

    @include for-size(tablet-portrait-up) {
      margin-right: $gutterMedium;
    }
  }
}

.check-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.hero {
  margin-bottom: 150px;
}

.hero-illustration {
  margin-top: -10vw;
  margin-bottom: -50px;
  max-width: 480px;

  @include for-size(tablet-landscape-up) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.hero-content {
  line-height: 30px;

  @include for-size(phone-only) {
    font-size: 14px;
  }
}

.title {
  line-height: normal;
}

.ribbon {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 120px;
  box-shadow: -50px 20px 50px -10px rgb(27 31 59 / 20%);
  border-bottom: 10px solid #fff;
  padding: $gutterLarge;
  text-align: center;
  color: #fff;
  background-color: $darkBlue2;

  @include for-size(tablet-landscape-up) {
    $ribbonHeight: 220px;

    flex-direction: row-reverse;
    position: relative;
    margin-right: $gutterExtraLarge;
    height: $ribbonHeight;
    min-width: 760px;
    max-width: 70vw;
    white-space: nowrap;

    &:after {
      content: "";
      position: absolute;
      right: 0;
      border-top: calc(#{$ribbonHeight} / 2) solid transparent;
      border-right: 50px solid #fff;
      border-bottom: calc(#{$ribbonHeight} / 2) solid transparent;
    }
  }

  &-label {
    font-size: 80px;
    line-height: 60px;
    font-family: Didot, serif;

    @include for-size(tablet-landscape-up) {
      margin-right: $gutterLarge;
      margin-left: auto;
    }

    &--small {
      display: block;
      font-size: 50px;
      font-family: Didot, serif;
    }
  }

  &-img {
    margin: -83px 0 $gutterSmall;
    width: 255px;
    height: 255px;

    @include for-size(tablet-landscape-up) {
      margin: 0 40px 0 0;
    }
  }
}

.forex-phones {
  display: flex;
  justify-content: center;
  margin-bottom: $gutterSemiLarge;

  @include for-size(tablet-landscape-up) {
    margin-bottom: $gutterExtraLarge;
  }

  img {
    width: 100%;
    //SAFARI FIX
    height: 100%;
    max-width: 1600px;
    max-height: 100%;
  }
}

.sign-up-wrap {
  $whiteCircleSize: 200px;
  $whiteCircleMobileOffset: 53px;
  $whiteCircleShadowSize: 51px;
  $titleGutter: $gutterExtraLarge;
  $titleLineHeight: 60px;
  $dotLineOffset: 62px;
  $circleSize: 628px;
  $yellowCircleDesktopOffset: 9px;

  padding-bottom: calc(#{$whiteCircleSize} + #{$whiteCircleShadowSize} - #{$whiteCircleMobileOffset});
  overflow: hidden;

  @include for-size(phone-only) {
    position: relative;
  }

  @include for-size(tablet-landscape-up) {
    padding-bottom: 100px;
  }

  .sign-up-panels {
    display: flex;
    gap: 30px;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 100%;

    @include for-size(tablet-landscape-up) {
      flex-direction: row;
      justify-content: center;
      align-items: flex-end;
      margin: 90px auto auto;
    }

    &:before {
      content: "";
      position: absolute;
      z-index: -2;
      top: 50%;
      left: 50%;
      border-radius: 50%;
      width: $circleSize;
      height: $circleSize;
      transform: translateY(-50%);
      background: $yellow;

      @include for-size(tablet-portrait-up) {
        transform: translate(-20%, -70%);
      }
    }

    &:after {
      content: "";
      position: absolute;
      z-index: -1;
      top: calc(100% - #{$whiteCircleMobileOffset});
      left: calc(100% - 110px);
      box-shadow: 0 1px $whiteCircleShadowSize 0 rgb(119 137 173 / 10%);
      border-radius: 50%;
      width: $whiteCircleSize;
      height: $whiteCircleSize;
      background: rgb(255 255 255 / 80%);
      backdrop-filter: blur(5px);

      @include for-size(tablet-landscape-up) {
        top: calc(100% - 139px);
        left: calc(100% - 63px);
      }
    }
  }

  .heading {
    margin-bottom: $titleGutter;
    max-width: 500px;
    font-size: 30px;
    font-weight: bold;

    @include for-size(tablet-landscape-up) {
      font-size: 40px;
      line-height: $titleLineHeight;
    }
  }

  .sign-up-panel {
    width: 100%;
    max-width: 440px;

    &:nth-of-type(2) {
      @include for-size(tablet-landscape-up) {
        transform: translateY(-30px);
      }
    }
  }

  .yellow-dots {
    position: absolute;
    top: calc(100% + 20px);
    left: 0;
  }

  .dotted-line-arrow {
    position: absolute;
    bottom: $whiteCircleShadowSize;
    left: 0;
  }
}
