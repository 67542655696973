@import "../../theme/variables";

.title-caption {
  align-self: center;
  width: 600px;
  max-width: 100%;
  text-align: center;
  color: $lightBlue2;
}

.button-wrap {
  justify-content: center;
  align-items: center;
}

.button {
  width: 100%;

  @include for-size(tablet-portrait-up) {
    width: 225px;
  }

  & + & {
    margin-top: $gutterMedium;

    @include for-size(tablet-portrait-up) {
      margin-top: 0;
      margin-left: $gutterMedium;
    }
  }
}

.form-wrapper {
  margin-top: 10px;
  padding: 0 10px;
}

.title-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.title-icon {
  margin-right: 15px;
}
