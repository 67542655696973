@import "../../theme/variables";

$giftCardOffset: 80px;
$giftCardBottomShadow: 40px;
$bgCircleLarge: 1292px;
$titleBarHeightLarge: 340px;
$titleBarHeightSmall: 250px;

.wrap {
  position: relative;
  z-index: 0;
  overflow: hidden;

  &:before,
  &:after {
    content: "";
    position: absolute;
    z-index: -1;
    bottom: calc(100% - (#{$bgCircleLarge} / 2) - #{$titleBarHeightSmall} - #{$giftCardOffset});
    left: 50%;
    border: 1px solid;
    border-radius: 50%;
    transform: translate(-50%, calc(50%));

    @include for-size(tablet-landscape-up) {
      bottom: calc(100% - (#{$bgCircleLarge} / 2) - #{$titleBarHeightLarge} - #{$giftCardOffset});
    }
  }

  &:before {
    width: 845px;
    height: 845px;
    border-color: rgba($darkBlue, 0.09);
  }

  &:after {
    width: $bgCircleLarge;
    height: $bgCircleLarge;
    border-color: rgba($darkBlue, 0.06);
  }
}

.titlebar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: calc(#{$giftCardOffset} + #{$giftCardBottomShadow} + #{$gutterSemiLarge});
  height: $titleBarHeightSmall;
  background: #000 url("./assets/gift-bg.jpg") no-repeat center center / cover;

  @include for-size(tablet-landscape-up) {
    height: $titleBarHeightLarge;
  }
}

.title {
  margin-bottom: $gutterExtraLarge;
  text-transform: capitalize;
  font-weight: $extraLight;
  font-size: 40px;
  color: #fff;

  @include for-size(tablet-landscape-up) {
    margin-bottom: 100px;
    font-size: 60px;
  }
}

.gift-card {
  position: absolute;
  bottom: -$giftCardOffset;
  box-shadow: 50px 40px 37px 2px rgb(27 31 59 / 15%);
}
