@import "../../theme/variables";

.separator {
  width: 100%;
  height: 1px;
  background: $offWhite;

  &--gutter-small {
    margin-bottom: $gutterSmall;
  }

  &--gutter-medium {
    margin-bottom: $gutterMedium;
  }

  &--gutter-semi-large {
    margin-bottom: $gutterSemiLarge;
  }

  &--gutter-large {
    margin-bottom: $gutterLarge;
  }

  &--gutter-extra-large {
    margin-bottom: $gutterExtraLarge;
  }

  &--has-children {
    position: relative;
    z-index: 0;
    height: auto;
    text-align: center;
    background: transparent;

    &:before {
      content: "";
      position: absolute;
      z-index: -1;
      top: 50%;
      left: 0;
      width: 100%;
      height: 1px;
      background: $offWhite;
    }

    & > * {
      display: inline;
      padding: 0 $gutterSemiLarge;
      background: #fff;
    }
  }

  &--secondary {
    position: absolute;
    width: 100%;
    height: 100px;
  }
}

.position {
  left: 0;

  &--top {
    top: 0;
    background: linear-gradient(180deg, #f8fbff 0%, rgb(255 255 255 / 0%) 100%);
  }

  &--bottom {
    bottom: 0;
    background: linear-gradient(0deg, #f8fbff 0%, rgb(255 255 255 / 0%) 100%);
  }

  &--on-background {
    z-index: -1;
  }
}
