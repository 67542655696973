@import "../../theme//variables.scss";

.closed {
  & > * {
    transform: translateX(100%);
    transition: transform 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
}

.open {
  & > * {
    transform: translateX(0);
    transition: transform 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
}

.closed.direction--bottom {
  & > * {
    transform: translateY(-100%);
    transition: transform 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
}

.open.closed.direction--bottom {
  & > * {
    transform: translateY(0);
    transition: transform 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
}

.menu {
  background-color: white;
}

.overlay {
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  pointer-events: none;
}

.fadeIn {
  opacity: 0.4;
  transition: opacity 0.4s ease-in;
}

.fadeOut {
  opacity: 0;
  transition: opacity 0.4s ease-out;
}
