@import "../../../theme/variables";

.title-caption {
  margin-bottom: $gutterLarge;
  text-align: center;
  line-height: 30px;
  color: $lightGrey;
}

.user-email {
  display: inline-block;
  word-break: break-all;
  color: $primaryText;
}

.checkmark {
  margin-left: $gutterMedium;
}

.arrow-icon {
  margin-left: $gutterMedium;
  height: 24px;
  transform: rotate(180deg);
  fill: #fff;
}

.text {
  margin-bottom: $gutterLarge;
  color: $lightGrey;
}
