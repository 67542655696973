@import "../../theme/variables";

.section {
  &--small-gutter {
    margin-bottom: $gutterSmall;
  }

  &--medium-gutter {
    margin-bottom: $gutterMedium;
  }

  &--semi-large-gutter {
    margin-bottom: $gutterSemiLarge;
  }

  &--large-gutter {
    margin-bottom: $gutterLarge;
  }

  &--extra-large-gutter {
    margin-bottom: $gutterExtraLarge;
  }

  &--center {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &--center-text {
    text-align: center;
  }

  &--center-self {
    margin-right: auto;
    margin-left: auto;
  }

  &--with-space {
    padding: 0 $gutterSmall;

    @include for-size(tablet-portrait-up) {
      padding: 0 $gutterMedium;
    }

    @include for-size(tablet-landscape-up) {
      padding: 0 $gutterLarge;
    }
  }

  &--relative {
    position: relative;
  }
}
