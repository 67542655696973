@import "../../theme/variables";

.wrap {
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 0;
  border-radius: $radiusLarge;
  overflow: hidden;
  color: #fff;
  background: rgb(255 135 28 / 100%);
  background: linear-gradient(180deg, #fec173 0%, #ff782e 100%);

  &.wrap-black {
    color: #fff;
    background: rgb(20 20 20 / 100%);
    background: linear-gradient(180deg, #444 0%, #000 100%);
  }
}

.background-logo {
  position: absolute;
  z-index: -1;

  path {
    opacity: 0.2;
    background: linear-gradient(180deg, #fed69f 0%, #ff9747 100%);
  }
}

.wrap-black .background-logo path {
  opacity: 0.2;
  background: linear-gradient(180deg, #000 0%, #666 100%);
}

.logo {
  flex-shrink: 0;
  margin-bottom: $gutterMedium;
}

.price {
  margin-bottom: $gutterSmall;
  font-weight: bold;
  font-size: 25px;
}

.label {
  text-transform: uppercase;
  font-weight: $light;
}

.corner-text {
  position: absolute;
  top: 10px;
  right: 15px;
  border-radius: 8px;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  background: rgb(255 255 255 / 20%);
}
