@import "../../../theme/variables";

.content {
  display: grid;
  gap: 32px;
  font-weight: $light;
}

.pricing-note {
  color: black;
  font-weight: $normal;

  .highlighted {
    font-weight: $bold;

    > strong {
      text-transform: uppercase;
      background: linear-gradient(94deg, #ff871c 0%, #ffc542 120%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}
