@import "../../theme//variables.scss";

.shopping-cart {
  display: flex;
  align-items: center;
  text-decoration: none;

  &:hover {
    .cart-icon {
      fill: $sFOrange;
      transition: fill 0.2s ease-in-out;
    }
  }
}

// stylelint-disable-next-line
.cart-icon {
  fill: $darkBlue;
  transition: fill 0.2s ease-in-out;
}

.counter {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  color: #fff;
  background-color: $sFOrange;
}
