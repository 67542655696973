@import "../../theme/variables.scss";

.form {
  &--PRIMARY {
    margin-bottom: $gutterMedium;
    padding-bottom: $gutterMedium;
  }

  &--SECONDARY {
    margin-bottom: $gutterMedium;
    border: none;
  }
}

.checkBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @include for-size(tablet-portrait-up) {
    align-items: flex-start;
  }

  &--PRIMARY {
    @include for-size(desktop-up) {
      height: auto !important;
    }
  }

  &--SECONDARY {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto !important;
    min-height: 81px;

    @include for-size(tablet-landscape-up) {
      grid-column-start: 1;
      grid-column-end: 3;
      justify-self: center;
    }
  }
}

.label {
  &--PRIMARY {
    font-size: 16px;
    font-weight: $medium;
    color: $lightBlue2;

    a {
      text-decoration: none;
      color: $sFOrange;
    }
  }

  &--SECONDARY {
    font-size: 16px;
    font-weight: bold;
    color: $lightBlue2;

    a {
      text-decoration: none;
      color: $blue;
    }
  }
}

div.tooltip-content {
  padding: 12px 36px 12px 16px;
  font-size: 13px;
}

.button {
  &--PRIMARY {
    align-self: center;
    width: 100% !important;

    &:hover {
      background: $blue;
    }
  }

  &--SECONDARY {
    @include for-size(tablet-landscape-up) {
      grid-column-start: 1;
      grid-column-end: 3;
      justify-self: center;
    }
  }
}
