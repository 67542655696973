@import "../../theme/variables";

.layout {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include for-size(phone-only) {
    &--mobile {
      flex-direction: column;
    }

    &.stretch--mobile {
      width: 100%;
    }
  }

  @include for-size(tablet-portrait-up) {
    &--tablet-portrait-up {
      flex-direction: column;
    }

    .stretch--portrait-up {
      width: 100%;
    }
  }

  @include for-size(tablet-landscape-up) {
    &--tablet-landscape-up {
      flex-direction: column;
    }

    .stretch--landscape-up {
      width: 100%;
    }
  }

  @include for-size(desktop-up) {
    &--desktop-up {
      flex-direction: column;
    }

    .stretch--desktop-up {
      width: 100%;
    }
  }

  @include for-size(tablet-portrait-max) {
    &--tablet-portrait-max {
      flex-direction: column;
    }

    .stretch--portrait-max {
      width: 100%;
    }
  }

  @include for-size(tablet-landscape-max) {
    &--tablet-landscape-max {
      flex-direction: column;
    }

    .stretch--landscape-max {
      width: 100%;
    }
  }
}

.stretch {
  width: 100%;
}

.gutter {
  &--small {
    margin-bottom: $gutterSmall;
  }

  &--medium {
    margin-bottom: $gutterMedium;
  }

  &--semi-large {
    margin-bottom: $gutterSemiLarge;
  }

  &--large {
    margin-bottom: $gutterLarge;
  }

  &--extra-large {
    margin-bottom: $gutterExtraLarge;
  }
}

.space {
  padding-right: $gutterSmall;
  padding-left: $gutterSmall;

  @include for-size(tablet-portrait-up) {
    padding-right: $gutterMedium;
    padding-left: $gutterMedium;
  }
}

.wrap {
  flex-direction: column;

  @include for-size(phone-only) {
    &--mobile {
      flex-direction: column;
    }

    .stretch--mobile {
      width: 100%;
    }
  }

  @include for-size(tablet-portrait-up) {
    &--tablet-portrait-up {
      flex-direction: column;
    }

    .stretch--portrait-up {
      width: 100%;
    }
  }

  @include for-size(tablet-landscape-up) {
    &--tablet-landscape-up {
      flex-direction: column;
    }

    .stretch--landscape-up {
      width: 100%;
    }
  }

  @include for-size(desktop-up) {
    &--desktop-up {
      flex-direction: column;
    }

    .stretch--desktop-up {
      width: 100%;
    }
  }

  @include for-size(tablet-portrait-max) {
    &--tablet-portrait-max {
      flex-direction: column;
    }

    .stretch--portrait-max {
      width: 100%;
    }
  }

  @include for-size(tablet-landscape-max) {
    &--tablet-landscape-max {
      flex-direction: column;
    }

    .stretch--landscape-max {
      width: 100%;
    }
  }

  .gutter {
    &--small {
      margin-bottom: $gutterSmall;
    }

    &--medium {
      margin-bottom: $gutterMedium;
    }

    &--semi-large {
      margin-bottom: $gutterSemiLarge;
    }

    &--large {
      margin-bottom: $gutterLarge;
    }

    &--extra-large {
      margin-bottom: $gutterExtraLarge;
    }
  }
}
