@import "../../theme/variables";

$tabBoxShadowHeight: 30px;
$tabScrollbarHeight: 10px;
$giftIconSpace: 40px;

.wrap {
  display: flex;
  align-items: center;
  margin: 0 auto;
  padding-top: $giftIconSpace;
  padding-bottom: $tabBoxShadowHeight;
  max-width: 100%;
  overflow-x: auto;

  /* width */
  &::-webkit-scrollbar {
    height: $tabScrollbarHeight;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    border-radius: calc(#{$tabScrollbarHeight} / 2);
    background: $offWhite4;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    border-radius: calc(#{$tabScrollbarHeight} / 2);
    background: $offWhite;
  }
}

.tooltip-wrap {
  & + & {
    margin-left: 8px;
  }
}

.gift-icon {
  position: absolute;
  bottom: calc(100% + 8px);
  opacity: 0.5;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  box-shadow: 0 20px $tabBoxShadowHeight -10px rgb(0 0 0 / 10%);
  border-radius: $radiusLarge;
  padding: $gutterMedium 15px;
  width: 60px;
  background: linear-gradient(#f6f6f6 0%, #fff 100%);
  cursor: pointer;

  &:hover {
    box-shadow: 0 20px $tabBoxShadowHeight -10px rgb(0 0 0 / 20%);
  }

  &--free-month {
    border: 1px solid $green2;
  }

  &--discounted-month {
    border: 1px solid $yellow;
  }

  &--current-month {
    border: 1px solid $sFOrange;
  }
}

.subscription-icon {
  margin-bottom: 6px;
}

.month {
  margin-bottom: 15px;
  border-bottom: 2px solid rgba(#e2e2ea, 0.3);
  padding-bottom: 15px;
  text-transform: uppercase;
  font-weight: $semiBold;
  font-size: 14px;
  text-align: center;

  &--past {
    color: $darkBlue;
  }

  &--current {
    color: $sFOrange;
  }

  &--future {
    color: $lightBlue2;
  }
}

.year {
  display: block;
  font-size: 10px;
}

.subscription-count {
  font-size: 20px;
  font-weight: $extraBold;
  color: $darkBlue;

  &--past {
    color: $darkBlue;
  }

  &--current {
    color: $sFOrange;
  }

  &--future {
    color: $lightBlue2;
  }

  &--free {
    color: $green2;
  }

  &--discounted {
    color: $yellow;
  }
}

.tooltip {
  padding: $gutterSemiLarge;
  max-width: 320px;
  background: rgba($darkBlue, 0.9) !important;
}

.tooltip-arrow {
  opacity: 0.9;
}

.tooltip-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
}

.tooltip-icon {
  display: block;
  flex-shrink: 0;
  margin-bottom: 15px;
}

.tooltip-title {
  text-align: center;
  font-style: italic;
  font-size: 14px;
  color: #fff;
}

.tooltip-text {
  line-height: 17px;
  font-size: 12px;
  font-weight: $light;
}

.subscribers-label {
  align-self: flex-start;
  font-size: 12px;
}

.subscribers {
  align-self: flex-start;
  padding-left: $gutterSmall;
  font-size: 12px;
}
