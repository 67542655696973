@import "../../theme/variables";

.panel {
  display: flex;
  position: relative;
  border-radius: $radiusMedium;
  background: #fff;

  @include for-size(phone-only) {
    flex-direction: column;
  }

  @include for-size(tablet-portrait-up) {
    flex-direction: row;
  }

  &--secondary {
    flex-direction: column;
    align-items: center;
    margin-top: $panelImageOverflow;
    padding-top: calc(200px - #{$panelImageOverflow});

    @include for-size(tablet-portrait-up) {
      flex-direction: column; // overwrite .panel
    }
  }

  &--tertiary {
    border-radius: 17px;
    background: $darkBlue;
  }

  &--small {
    width: 660px;
    max-width: 100%;
  }

  &--medium {
    width: 1080px;
    max-width: 100%;
  }

  &--large {
    width: 1440px;
    max-width: 100%;
  }

  &--inline {
    display: inline-flex;
  }
}

.image-wrap {
  display: none;

  @include for-size(tablet-portrait-up) {
    display: flex;
    flex-basis: 50%;
    align-items: center;
    justify-content: center;
    margin: $gutterMedium;
  }

  @include for-size(tablet-landscape-up) {
    flex-basis: 540px;
    margin: $gutterLarge;
  }

  &--secondary {
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    margin: 0;
    width: 200px;
    height: 200px;
    transform: translate(-50%, -$panelImageOverflow);
  }
}

.image {
  width: 100%;
  height: auto;
  max-width: 540px;
  max-height: 540px;
}

.panel-content {
  margin: $gutterMedium;
  color: $darkGrey;

  &--full-width {
    width: 100%;
  }

  &--secondary {
    width: calc(100% - #{$gutterSmall} * 2);

    @include for-size(tablet-portrait-up) {
      width: calc(100% - #{$gutterMedium} * 2);
    }

    @include for-size(tablet-landscape-up) {
      width: calc(100% - #{$gutterLarge} * 2);
    }
  }

  &--with-image {
    margin: $gutterMedium $gutterSmall;

    @include for-size(tablet-portrait-up) {
      display: flex;
      flex-direction: column;
      flex-basis: 50%;
      justify-content: center;
      margin: $gutterLarge $gutterMedium;
    }
  }
}

.label-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.label {
  display: flex;
  justify-content: center;
  align-self: center;
  align-items: center;
  margin-bottom: $gutterMedium;
  outline: 1px solid;
  border-radius: 4px;
  padding: 3px $gutterSemiLarge;
  min-height: 20px;
  font-size: 12px;
  font-weight: $semiBold;
  text-align: center;
  color: #fff;
  background: linear-gradient(#f5b851 0, #f6be4e 100%);
}

.title {
  font-size: 28px;

  @include for-size(phone-only) {
    font-style: italic;
  }
}

.title-caption {
  margin-bottom: $gutterMedium;
  line-height: 24px;
}
