@import "../../theme/variables.scss";

.wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  border-radius: $radiusMedium $radiusMedium 5px 5px;
  padding: $gutterLarge $gutterSmall $gutterSmall;
  width: 100%;
  max-width: 500px;
  color: #fff;
  background-color: $darkBlue;

  @include for-size(tablet-portrait-up) {
    border-radius: 25px;
    padding: $gutterLarge;
  }
}

.is-popular {
  position: absolute;
  top: -15px;
  border-radius: 5px;
  padding: $gutterSmall;
}

.title {
  display: flex;
  justify-content: center;
  margin-bottom: $gutterMedium;
  width: 100%;
}

.price {
  margin-bottom: $gutterSmall;
  font-size: 20px;
  line-height: 1;
  font-weight: 300;
  text-align: center;

  strong {
    display: block;
    font-size: 40px;
    color: $yellow;
  }
}

.price-additional {
  margin-bottom: 15px;
  font-size: 12px;
  font-weight: $light;
  color: $lightBlue2;
  line-height: normal;
}

.cart-button {
  margin-top: auto;
}

.button {
  width: 100%;
  max-width: 100%;

  @include for-size(tablet-portrait-up) {
    width: 280px;
  }
}
