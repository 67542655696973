@import "../../theme/variables";

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: $gutterMedium 0;

  @include for-size(tablet-portrait-up) {
    margin-right: $gutterSmall;
  }
}

.section-product {
  margin-right: auto;
  margin-bottom: $gutterMedium;
}

.icon-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: $gutterMedium;
  border-radius: 11px;
  width: 50px;
  height: 50px;
  background: $offWhite3;

  @include for-size(desktop-up) {
    border-radius: 20px;
    width: 140px;
    height: 175px;
  }

  img {
    width: 25px;
    height: auto;

    @include for-size(desktop-up) {
      flex-shrink: 0;
      width: auto;
      height: 75px;
    }
  }
}

.price {
  display: flex;
  align-items: center;
  margin-top: 5px;
  font-size: 12px;
  font-weight: $light;

  @include for-size(tablet-portrait-up) {
    display: none;
  }
}

.price-selector {
  display: inline-block;
  appearance: none;
  margin-left: $gutterMedium;
  border: none;
  padding-right: 18px;
  color: $yellow;
  background-color: transparent; // android fix
  background-image: url("data:image/svg+xml;utf8,<svg fill='orange' opacity='0.7' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: calc(100% + 3px);
  background-position-y: -4px;

  &:focus {
    outline: none;
  }
}

.price-disclaimer {
  margin-top: 5px;
  font-size: 12px;
  font-weight: 300;
  font-style: italic;
  opacity: 0.7;

  @include for-size(tablet-portrait-up) {
    display: none;
  }
}

.price-warning {
  display: inline-block;
  margin: $gutterSmall 0;
  border-radius: $radiusSmall;
  padding: $gutterSmall;
  font-size: 14px;
  color: #fff;
  background-color: $lightRed;

  @include for-size(tablet-portrait-up) {
    display: none;
  }
}

.period {
  display: none;

  @include for-size(tablet-portrait-up) {
    display: block;
    margin-top: $gutterSmall;
    font-size: 12px;
    font-weight: $light;
    color: $lightGrey2;
  }
}

.trashbin {
  margin-left: $gutterSmall;

  @include for-size(tablet-portrait-up) {
    display: none;
  }
}

.suggestion-wrap {
  position: relative;
  border-radius: $radiusSmall;
  padding: $gutterSmall calc((2 * 15px) + 12px) $gutterSmall 15px;
  color: #fff;
  background: $blue;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    border-radius: $radiusSmall 0 0 $radiusSmall;
    width: 4px;
    height: 100%;
    background: rgba(#fff, 0.5);
  }

  &:after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    width: 0;
    height: 0;
    transform: translateX(-50%);
    border-style: solid;
    border-width: 0 10px 6px;
    border-color: transparent transparent $blue transparent;
  }
}

.suggestion-label {
  margin-bottom: 5px;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: bold;
  color: #bbd5ff;
}

.suggestion {
  font-size: 12px;
}

.close {
  position: absolute;
  top: 0;
  right: $gutterSmall;
  height: 100%;
}
