@import "../../theme/variables";

.form {
  &--double-column {
    grid-template-columns: 7fr 7fr;
    grid-gap: 0 15px;
    margin-top: 10px;
    border-bottom: 1px solid $offWhite;
    width: 100%;

    @include for-size(phone-only) {
      grid-template-columns: 1fr;
    }

    @include for-size(desktop-up) {
      display: grid;
    }

    & > div {
      height: 81px;
    }
  }

  &--stretch {
    width: 100%;
  }
}
