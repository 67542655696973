@import "../../../../../theme/variables";

.label {
  font-weight: $medium;
}

.label-section {
  color: $lightBlue2;

  &--active {
    color: rgb(255 255 255 / 50%);
  }
}

.button {
  margin-top: $gutterLarge;
  min-height: 60px;
}
