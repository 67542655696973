@import "../../../../theme/variables";

button.access-product-link,
a.access-product-link {
  min-width: 160px;

  @include for-size(phone-only) {
    margin-bottom: $gutterMedium;
    border-radius: 0 !important;
    width: 100%;
    min-height: 40px;
    text-transform: uppercase;
    font-size: 12px !important;
    font-style: italic;
  }
}

.link-label {
  font-weight: bold;

  @include for-size(tablet-portrait-up) {
    font-size: 14px;
  }
}
