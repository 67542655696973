@import "../../theme/variables";

.title {
  margin-top: $gutterMedium;
  font-style: italic;
  font-size: 30px;
  font-weight: $normal;
}

.message {
  margin-bottom: $gutterLarge;
  max-width: 500px;
  line-height: 30px;
  text-align: center;
  color: $lightBlue2;
}
