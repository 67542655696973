@import "../../../theme//variables.scss";

.affiliate-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative; // to contain loader
  padding: $gutterSmall;
  min-height: 100px; // to make loader visible

  @include for-size(tablet-portrait-up) {
    padding: $gutterMedium;
  }
}

.form {
  max-width: 100%;
}

.business {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.affiliate-description {
  margin-bottom: $gutterMedium;
  max-width: 100%;
  color: $lightGrey;
  line-height: 40px;

  @include for-size(phone-only) {
    font-size: 14px;
    line-height: 30px;
  }
}

.description {
  margin-bottom: $gutterLarge;
  width: 625px;
  max-width: 100%;
}

.business-fee-option {
  width: 625px;
  max-width: 100%;
}

.option-section-title {
  @include for-size(tablet-portrait-up) {
    position: relative;
    padding-right: 120px;
  }
}

.arrow-wrap {
  display: none;

  @include for-size(tablet-portrait-up) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    padding-left: 25px;
  }
}

.arrow-text {
  position: absolute;
  top: 15px;
  left: 0;
  font-family: Signpainter, sans-serif;
  text-transform: uppercase;
  color: $yellow;
  font-size: 20px;
  font-weight: $medium;
  font-display: swap;
}

.username-wrap {
  width: 645px;
  max-width: 100%;
}

.income-centers-consent-wrap {
  display: grid;
  gap: $gutterMedium;
}

.income-center-wrap {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: $gutterMedium;
  margin-left: calc(#{$checkBoxHeightSecondary} + #{$checkBoxGapSecondary});
}

.income-center-label {
  font-weight: bold;
}

.title {
  &--disabled {
    opacity: 0.3;
  }
}

.name-field {
  &--disabled {
    opacity: 0.3;
  }
}

.checkbox-label,
.checkbox-link {
  font-weight: bold;
  color: $darkBlue2;

  &--disabled {
    color: $primaryText;
    opacity: 0.3;
  }
}

.loading-message {
  margin-bottom: $gutterMedium;
  color: $lightBlue2;
  font-style: italic;
  font-size: 14px;
  font-weight: $medium;
  text-align: center;
}

.icon {
  opacity: 0.6;
}

.consent-wrap {
  max-width: 500px;
}

.consent + .consent {
  margin-top: $gutterMedium;
}
