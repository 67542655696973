@import "../../../../theme/variables";

.wrap {
  display: flex;
  align-items: center;
  border-radius: $radiusLarge;
  padding: $gutterSemiLarge 0 0;
  max-width: 1100px;
  min-height: 260px;

  @include for-size(tablet-landscape-up) {
    padding: $gutterMedium 0;
  }
}

.content {
  margin: auto;
  border-radius: $radiusLarge;
  padding: 0 $gutterMedium $gutterLarge;
  width: 100%;
  background: #000 url("../assets/gift-bg.jpg") no-repeat center center/cover;

  @include for-size(tablet-landscape-up) {
    padding: $gutterMedium $gutterLarge $gutterMedium 0;
    width: auto;
  }
}

.gift-card {
  margin-bottom: $gutterExtraLarge;
  transform: rotate(-15deg);

  @include for-size(tablet-landscape-up) {
    margin-right: $gutterExtraLarge;
    margin-bottom: 0;
  }
}

.caption {
  margin-bottom: $gutterSemiLarge;
  font-weight: $light;
  font-size: 20px;
  color: #fff;

  strong {
    display: block;
    font-size: 40px;
  }

  span {
    color: $sFOrange;
  }
}

.button-wrap {
  display: grid;
  gap: $gutterSemiLarge;

  @include for-size(tablet-portrait-up) {
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    justify-content: start;
  }

  @include for-size(desktop-up) {
    grid-template-columns: repeat(2, 180px);
  }
}
