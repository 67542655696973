@import "../../theme/variables.scss";

.container {
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: $gutterExtraLarge;
  height: 180px;
  background: linear-gradient(180deg, $bannerGradientLight 0%, $bannerGradientDark 100%);
}

.body {
  display: flex;
  justify-content: center;
  padding: 0 $appSidePadding;

  @include for-size(phone-only) {
    flex-direction: column;
  }

  @include for-size(tablet-portrait-up) {
    flex-direction: column;
  }

  @include for-size(tablet-landscape-up) {
    flex-direction: row;
  }
}

.body-info {
  margin-right: $gutterLarge;
  margin-bottom: $gutterExtraLarge;

  p {
    margin-bottom: $gutterMedium;
    font-size: 14px;
    color: $darkGrey;
  }

  a {
    color: $sFOrange;
  }
}

.body-header {
  margin-bottom: $gutterExtraLarge;
}
