@import "../../theme/variables";

.wrap {
  width: 600px;
  max-width: 100%;
}

.save-new-card {
  display: flex;
  justify-content: center;
}

.form {
  margin-bottom: 0;
}

.method-wrap {
  & + & {
    margin-top: $gutterMedium;
  }
}

.content {
  border-radius: $radiusLarge;
  padding: $gutterMedium;
  color: $lightBlue2;
  background: $offWhite2;

  @include for-size(tablet-landscape-up) {
    padding: $gutterMedium $gutterLarge;
  }
}

.label-wrap {
  @include for-size(tablet-landscape-max) {
    align-items: flex-start;
  }
}

.caption {
  color: $yellow;
  white-space: nowrap;

  @include for-size(tablet-landscape-up) {
    margin-left: 5px;
    border-left: 1px solid $lightBlue2;
    padding-left: 5px;
  }
}

.toggle-button {
  width: 30px;
  height: 30px;
}

.arrow-icon {
  transform: rotate(-90deg);

  &--rotated {
    transform: rotate(90deg);
  }
}

.link {
  margin-left: $gutterSmall;
  color: $lightBlue2;
}

// gutter
.gutter {
  &--SMALL {
    margin-bottom: $gutterSmall;
  }

  &--MEDIUM {
    margin-bottom: $gutterMedium;
  }

  &--SEMI_LARGE {
    margin-bottom: $gutterSemiLarge;
  }

  &--LARGE {
    margin-bottom: $gutterLarge;
  }

  &--EXTRA_LARGE {
    margin-bottom: $gutterExtraLarge;
  }
}
