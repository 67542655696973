@import "../../../theme/variables.scss";

$pdfIconWidth: 36px;

.wrap {
  position: relative; // to container loader
  margin: 0 auto; // center content
  width: $siteMaxWidth;
  max-width: 100%;
  min-height: 100px; // make loader always visible
}

.term-grid {
  $gap: $gutterMedium;

  display: grid;
  grid-gap: $gap;
  justify-content: center; // center content in mobile view
  margin: auto auto $gutterMedium;
  border-radius: $radiusMedium;
  padding: $gutterMedium $gutterSemiLarge $gutterMedium $gutterLarge;
  width: 100%;
  font-size: 14px;
  font-weight: $normal;
  color: $darkGrey;
  text-align: center;
  background-color: $offWhite2;

  // On mobile, show in columns. On bigger screens, show in rows
  @include for-size(tablet-portrait-up) {
    grid-template-columns: 1fr 90px;
    align-items: center;
    padding: $gap;
    text-align: left;
  }

  @include for-size(tablet-landscape-up) {
    grid-gap: 40px;
    padding: $gutterMedium $gutterSemiLarge $gutterMedium $gutterLarge;
  }

  // allows text to turn ellipsis if it wont fit
  > * {
    min-width: 0;
  }
}

.icon {
  @include for-size(phone-only) {
    justify-self: center;
  }
}

.title {
  display: block;
  font-size: 16px;
  font-weight: $semiBold;
  color: $darkGrey;
}

strong.title {
  font-weight: $semiBold;
}

.title-secondary {
  overflow: hidden;
  font-size: 12px;
  font-weight: $semiBold;
  color: $lightBlue2;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.pdf {
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;
    max-width: $pdfIconWidth;
  }
}
