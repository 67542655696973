@import "../../../../../theme/variables";

.wrap {
  position: relative;
}

.details {
  padding: $gutterMedium;
  font-size: 12px;
  background: url(./assets/border.svg) bottom left / 12px 1px repeat-x;

  &:first-child {
    background: url(./assets/border.svg) top left / 12px 1px repeat-x,
      url(./assets/border.svg) bottom left / 12px 1px repeat-x;
  }
}

.title {
  font-weight: bold;
}

.caption {
  color: $lightBlue2;
}

.value {
  margin-left: $gutterMedium;
  font-weight: $semiBold;
}

.disabled {
  text-decoration: line-through;
  color: $lightRed;
}

.yellow {
  color: $yellow;
}

.green {
  color: $green2;
}

.red {
  color: $lightRed;
  text-decoration: line-through;
}

.total {
  margin: $gutterMedium 0 $gutterMedium auto;
  font-weight: $semiBold;
  text-transform: uppercase;
  font-size: 18px;
  text-align: right;
}

.price {
  margin-left: $gutterLarge;
}

.terms-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: $gutterLarge;

  a {
    margin-left: 5px;
    font-weight: bold;
    text-decoration: none;
    color: $blue;
  }
}

.checkbox {
  margin-bottom: $gutterMedium;
}

.terms-description {
  font-style: italic;
  font-weight: $light;
  font-size: 14px;
}

.button {
  display: block;
  margin-top: $gutterLarge;
  min-width: 280px;
  min-height: 60px;
}
