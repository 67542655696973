@import "../../../theme/variables";

.title {
  font-size: 30px;
  line-height: normal;
  font-weight: bold;
}

.section {
  flex-shrink: 0;
  width: 500px;
}

.image {
  width: auto;
  max-width: unset;
}
