@import "../../theme//variables.scss";

.wrap {
  display: grid;
  grid-gap: 20px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  padding: $gutterMedium $gutterSmall $gutterLarge $gutterSmall;
  min-height: 80px;
  line-height: 17px;
  font-weight: 400;
  color: $darkGrey;
  background-color: $offWhite2;

  @include for-size(tablet-portrait-up) {
    justify-content: unset;
    padding: $gutterMedium 5px $gutterLarge 5px;
  }

  @include for-size(tablet-landscape-up) {
    grid-template-columns: 1fr 2fr 1fr;
    grid-gap: 40px;
    justify-content: center;
    padding: 5px $gutterMedium 5px $gutterLarge;
  }

  & + & {
    margin-top: $gutterMedium;
  }
}

// only remove empty placeholder div on small screen to help with alignment
.placeholder {
  &:empty {
    display: none;

    @include for-size(tablet-landscape-up) {
      display: block;
    }
  }
}

.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  @include for-size(tablet-portrait-up) {
    flex-direction: row;
    text-align: left;
  }

  @include for-size(tablet-landscape-up) {
    justify-content: flex-start;
  }
}

.payment-data {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @include for-size(tablet-portrait-up) {
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
  }

  @include for-size(tablet-landscape-up) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: space-around;
  }
}

.data-fraction {
  & + & {
    margin-left: $gutterSmall;

    @include for-size(phone-only) {
      margin-top: $gutterMedium;
      margin-left: 0;
    }
  }
}

.status-icon {
  flex-shrink: 0;
  margin: 0 auto $gutterSmall auto;

  @include for-size(tablet-portrait-up) {
    margin-right: $gutterMedium;
    margin-bottom: 0;
    margin-left: unset;
  }

  @include for-size(tablet-landscape-up) {
    margin-right: $gutterLarge;
  }
}

.name {
  font-weight: $light;
}

.status {
  font-size: 12px;
  font-weight: $semiBold;

  &--active {
    color: $green2;
  }

  &--inactive {
    color: $red;
  }
}
