@import "../../../theme/variables";

.title {
  line-height: normal;
  font-weight: bold;
}

.content {
  display: grid;
  gap: 32px;
  font-weight: $light;
}

.cart-wrapper {
  display: grid;
  gap: 8px;
  justify-items: start;
}

.cart-button {
  margin-bottom: 0;
  background: linear-gradient(75deg, #000, #292f48 25%, #ff871c 40%, #ffc542 100%) no-repeat 0% 0% / 400%;
}

.cart-button-label {
  color: white;

  > svg {
    fill: white;
  }
}

.discount-label {
  display: flex;
  align-items: center;
  gap: $gutterSmall;
  color: white;
  font-size: 12px;
  font-weight: $semiBold;
  letter-spacing: 3px;
  padding: 4px 16px;
  text-transform: uppercase;
  border-radius: 4px;
  background: linear-gradient(92deg, #ff871c 0.02%, #ffc542 99.65%);

  > svg {
    opacity: 0.5;
    fill: white;
  }
}

.bottom-separator {
  bottom: -80px; // Matches view gutter
}
