@import "../../../theme/variables";

.wrap {
  position: relative; // to container loader
  width: $siteMaxWidth;
  max-width: 100%;
  min-height: 100px; // make loader always visible

  &--is-user-from-us {
    width: auto;

    h3 {
      text-align: center;

      @include for-size(tablet-landscape-up) {
        text-align: left;
      }
    }
  }
}

.section-wrap {
  width: 1040px; // static value to force section to take up as much space as it needs
  max-width: 100%;
}

.title {
  font-size: 30px;
  font-weight: $light;
  color: $sFOrange;
  line-height: normal;
  text-align: center;

  @include for-size(tablet-landscape-up) {
    text-align: left;
  }
}

.carousel {
  max-width: 100%;

  :global(.slider-wrapper) {
    width: 940px;
    max-width: 100%;
  }
}

.section-layout {
  display: flex;
  justify-content: space-between;
  align-items: flex-start; // Aligns the top edges
  width: 100%;
  max-width: 1040px;
}

.left-side {
  display: flex;
  gap: 20px; // Adjust spacing
  flex: 1; // Takes up remaining space
  flex-direction: column;
}

.right-side {
  flex: 0 0 300px; // Fixed width for TopUpSection
  margin-top: -65px;
}
