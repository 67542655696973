@import "../../theme//variables.scss";

$customTableCellHeight: 58px;

.titlebar {
  @include for-size(tablet-portrait-max) {
    margin-bottom: $gutterLarge;
    font-weight: bold;
  }

  * {
    @include for-size(tablet-portrait-max) {
      font-size: 12px;
    }
  }
}

.title {
  font-size: 30px !important;

  @include for-size(tablet-landscape-up) {
    font-size: 50px !important;
  }
}

.form {
  margin-bottom: 70px;

  @include for-size(tablet-landscape-up) {
    margin-bottom: 100px;
  }
}

.container {
  padding-right: $gutterMedium;
  padding-left: $gutterMedium;
}

.image {
  margin: $gutterMedium 0;
  width: auto;
  max-height: 175px !important;
}

.period {
  @include for-size(tablet-portrait-up) {
    padding-right: $gutterSmall;
    height: $customTableCellHeight;
  }
}

.price-disclaimer {
  margin-top: 5px;
  font-size: 12px;
  font-weight: 300;
  font-style: italic;
  opacity: 0.7;
}

.price-warning {
  display: block;
  margin: $gutterSmall 0;
  border-radius: $radiusSmall;
  padding: $gutterSmall;
  font-size: 14px;
  color: #fff;
  background-color: $lightRed;
}

.quantity-wrap {
  display: inline-flex;
  justify-content: flex-start;
  margin: 5px $gutterMedium 5px 0;
}

.discount-total {
  font-weight: bold;
  color: $sFOrange;
}

.totals {
  margin-bottom: 50px;

  @include for-size(tablet-landscape-up) {
    margin-bottom: 100px;
  }
}

.table {
  border-collapse: collapse;
  width: 100%;
  text-align: left;
  color: $darkBlue;

  th {
    padding-bottom: 15px;
  }
}

.has-border {
  border-bottom: 1px solid $offWhite3;
}

.product-wrap {
  padding-right: $gutterMedium;
}

.loader-wrapper {
  width: $loaderWidthMedium;
}

.remove-product-icon {
  width: 20px;
  height: 24px;
  fill: $lightGrey2;
  cursor: pointer;
}

.total-row {
  height: 120px !important;
}

.total-price {
  width: 100px; // prevent price row from 'jumping' when quantity is increased
}

.checkout-button {
  $iconSize: 28px;
  $iconOffsetX: $gutterSemiLarge;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 5px calc(#{$iconSize} + #{$iconOffsetX} + #{$gutterMedium});

  .icon {
    position: absolute;
    top: 50%;
    right: $gutterSemiLarge;
    width: $iconSize;
    height: $iconSize;
    transform: translateY(-50%);
    opacity: 0.5;
    fill: $white;
  }
}

.button-text-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: normal;
}

.button-total-sum {
  font-size: 15px;
  font-weight: $light;
  opacity: 0.5;
}

.empty-cart {
  text-align: center;
}

.payment-option-wrap {
  width: $mobileWidth;
  max-width: 100%;

  @include for-size(tablet-landscape-up) {
    width: calc(#{$mobileWidth} + (2 * #{$gutterLarge}));
  }
}

.error-message {
  margin-bottom: $gutterLarge;
  color: $lightRed;
  font-size: 14px;
  font-weight: $light;
  font-style: italic;
}

.gift-card-button-wrap {
  display: flex;
  justify-content: flex-end;
  margin-bottom: $gutterLarge;
}

.gift-card-button {
  display: inline-block;
  box-shadow: 0 10px 20px 0 rgb(0 0 0 / 10%);
  color: $darkBlue;
}

.gift-card-icon {
  margin-right: 5px;
}

.terms-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: $gutterLarge;

  a {
    margin-left: 5px;
    font-weight: bold;
    text-decoration: none;
    color: $blue;
  }
}

.checkbox {
  margin-bottom: $gutterMedium;
}

.terms-description {
  font-style: italic;
  font-weight: $light;
  font-size: 14px;
}

.discount {
  align-items: flex-start;
  padding: $gutterMedium 0;

  @include for-size(desktop-up) {
    flex-direction: row;
  }
}

.discount-coupon {
  margin-bottom: $gutterMedium;

  @include for-size(desktop-up) {
    margin-right: $gutterMedium;
    margin-bottom: 0;
  }
}

.disclaimer-wrap {
  text-align: center;

  a {
    text-decoration: none;
    color: $blue;
    font-weight: bold;
  }
}

.disclaimer-message {
  margin-bottom: $gutterMedium;
  font-style: italic;
  color: $lightRed;
}
