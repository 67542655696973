@import "../../theme/variables.scss";

.field-wrap {
  position: relative;
  margin: 0;
  text-align: left;
  font-size: 0; // remove white space

  & > span {
    height: 15px;
    font-size: 14px;
    font-style: italic;
    line-height: 15px;
  }

  &--gutter-small {
    margin-bottom: $gutterSmall;
  }

  &--gutter-medium {
    margin-bottom: $gutterMedium;
  }

  &--gutter-semi-large {
    margin-bottom: $gutterSemiLarge;
  }

  &--gutter-large {
    margin-bottom: $gutterLarge;
  }

  &--datepicker {
    z-index: 10;

    .label.error {
      transform: translateY(calc((#{-$fieldHeight} / 2) + 50%));
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  textarea:-webkit-autofill:active,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus,
  select:-webkit-autofill:active {
    box-shadow: 0 0 0 30px white inset !important;
    -webkit-text-fill-color: $lightGrey2;
  }
}

.passwordVisibility {
  position: absolute;
  top: calc(#{$fieldHeight} / 2);
  right: 20px;
  transform: translateY(-50%);
}

.label {
  display: inline-block;
  position: absolute;
  z-index: 3;
  left: $gutterMedium;
  height: $fieldLabelHeight;
  transform: translateY(calc((#{$fieldHeight} / 2) - 50%));
  font-size: 16px;
  line-height: $fieldLabelHeight;
  font-weight: 300;
  transition: ease-in-out 0.2s;
  pointer-events: none;

  &.focus {
    color: $blue;
  }

  &:not(.focus) {
    color: $lightGrey2;
  }

  &:empty {
    display: none;

    + .select,
    + .datepicker-wrapper {
      margin-top: 0 !important; // removes -20px margin if no label is present
    }
  }

  &--hidden {
    display: none;
    visibility: none;
  }

  &--textarea {
    position: absolute;
    top: calc((#{$fieldHeight} / 2) - (#{$fieldLabelHeight} / 2));
    transform: none;

    &.raisedLabel {
      top: 0;
      transform: translateY(calc(#{-$fieldHeight} + #{$fieldLabelHeight} + 100%));
    }
  }
}

.input-wrap {
  display: flex;
  position: relative;
  border: 2px solid $offWhite3;
  border-radius: $radiusMedium;
  height: calc(#{$fieldHeight} + 4px); // 4px for border height

  &--has-focus {
    border: 2px solid $blue;
  }

  &--textarea {
    height: unset;
  }

  &.error {
    border: 2px solid $lightRed;
  }
}

.datepicker,
.input,
.textarea,
.custom,
.select {
  display: block;
  border: none;
  border-radius: $radiusMedium;
  padding-left: 20px;
  width: 100%;
  max-height: 100%;
  font-size: 16px;
  line-height: 40px;
  font-weight: 600;
  color: $lightGrey2;
  background-color: white;
  transition: ease-in-out 0.2s;
  filter: none;

  &:focus {
    outline: none;
    border: none;
  }
}

.datepicker,
.input,
.select {
  height: $fieldHeight;
}

.textarea {
  padding: $gutterMedium;
  line-height: normal;
}

.custom {
  line-height: normal;
}

.field {
  &--has-label {
    margin-bottom: -$fieldLabelHeight;
  }
}

.select {
  padding-right: 30px;
  color: $lightBlue2;
  text-transform: capitalize;
  appearance: none;
  appearance: none;
  appearance: none;
  background-image: url("./children/icon/IconDropdownArrow.svg");
  background-position: right 17.5px center;
  background-size: 8px 10px;
  background-repeat: no-repeat;

  &--transparent {
    border: none;
    border-radius: 0;
    background: transparent;
  }

  &--uppercase-options {
    text-transform: uppercase !important;
  }
}

.input {
  height: $fieldHeight;

  &--transparent {
    border: none;
    border-bottom: 1px solid rgb(255 255 255 / 10%);
    border-radius: 0;
    background: transparent;
  }

  &--hide-focus {
    &:focus {
      border: none;
      border-bottom: 1px solid rgb(255 255 255 / 10%);
    }
  }

  &--align-right {
    text-align: right;
  }

  &--type-password {
    padding-right: 50px;
  }
}

.input--has-no-placeholder::placeholder {
  color: rgb(0 0 0 / 0%);

  & ~ .label {
    transition: ease-in-out 0.2s;
  }
}

.raisedLabel,
.input:focus ~ .label,
.input:not(:placeholder-shown) ~ .label {
  padding: 0 5px;
  transform: translateY(calc((#{-$fieldHeight} / 2) + 3px + 50%)); // 3px is from border height's 4px
  font-size: 12px; // this rule causes the moving input bug. solution below
  line-height: $fieldLabelHeight;
  font-weight: 600;
  background: #fff;
  transition: ease-in-out 0.2s;
  pointer-events: none;
}

// copies the code above because FF is unable to read -webkit-autofill
.input:-webkit-autofill ~ label {
  padding: 0 5px;
  transform: translateY(calc(#{-$fieldHeight} + 50%));
  font-size: 12px; // this rule causes the moving input bug. solution below
  line-height: $fieldLabelHeight;
  font-weight: 600;
  background: #fff;
  transition: ease-in-out 0.2s;
  pointer-events: none;
}

.datepicker-wrapper {
  display: block;
  position: relative;
  margin-top: calc(#{-$fieldLabelHeight} / 2);
  border-radius: $radiusMedium;
  padding-top: calc(#{$fieldLabelHeight} / 2);
  width: 100%;

  :global(.react-datepicker__input-container) {
    height: 100%;
  }

  input {
    margin-top: 0;
  }

  &--has-error {
    margin-top: 0;
    margin-bottom: -$fieldLabelHeight;
    padding-top: 0;
    height: $fieldHeight;
  }
}

.datepicker {
  position: relative;
  border-radius: $radiusMedium;
}

.datepicker-selected-day {
  border-radius: $radiusExtraSmall;
  background: $blue;
}

.datepicker-popper {
  * {
    font-family: Montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif;
  }

  :global(.react-datepicker__header) {
    background: $darkBlue;

    * {
      color: #fff;
    }
  }

  :global(.react-datepicker__triangle) {
    border-bottom-color: $darkBlue !important;
  }

  :global(.react-datepicker__day--keyboard-selected) {
    border-radius: $radiusExtraSmall;
    background: $blue;
  }

  :global(.react-datepicker__navigation--previous) {
    border-right-color: #fff;
  }

  :global(.react-datepicker__navigation--next) {
    border-left-color: #fff;
  }

  :global(.react-datepicker__year-dropdown) {
    background: $darkBlue;
  }
}

.expand {
  flex-grow: 1;
}

.error-message {
  color: $lightRed;
  font-size: 14px;
  font-weight: 300;
  font-style: italic;
}

.textarea,
.textarea ~ .label {
  margin-bottom: 0;
}

.checkboxLabel {
  display: flex;
  align-items: center;
  position: relative;
  transform: none;
  font-size: 15px;
  color: $primaryText;
  cursor: pointer;
  pointer-events: all;
  user-select: none;

  &--tertiary {
    border-radius: $radiusMedium;
    padding: 15px $gutterMedium;
    background: $offWhite2;
  }

  &--tertiary-checked {
    background: $blue;
  }
}

.checkbox {
  display: none;
  position: absolute;
  height: 0;
  opacity: 0;
  user-select: none;

  &:checked {
    + span {
      border: 2px solid $sFOrange;
      background: $sFOrange;

      &:after {
        content: "";
        position: relative;
        top: 3px;
        left: 8px;
        width: 6px;
        height: 12px;
        transform: rotate(45deg);
        transition: all 0.25s;
        border-style: solid;
        border-color: white;
        border-width: 0 2px 2px 0;
      }
    }
  }

  &:not(:checked) + span {
    transition: all 0.25s;
  }

  &:disabled {
    cursor: auto;
  }

  &:disabled + span {
    background-color: #fff;
    cursor: auto;
  }

  &--secondary {
    &:checked {
      + .checkmark {
        border: 2px solid $blue;
        background: $blue;

        &:after {
          top: 1px;
          left: 4px;
          width: 5px;
          height: 8px;
          border-width: 0 2px 2px 0;
        }
      }
    }
  }

  &--tertiary {
    + .checkmark {
      border: 1px solid $offWhite;
      border-radius: 50%;
      width: 31px;
      height: 31px;
    }

    &:checked {
      + .checkmark {
        border: 1px solid #fff;
        background: #fff;

        &:after {
          top: 5px;
          left: 10px;
          width: 10px;
          height: 15px;
          border-color: $yellow;
          border-width: 0 4px 4px 0;
        }
      }
    }
  }
}

.checkbox-label--disabled {
  cursor: auto;
}

.checkmark {
  display: inline-flex;
  top: 0;
  left: 0;
  margin-right: 14px;
  border: 2px solid $offWhite3;
  border-radius: 4px;
  width: $checkBoxHeight;
  height: $checkBoxHeight;
  min-width: $checkBoxHeight;
  min-height: $checkBoxHeight;
  background-color: #fff;
  transition: all 0.25s;

  &--secondary {
    margin-right: $checkBoxGapSecondary;
    width: $checkBoxHeightSecondary;
    height: $checkBoxHeightSecondary;
    min-width: $checkBoxHeightSecondary;
    min-height: $checkBoxHeightSecondary;
  }
}
