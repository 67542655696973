@import "../../theme/variables";

.field-wrap {
  margin-top: $gutterMedium;
  margin-right: auto;
  margin-left: auto;
  max-width: 680px;

  @include for-size(tablet-landscape-up) {
    padding: 0;
  }
}

.error {
  margin: $gutterMedium 0;
  border-radius: $radiusSmall;
  padding: $gutterMedium;
  color: $red;
  background-color: rgb(206 17 38 / 5%);
}

.field {
  flex: 1;
  width: 100%;

  & + & {
    @include for-size(tablet-landscape-up) {
      margin-left: $gutterMedium;
    }
  }
}

.custom-field {
  > div {
    padding-left: 0;
  }
}

.submit-button {
  margin-top: $gutterMedium;
}
