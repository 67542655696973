@import "../../theme/variables";

.title-caption {
  margin-bottom: $gutterLarge;
}

.guideline-text {
  margin-bottom: $gutterMedium;
}

.title-caption,
.guideline-text {
  text-align: center;
  line-height: 30px;
  color: $lightGrey;
}

.icon {
  opacity: 0.6;
}

.discalaimer {
  font-style: italic;
}

.error-message-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: $gutterLarge;
  border: 2px solid $lightRed;
  border-radius: $radiusLarge;
  padding: $gutterLarge;
  background: $offWhite2;

  @include for-size(tablet-portrait-up) {
    flex-direction: row;
  }
}

.error-message {
  margin-top: $gutterMedium;
  line-height: 30px;
  text-align: center;

  @include for-size(tablet-portrait-up) {
    margin-top: 0;
    margin-left: $gutterLarge;
    text-align: left;
  }
}

.email {
  display: inline-block;
  word-break: break-all;
  color: $lightRed;
}

.support-email {
  word-break: break-all;
  color: $blue;
}

.user-email {
  display: inline-block;
  word-break: break-all;
  color: $primaryText;
}

.retry {
  margin: $gutterLarge 0;
  color: $blue;
  text-decoration: underline;
}
