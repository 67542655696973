@import "../../../theme/variables";

.label {
  margin-bottom: $gutterMedium;
}

.checkbox-label {
  span {
    color: $lightBlue2;
    font-weight: $light;
  }

  &--active {
    span {
      color: rgba(#fff, 0.5);
    }
  }
}

.summary-wrap {
  display: flex;
  justify-content: flex-end;
  margin-bottom: $gutterSmall;
  font-weight: $semiBold;
  font-size: 18px;

  @include for-size(tablet-portrait-up) {
    padding: 0 $gutterExtraLarge $gutterSmall 0;
  }

  &--is-center {
    justify-content: center;
    padding: 0;
  }
}

.summary-title {
  margin-right: $gutterSemiLarge;
  text-transform: uppercase;
}

.summary-value {
  margin-bottom: 50px;
  text-align: right;

  &-primary {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    color: $yellow;
  }

  &-secondary {
    font-size: 14px;
    color: $lightBlue2;
    font-weight: normal;
  }
}

.summary-icon {
  margin-left: 5px;
}

.terms {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: $gutterSemiLarge;

  a {
    font-weight: bold;
    color: $blue;
    text-decoration: none;
  }
}

.error {
  margin-bottom: $gutterMedium;
  padding: 0 $gutterMedium;
  text-align: center;
  font-size: 14px;
  font-style: italic;
  font-weight: $light;
  color: $lightRed;
}

.button-wrap {
  display: flex;
  flex-direction: column;

  @include for-size(tablet-portrait-up) {
    flex-direction: row;
    justify-content: center;
  }
}

.button {
  & + & {
    margin-top: $gutterMedium;

    @include for-size(tablet-portrait-up) {
      margin-top: 0;
      margin-left: $gutterMedium;
    }
  }
}
