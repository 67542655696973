.notFound {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 400px;
  color: #7789ad;
  font-size: 20px;
  font-weight: 400;
}
