@import "../../theme/variables.scss";

.container {
  margin-bottom: $gutterMedium;
  border-bottom: 1px solid $offWhite;
  padding-bottom: $gutterMedium;

  &:last-of-type {
    border-bottom: none;
  }
}

.header {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  margin-bottom: $gutterMedium;
}

.header-section {
  display: flex;
  gap: 0 $gutterSmall;
  flex-wrap: wrap;
}

.show-details {
  text-decoration: underline;
}
