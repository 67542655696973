@import "../../theme/variables";

.title-caption {
  align-self: center;
  width: 600px;
  max-width: 100%;
}

.modal-field-wrap {
  margin-right: auto;
  margin-left: auto;
  max-width: 100%;
}

// remove arrows from input with type="number"

/* Chrome, Safari, Edge, Opera */
.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

/* Firefox */
.input[type="number"] {
  appearance: textfield;
}
