@import "../../theme/variables";

.wrap {
  border-radius: $radiusMedium;
  padding: $gutterMedium $gutterMedium $gutterMedium $gutterSemiLarge;
  color: #fff;
  background: $darkBlue2;

  @include for-size(phone-only) {
    padding: $gutterMedium;
  }
}

.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 0;
  margin-bottom: $gutterMedium;
  text-align: center;

  @include for-size(tablet-landscape-up) {
    flex-direction: row;
    margin-right: $gutterMedium;
    margin-bottom: 0;
    text-align: left;
  }
}

.icon-wrap {
  display: inline-flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  margin-bottom: $gutterMedium;
  box-shadow: 0 0 0 4px rgb(255 255 255 / 30%);
  border-radius: 50%;
  width: 52px;
  height: 52px;
  background: #fff;

  @include for-size(tablet-landscape-up) {
    margin-right: $gutterMedium;
    margin-bottom: 0;
  }
}

.icon {
  display: block;
  width: 60% !important;
}

.name {
  strong {
    display: inline;
    margin-left: 5px;
    font-weight: bold;
    white-space: nowrap;
    color: $yellow;
  }
}

.disclaimer {
  display: block;
  margin-top: 5px;
  font-size: 12px;
  font-weight: 300;
  font-style: italic;
  opacity: 0.7;
}

.button-wrap {
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-left: $gutterSmall;

  @include for-size(tablet-landscape-up) {
    flex-direction: row;
  }
}

.price-wrap {
  display: flex;
  justify-content: space-around;
  margin-bottom: $gutterMedium;
  width: 100%;
  font-size: 12px;

  @include for-size(tablet-landscape-up) {
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 0;
    width: auto;
    font-size: inherit;
  }
}

.price {
  font-weight: $light;
  opacity: 0.7;

  @include for-size(tablet-landscape-up) {
    margin-right: $gutterSemiLarge;
  }

  &--old {
    text-decoration: line-through;
    color: $yellow;
  }

  & + & {
    @include for-size(tablet-landscape-up) {
      margin-top: 5px;
    }
  }
}
