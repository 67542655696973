@import "../../theme/variables";

.password-tip-wrap {
  & + & {
    margin-top: 5px;
  }
}

.password-tip-addon {
  display: inline-block;
  margin-right: $gutterSmall;
  width: 30px;
  text-align: right;
  font-style: italic;
  font-weight: bold;
  color: $sFOrange;

  &--checked {
    color: $green2;
  }
}

.password-tooltip--checked {
  color: $lightBlue2;
  text-decoration: line-through;
}
