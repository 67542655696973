@import "../../theme/variables";

.wrap {
  border-radius: 14px;
  padding: $gutterSmall;
  min-height: 80px;
  font-weight: bold;
  color: #fff;
  background: $yellow;
}

.content {
  border: 2px dashed #fff;
  border-radius: 14px;
  padding: 5px $gutterMedium;
  height: 100%;
}

.icon-wrap {
  margin-right: 15px;
}

.label {
  display: flex;
  align-items: center;
  margin-right: $gutterMedium;

  @include for-size(tablet-portrait-up) {
    margin-right: $gutterLarge;
  }
}

.has-gap {
  &--MOBILE {
    @include for-size(tablet-portrait-up) {
      margin-right: $gutterLarge;
    }
  }

  &--TABLET_PORTRAIT_UP {
    @include for-size(tablet-portrait-up) {
      margin-right: 0;
    }
  }

  &--TABLET_LANDSCAPE_UP {
    @include for-size(tablet-landscape-up) {
      margin-right: 0;
    }
  }

  &--DESKTOP_UP {
    @include for-size(desktop-up) {
      margin-right: 0;
    }
  }

  &--TABLET_PORTRAIT_MAX {
    @include for-size(tablet-portrait-max) {
      margin-right: 0;
    }
  }

  &--TABLET_LANDSCAPE_MAX {
    @include for-size(tablet-landscape-max) {
      margin-right: 0;
    }
  }

  &--true {
    margin-right: 0;
  }
}

.gutter {
  &--medium {
    margin-bottom: $gutterMedium;
  }

  &--large {
    margin-bottom: $gutterLarge;
  }
}
