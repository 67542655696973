// --- heading usage cheatsheet ---
// h1 home page main hero-section title,
// h2 sub-page title
// h3 page section title, modal title
// h4 panel title
// h5 form titles: contact page, my profile, affiliate view
// h6 other lesser titles

@import "./main";
@import "./variables";

body,
* {
  font-family: Montserrat, sans-serif;
}

select {
  font-family: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}
