@import "../../../theme/variables";

.image-wrap {
  position: relative;
  width: auto;
  max-width: 100%;
}

.image {
  width: 100%;
  height: auto;
  max-width: 500px;
  max-height: 675px;
}

.label {
  position: absolute;
  top: 0;
  padding: $gutterSmall $gutterSemiLarge;
  letter-spacing: 1px;
  font-weight: $semiBold;
  color: #fff;

  &--new {
    right: 0;
    background: $blue;
  }

  &--sale {
    left: 0;
    background: $sFOrange;
  }
}
