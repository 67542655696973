@import "../../theme/variables";

.wrap {
  $wrapHeight: 34px;
  $wrapPadding: 3px;

  display: flex;
  justify-content: space-between;
  align-items: center; // iOS fix
  border-radius: calc(#{$wrapHeight} / 2);
  padding: $wrapPadding;
  height: $wrapHeight;
  max-width: 100%;
  background: #fff;

  .period {
    $periodHeight: calc(#{$wrapHeight} - (2 * #{$wrapPadding}));

    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    border-radius: calc(#{$periodHeight} / 2);
    height: $periodHeight;
    line-height: 10px;
    color: $lightBlue2;

    &--selected {
      color: #fff;
      background: $yellow;
    }
  }
}

.gutter {
  &--medium {
    margin-bottom: $gutterMedium;
  }

  &--semi-large {
    margin-bottom: $gutterSemiLarge;
  }
}
