@import "../../theme/variables";

$dotHeight: 50px;
$slideGapDesktop: $gutterSmall;
$slideGapMobile: 5px;
$navigationWidth: calc(8px + 2 * #{$slideGapDesktop});

.carousel {
  :global(.carousel-slider) {
    padding: 0 $navigationWidth;
  }

  :global(.slider-wrapper) {
    margin-bottom: $dotHeight;
    width: 100%;
  }

  :global(.slide) {
    padding: 0 $slideGapMobile;
    background: transparent;

    @include for-size(tablet-portrait-up) {
      padding: 0 $slideGapDesktop;
    }
  }

  :global(.carousel-status) {
    display: none;
  }

  :global(.control-dots) {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    width: calc(100% - 2 * #{$navigationWidth});
    height: $dotHeight;

    :global(.dot) {
      margin: 0 4px;
      box-shadow: none;
    }
  }

  :global(.dot:not(.selected)) {
    border: 2px solid $lightGrey;
  }

  :global(.dot.selected) {
    background: $sFOrange;
  }

  &--single-item {
    :global(.carousel-slider) {
      padding: 0;
    }

    :global(.slide) {
      padding: 0 $gutterSmall;
      background: transparent;

      @include for-size(tablet-portrait-up) {
        padding: 0;
      }
    }
  }
}

.navigate {
  position: absolute;
  z-index: 1;
  top: 0;
  width: $navigationWidth;
  height: calc(100% - #{$dotHeight});

  &--prev {
    left: 0;
  }

  &--next {
    right: 0;
    transform: rotate(180deg);
  }
}
