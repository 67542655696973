@import "../../../../theme/variables";

$giftCardOffset: 80px;
$giftCardBottomShadow: 40px;
$bgCircleLarge: 1292px;
$titleBarHeightLarge: 340px;
$titleBarHeightSmall: 250px;
$topUpPanelOffset: 0;

.src-content-container {
  display: flex;
  align-items: flex-start; // Aligns both elements at the top
  justify-content: space-between; // Keeps space between them
  gap: 20px; // Adds spacing between text and panel

  @media (max-width: 768px) {
    flex-direction: column; // Stack items vertically on small screens
    align-items: center; // Center align content
  }
}

.text-container {
  flex: 1; // Makes text take up remaining space
  max-width: 70%; // Adjust width if needed

  p {
    margin: 20px 10px 20px 0;
    line-height: 1.5;
  }

  @media (max-width: 768px) {
    max-width: 100%;
  }
}

.scr-wrap {
  margin: $topUpPanelOffset $gutterSmall 0;
  border-radius: $radiusExtraLarge;
  width: 100%;
  max-width: 100%;
  background: $offWhite4;

  @include for-size(tablet-portrait-up) {
    margin-right: $gutterMedium;
    margin-left: $gutterMedium;
    width: 280px;
  }

  @include for-size(desktop-up) {
    margin-right: 0;
    margin-left: $gutterLarge;
    width: 280px;
  }

  @media (max-width: 768px) {
    width: 280px;
  }
}

.scr-wrap-content {
  align-items: center;
  margin: 0 $gutterMedium $gutterLarge;
  text-align: center;
}

.scr-panel {
  align-items: center;
  margin-top: 10px;
  margin-bottom: $gutterSemiLarge;
  width: 100%;
  max-width: 100%;
  text-align: center;
}

.src-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @include for-size(tablet-portrait-up) {
    margin: 50px $gutterExtraLarge $gutterLarge;
  }
}

.scr-logo {
  width: 100%;
  margin-bottom: $gutterLarge;
}

.scr-button {
  min-width: 180px;
}

.scr-label {
  margin-bottom: $gutterSemiLarge;
  font-size: 20px;
  font-weight: $light;
  text-transform: uppercase;
  color: $sFOrange;
}

.scr-credit {
  font-size: 30px;
  font-weight: bold;
  color: #fff;
}

.wrap {
  position: relative; // to container loader
  margin: 0 auto; // center content
  width: $siteMaxWidth;
  max-width: 100%;
  min-height: 100px; // make loader always visible
}

.title {
  display: block;
  font-size: 16px;
  font-weight: $semiBold;
  color: $darkGrey;
}

.main-title {
  line-height: normal;
  font-weight: bold;
}

.main-content {
  display: grid;
  gap: 32px;
  font-weight: $light;
}

.term-grid {
  $gap: $gutterMedium;

  display: grid;
  grid-gap: $gap;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: center; // center content in mobile view
  margin: auto auto $gutterMedium;
  border-radius: $radiusMedium;
  padding: $gutterMedium $gutterSemiLarge $gutterMedium $gutterLarge;
  width: 100%;
  font-size: 14px;
  font-weight: $normal;
  color: $darkGrey;
  text-align: center;
  background-color: $offWhite2;

  // On mobile, show in columns. On bigger screens, show in rows
  @include for-size(tablet-portrait-up) {
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    padding: $gap;
    text-align: left;
  }

  @include for-size(tablet-landscape-up) {
    grid-gap: 40px;
    padding: $gutterMedium $gutterSemiLarge $gutterMedium $gutterLarge;
  }

  // allows text to turn ellipsis if it wont fit
  > * {
    min-width: 0;
  }
}

.dag-wrap {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr 2fr auto;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  padding: $gutterMedium $gutterSmall $gutterLarge $gutterSmall;
  min-height: 80px;
  line-height: 17px;
  font-weight: 400;
  color: $darkGrey;
  background-color: $offWhite2;

  @include for-size(tablet-portrait-up) {
    grid-template-columns: 1fr 2fr auto;
    justify-content: space-between;
    // padding: $gutterMedium 5px $gutterLarge 5px;
  }

  @include for-size(tablet-landscape-up) {
    grid-template-columns: 1fr 2fr auto;
    grid-gap: 40px;
    justify-content: center;
    // padding: 5px $gutterMedium 5px $gutterLarge;
  }

  @include for-size(phone-only) {
    justify-content: left;
  }

  & + & {
    margin-top: $gutterMedium;
  }
}

.access-product-link {
  justify-self: end;
  margin-left: auto;
  width: 160px;

  @include for-size(phone-only) {
    justify-self: start;
  }

  @include for-size(tablet-landscape-up) {
    justify-self: end;
  }
}

.action-right {
  text-align: end;
}

.data-fraction {
  & + & {
    margin-left: $gutterSmall;

    @include for-size(phone-only) {
      margin-top: $gutterMedium;
      margin-left: 0;
    }
  }
}

.dag-data {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @include for-size(tablet-portrait-up) {
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
  }

  @include for-size(tablet-landscape-up) {
    display: grid;
    grid-template-columns: 1fr 1fr auto;
    justify-content: space-around;
  }
}

.name {
  font-weight: $light;
}

.dag-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  @include for-size(tablet-portrait-up) {
    flex-direction: row;
    text-align: left;
  }

  @include for-size(tablet-landscape-up) {
    justify-content: flex-start;
  }
}

ul {
  margin: 10px 0;
  padding: 0;
  width: 100%;
}

.grid-container {
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  justify-content: center;
}

.separator {
  display: flex;
  align-items: center;
  justify-content: center;
}

.separator-line {
  width: 2px;
  height: 40px;
  background-color: #e2e2ea;
}

.modal-title {
  font-size: 30px;
  color: $darkGrey;
}

.modal-caption {
  line-height: 30px;
  color: $lightBlue2;
  text-align: center;
}
