@import "../../theme/variables";

.modal-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgb(0 0 0 / 20%);

  &--secondary {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    outline: 1px solid;
    width: 100vw;
    height: 100vh;
    overflow-y: auto;
    overflow-y: scroll;
    background: transparent;
  }
}

.modal {
  @include for-size(tablet-portrait-up) {
    position: relative;
  }

  &:focus {
    outline: none;
  }

  iframe {
    max-width: 100%;
  }

  &--primary {
    position: absolute;
    padding: $gutterMedium;
    overflow: auto;
    background: #fff;

    @include for-size(phone-only) {
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    @include for-size(tablet-portrait-up) {
      margin-top: $gutterExtraLarge;
      border-radius: $radiusMedium;
      max-width: calc(100% - (#{$gutterMedium} * 2));
      max-height: calc(100vh - 2 * #{$gutterLarge});
    }

    @include for-size(big-desktop-up) {
      max-width: $siteMaxWidth;
    }
  }

  &--secondary {
    margin: calc(#{$panelImageOverflow} + #{$gutterMedium}) $gutterSmall $gutterMedium;
    max-width: calc(100% - 2 * #{$gutterSmall});

    @include for-size(phone-only) {
      -webkit-padding-after: 100px; // react-modal doesn't activate scroll event in ios and the bottom navbar doesn't disappear, hence it needs a padding to show content.
    }

    @include for-size(tablet-portrait-up) {
      position: relative;
      margin: calc(#{$panelImageOverflow} + #{$gutterMedium}) $gutterMedium $gutterMedium;
      max-width: calc(100% - 2 * #{$gutterMedium});
    }
  }

  &--tertiary {
    position: absolute;
    padding: 0 $gutterSmall;
    max-width: 100%;
    overflow: auto;
    background: #000;

    @include for-size(tablet-portrait-max) {
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    @include for-size(tablet-landscape-up) {
      margin-top: $gutterExtraLarge;
      border-radius: $radiusMedium;
      padding: 0 $gutterMedium;
      max-height: calc(100vh - 2 * #{$gutterLarge});
    }
  }

  &--loading {
    width: $loaderWidthMedium;
    height: $loaderWidthMedium;
    background: transparent;
  }
}

.return {
  display: block;
  position: absolute;
  z-index: 20;
  left: $gutterMedium;
  height: 25px;
  font-size: 20px;

  &--secondary {
    top: $gutterMedium;

    @include for-size(tablet-portrait-up) {
      top: calc(#{$panelImageOverflow} + #{$gutterMedium});
    }
  }
}

.close {
  display: block;
  position: absolute;
  z-index: 20;
  right: $gutterMedium;
  height: 25px;
  font-size: 20px;

  &--secondary {
    top: $gutterMedium;

    @include for-size(tablet-portrait-up) {
      top: calc(#{$panelImageOverflow} + #{$gutterMedium});
    }
  }

  &--tertiary {
    position: absolute;
    top: 15px;
    right: 15px;
    height: 20px;
    line-height: 20px;
    color: #fff;
  }

  &--loading {
    display: none;
  }
}

.close-icon {
  &--secondary {
    width: 25px;
    height: 25px;
    fill: #fff;

    @include for-size(tablet-portrait-up) {
      fill: $lightGrey2;
    }
  }
}

.modal-overlay {
  position: fixed;
  z-index: 900;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgb(0 0 0 / 50%);

  @include for-size(tablet-portrait-up) {
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  &--loading {
    background: transparent;
  }

  &--secondary {
    background: linear-gradient(rgba($bannerGradientDark, 0.95) 0%, rgba($bannerGradientLight, 0.95) 100%);
  }
}

.body--has-modal {
  overflow: hidden;
}

.center {
  &--ALL {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &--MOBILE {
    @include for-size(phone-only) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &--TABLET_PORTRAIT_UP {
    @include for-size(tablet-portrait-up) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &--TABLET_LANDSCAPE_UP {
    @include for-size(tablet-landscape-up) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &--DESKTOP_UP {
    @include for-size(desktop-up) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &--TABLET_PORTRAIT_MAX {
    @include for-size(tablet-portrait-max) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &--TABLET_LANDSCAPE_MAX {
    @include for-size(tablet-landscape-max) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
