@import "../../../theme/variables";

.wrap {
  position: relative;
  z-index: 0;
  margin-bottom: 70px;
  padding-top: 50px;

  @include for-size(tablet-landscape-up) {
    margin-bottom: 100px;
    padding-top: 0;
  }
}

.separator {
  @include for-size(tablet-landscape-up) {
    display: none;
  }
}

.heading {
  margin-bottom: $gutterSemiLarge;
  font-size: 20px;
  line-height: normal;
  font-weight: bold;

  @include for-size(tablet-landscape-up) {
    margin-bottom: 90px;
    font-size: 30px;
  }
}

.dotted-line {
  display: none;

  @include for-size(desktop-up) {
    display: block;
    position: absolute;
    z-index: -1;
    top: -50px;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.panel-heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 20px;
  line-height: normal;
  text-transform: uppercase;
}

.text {
  text-align: justify;
  line-height: 24px;
  color: $lightBlue2;
}

.content-wrap {
  $iconSize: 105px;

  position: relative;
  padding-top: calc(#{$iconSize} / 2 + #{$gutterMedium});
  background: #fff;

  .icon-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 100%;
    left: 50%;
    border: 5px solid #fff;
    border-radius: 50%;
    width: $iconSize;
    height: $iconSize;
    transform: translate(-50%, 50%);
    background-color: $yellow;
  }
}
