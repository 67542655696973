@import "../../theme/variables.scss";

.button {
  width: auto;
  height: auto;
  font-size: 18px;
  font-weight: $medium;

  &--center {
    margin-right: auto;
    margin-left: auto;
  }

  &--disabled {
    pointer-events: none;
    color: transparent;

    svg {
      display: none;
    }
  }

  &--height-medium {
    min-height: $fieldHeight;
  }

  &--height-large {
    min-height: 55px;
  }

  &--has-loader {
    position: relative;
  }
}

.button-label {
  position: relative;

  .icon-cart {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);

    &--YELLOW_ROUND,
    &--DARK_BLUE_2,
    &--BLUE {
      opacity: 0.3;
      fill: #fff;
    }

    &--ORANGE {
      opacity: 0.3;
      fill: #fff;
    }

    &--YELLOW {
      fill: $darkBlue;
    }

    &--DARK_BLUE {
      fill: $sFOrange;
    }
  }
}

.stretch {
  &--MOBILE {
    @include for-size(phone-only) {
      width: 100%;
    }
  }

  &--TABLET_PORTRAIT_UP {
    @include for-size(tablet-portrait-up) {
      width: 100%;
    }
  }

  &--TABLET_LANDSCAPE_UP {
    @include for-size(tablet-landscape-up) {
      width: 100%;
    }
  }

  &--DESKTOP_UP {
    @include for-size(desktop-up) {
      width: 100%;
    }
  }

  &--TABLET_PORTRAIT_MAX {
    @include for-size(tablet-portrait-max) {
      width: 100%;
    }
  }

  &--TABLET_LANDSCAPE_MAX {
    @include for-size(tablet-landscape-max) {
      width: 100%;
    }
  }
}
