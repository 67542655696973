@import "../../theme//variables.scss";

.quantity {
  display: flex;
  border: 2px solid $offWhite3;
  border-radius: 5px;

  * {
    &:focus {
      outline: none;
    }
  }

  button {
    border: none;
    font-size: 20px;
    line-height: 24px;
    font-weight: bold;
    text-decoration: none;
    color: $colorDarkBlue;
    background-color: #fff;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }

  &--horizontal {
    flex-direction: row;
    height: $fieldHeight;

    button {
      padding: 0 15px;
    }
  }

  &--vertical {
    flex-direction: column;
    justify-content: space-between;
    width: 33px;
    height: 100%;

    button {
      margin: 7px 0;
      padding: 0;
    }
  }

  &--disabled {
    opacity: 0.4;

    & * {
      opacity: 0.4;
    }

    button {
      cursor: default;
    }
  }
}

.input {
  margin: 1px 0;
  border: none;
  min-width: 40px;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  color: $colorDarkBlue;

  @include for-size(desktop-up) {
    width: 20px;
  }

  &--vertical {
    width: 100%;
    min-width: unset;
  }
}
