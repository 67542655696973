@import "../../theme/variables";

.button {
  position: relative;
  max-width: 100%;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:disabled {
    background-color: $offWhite;
  }

  &--is-loading {
    position: relative;
    color: transparent !important;

    &:hover {
      background: $offWhite !important;
    }

    svg {
      opacity: 0 !important;
    }
  }

  &--text-button-loading {
    position: relative;
    color: transparent !important;
    background: transparent !important;

    &:hover {
      background: transparent !important;
    }
  }

  &--has-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

// height

.height {
  &--EXTRA_SMALL {
    padding: 5px calc(#{$buttonHeightExtraSmall} / 2);
    min-height: $buttonHeightExtraSmall;

    &.shape--ROUND {
      border-radius: calc(#{$buttonHeightExtraSmall} / 2);
    }
  }

  &--SMALL {
    padding: 5px calc(#{$buttonHeightSmall} / 2);
    min-height: $buttonHeightSmall;

    &.shape--ROUND {
      border-radius: calc(#{$buttonHeightSmall} / 2);
    }
  }

  &--MEDIUM {
    padding: 5px calc(#{$fieldHeight} / 2);
    min-height: $fieldHeight;

    &.shape--ROUND {
      border-radius: calc(#{$fieldHeight} / 2);
    }
  }

  &--LARGE {
    padding: 5px calc(#{$buttonHeightDesktop} / 2);
    min-height: $buttonHeightDesktop;

    &.shape--ROUND {
      border-radius: calc(#{$buttonHeightDesktop} / 2);
    }
  }

  &--AUTO {
    height: auto;

    &.shape--ROUND {
      border-radius: 9999px; // helps button stay pill-shaped no matter the height
    }
  }
}

// gutter

.gutter {
  &--SMALL {
    margin-bottom: $gutterSmall;
  }

  &--MEDIUM {
    margin-bottom: $gutterMedium;
  }

  &--SEMI_LARGE {
    margin-bottom: $gutterSemiLarge;
  }

  &--LARGE {
    margin-bottom: $gutterLarge;
  }

  &--EXTRA_LARGE {
    margin-bottom: $gutterExtraLarge;
  }
}

// shape

.shape {
  &--RECT {
    border-radius: 0;

    &.radius--SMALL {
      border-radius: $radiusSmall;
    }

    &.radius--MEDIUM {
      border-radius: $radiusMedium;
    }
  }
}

// color

.color {
  $borderType: 2px solid;

  &--BLUE {
    background: $blue;

    &.kind--HOLLOW {
      border: $borderType $blue;
      color: $blue;
    }

    &.kind--TEXT {
      color: $blue;
    }
  }

  &--LIGHT_BLUE {
    background: $lightBlue;

    &.kind--HOLLOW {
      border: $borderType $lightBlue;
      color: $lightBlue;
    }

    &.kind--TEXT {
      color: $lightBlue;
    }
  }

  &--LIGHT_BLUE_2 {
    background: $lightBlue2;

    &.kind--HOLLOW {
      border: $borderType $lightBlue2;
      color: $lightBlue2;
    }

    &.kind--TEXT {
      color: $lightBlue2;
    }
  }

  &--DARK_BLUE {
    background: $darkBlue;

    &.kind--HOLLOW {
      border: $borderType $darkBlue;
      color: $darkBlue;
    }

    &.kind--TEXT {
      color: $darkBlue;
    }
  }

  &--DARK_BLUE_2 {
    background: $darkBlue2;

    &.kind--HOLLOW {
      border: $borderType $darkBlue2;
      color: $darkBlue2;
    }

    &.kind--TEXT {
      color: $darkBlue2;
    }
  }

  &--GREY {
    background: $lightBlue2;

    &.kind--HOLLOW {
      border: $borderType $lightBlue2;
      color: $lightBlue2;
    }

    &.kind--TEXT {
      color: $lightBlue2;
    }
  }

  &--ORANGE {
    background: $sFOrange;

    &.kind--HOLLOW {
      border: $borderType $sFOrange;
      color: $sFOrange;
    }

    &.kind--TEXT {
      color: $sFOrange;
    }
  }

  &--YELLOW {
    background: $yellow;

    &.kind--HOLLOW {
      border: $borderType $yellow;
      color: $yellow;
    }

    &.kind--TEXT {
      color: $yellow;
    }
  }

  &--GREEN {
    background: $green2;

    &.kind--HOLLOW {
      border: $borderType $green2;
      color: $green2;
    }

    &.kind--TEXT {
      color: $green2;
    }
  }

  &--LIGHT_RED {
    background: $lightRed;

    &.kind--HOLLOW {
      border: $borderType $lightRed;
      color: $lightRed;
    }

    &.kind--TEXT {
      color: $lightRed;
    }
  }

  &--WHITE {
    background: #fff;

    &.kind--HOLLOW {
      border: $borderType #fff;
      color: #fff;
    }

    &.kind--TEXT {
      color: #fff;
    }
  }

  &--LIGHT_GREY {
    background: $lightGrey;

    &.kind--HOLLOW {
      border: $borderType $lightGrey;
      color: $lightGrey;
    }

    &.kind--TEXT {
      color: $lightGrey;
    }
  }
}

.text-color {
  &--BLUE {
    color: $blue;
  }

  &--LIGHT_BLUE {
    color: $lightBlue;
  }

  &--DARK_BLUE {
    color: $darkBlue;
  }

  &--GREY {
    color: $lightBlue2;
  }

  &--ORANGE {
    color: $sFOrange;
  }

  &--YELLOW {
    color: $yellow;
  }

  &--GREEN {
    color: $green2;
  }

  &--LIGHT_RED {
    color: $lightRed;
  }
}

// kind

.kind {
  &--SOLID {
    border: none;
    color: #fff;
  }

  &--TEXT {
    border: none;
    padding: unset;
    background: transparent;
  }

  &--HOLLOW {
    background: transparent;
  }
}

// center self

.center {
  &--ALIGN_SELF {
    align-self: center;
  }

  &--WITH_MARGIN {
    margin-right: auto;
    margin-left: auto;
  }

  &--BLOCK_AND_MARGIN {
    display: block;
    margin-right: auto;
    margin-left: auto;
  }
}

// font-weight

.weight {
  &--LIGHT {
    font-weight: $light;
  }

  &--NORMAL {
    font-weight: $normal;
  }

  &--MEDIUM {
    font-weight: $medium;
  }

  &--SEMI_BOLD {
    font-weight: $semiBold;
  }

  &--BOLD {
    font-weight: bold;
  }
}

// stretch
// !important is used to overwrite inline width value so both of them could work

.stretch {
  width: 100% !important;

  &--MOBILE {
    @include for-size(phone-only) {
      width: 100% !important;
    }
  }

  &--TABLET_PORTRAIT_UP {
    @include for-size(tablet-portrait-up) {
      width: 100% !important;
    }
  }

  &--TABLET_LANDSCAPE_UP {
    @include for-size(tablet-landscape-up) {
      width: 100% !important;
    }
  }

  &--DESKTOP_UP {
    @include for-size(desktop-up) {
      width: 100% !important;
    }
  }

  &--TABLET_PORTRAIT_MAX {
    @include for-size(tablet-portrait-max) {
      width: 100% !important;
    }
  }

  &--TABLET_LANDSCAPE_MAX {
    @include for-size(tablet-landscape-max) {
      width: 100% !important;
    }
  }
}

// button with icon

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 25px;

  svg {
    width: auto;
    height: 100%;
    fill: #fff;
  }
}
