@import "../../theme/variables";

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: relative;
  margin-bottom: $navBarGutter;
  padding: 0 $appSidePadding;
  height: 100px;

  &--mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-bottom: 30px;
    width: 100%;
    height: 119px;
    color: white;
  }

  a {
    text-decoration: none;
  }
}

.navbar-background {
  background: $sFOrange linear-gradient(180deg, lighten($sFOrange, 10%) 0%, $sFOrange 100%);

  @include for-size(tablet-landscape-up) {
    background: linear-gradient(0deg, lighten($sFOrange, 10%) 0%, $sFOrange 100%);
  }
}

.title {
  margin-bottom: 5px;
  font-size: 10px;
  opacity: 0.6;
}

.caption {
  font-size: 20px;
  font-style: italic;
  font-weight: 800;
}

.dropdown-wrap {
  display: grid;
  grid-template-columns: 1fr 1fr;

  > * {
    border-bottom: 1px solid $offWhite;
    padding: 20px 5px;
    text-decoration: none;

    &:nth-child(n + 1) {
      border-right: 1px solid $offWhite;
    }

    // remove border-bottom from 2 last children, but only if there's an even number of children, otherwise remove border-bottom just from the last child
    &:nth-last-child(1):nth-child(even),
    &:nth-last-child(1):nth-child(odd),
    &:nth-last-child(2):nth-child(odd) {
      border-bottom: none;
    }
  }
}

.menu-button {
  $buttonSize: 60px;

  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: calc(100% - #{$buttonSize} / 2);
  border-radius: 50%;
  width: $buttonSize;
  height: $buttonSize;
  background-color: #fff;
}

.icon-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-color: $sFOrange;
}

.icon {
  width: 18px;
  height: 18px;
}

.link {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 18px;
  font-weight: bold;
  text-align: center;

  @include for-size(tablet-portrait-max) {
    font-size: 10px;
    text-transform: uppercase;
    color: $primaryText;
  }

  @include for-size(tablet-landscape-up) {
    margin: 0 10px;
    padding: 0 5px;
    font-size: 12px;
    color: #fff;
  }

  // remove firefox dotted outline
  &:focus {
    outline: none;
  }

  &--disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &--active {
    color: $sFOrange;

    @include for-size(tablet-landscape-up) {
      align-self: flex-end;
      position: relative;
      box-shadow: 0 0 40px 0 rgb(229 229 229 / 70%);
      border-radius: 10px 10px 0 0;
      height: calc(100% + 10px);
      color: $primaryText;
      background: #fff;
    }

    .decoration {
      $angleSize: 15px;

      @include for-size(tablet-landscape-up) {
        position: absolute;
        bottom: 0;
        width: calc(100% + 2 * #{$angleSize});
        height: $angleSize;
        background: #fff;
      }

      &:before,
      &:after {
        @include for-size(tablet-landscape-up) {
          content: "";
          position: absolute;
          width: $angleSize;
          height: $angleSize;
        }
      }

      &:before {
        @include for-size(tablet-landscape-up) {
          left: 0;
          border-bottom-right-radius: 50%;
          background: linear-gradient(to right, #f6a65d 0%, #f2b076 100%);
        }
      }

      &:after {
        @include for-size(tablet-landscape-up) {
          right: 0;
          border-bottom-left-radius: 50%;
          background: linear-gradient(to left, #f6a65d 0%, #f2b076 100%);
        }
      }
    }

    // active link
    .link-icon {
      margin-top: 10px;
      height: 30px;

      svg {
        opacity: 1;
        fill: $sFOrange;
      }
    }
  }
}

.link-icon {
  margin-bottom: 7px;
  height: 30px;
  line-height: 0;

  @include for-size(tablet-landscape-up) {
    margin-bottom: 5px;
  }

  svg {
    position: relative;
    left: 50%;
    width: auto;
    height: 30px;
    transform: translateX(-50%);
    fill: $sFOrange;

    @include for-size(tablet-landscape-up) {
      opacity: 0.4;
      fill: #fff;
    }
  }
}
