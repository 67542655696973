@import "./src/theme/variables";

.menu-wrap {
  list-style: none;
  margin: 0 0 $gutterMedium $gutterLarge;
  padding: 0;
}

.menu-item {
  padding: 5px $gutterSmall;
}

.link {
  text-decoration: none;
  color: #fff !important; //global header nesting overrides this otherwise
  font-size: 15px;
  font-style: italic;
  font-weight: $light;
  line-height: 22px;

  &:hover {
    color: darken(#fff, 5) !important;
  }

  &--disabled {
    opacity: 0.6;
    pointer-events: none;
  }
}
