@import "../../theme/variables";

.gutter {
  &--SMALL {
    margin-bottom: $gutterSmall;
  }

  &--MEDIUM {
    margin-bottom: $gutterMedium;
  }

  &--SEMI_LARGE {
    margin-bottom: $gutterSemiLarge;
  }

  &--LARGE {
    margin-bottom: $gutterLarge;
  }
}

.label {
  display: flex;
  align-items: center;
  position: relative;
  transform: none;
  font-size: 15px;
  color: $primaryText;
  cursor: pointer;
  pointer-events: all;
  user-select: none;

  &--TERTIARY {
    border-radius: $radiusMedium;
    padding: 15px $gutterMedium;
    background: $offWhite2;
  }

  &--tertiary-checked {
    color: #fff;
    background: $blue;
  }

  &--disabled {
    cursor: auto;

    * {
      cursor: auto;
    }
  }

  &:not(.label--disabled) * {
    cursor: pointer;
  }
}

.checkbox {
  display: none;
  position: absolute;
  height: 0;
  opacity: 0;
  user-select: none;

  &:checked {
    + .checkmark {
      // shared checkmark styles
      &:after {
        content: "";
        position: relative;
        transform: rotate(45deg);
        transition: all 0.25s;
        border-style: solid;
      }
    }
  }

  &:disabled {
    + .checkmark {
      background-color: #fff;
    }
  }

  &--PRIMARY {
    &:checked {
      + .checkmark {
        border: 2px solid $sFOrange;
        background: $sFOrange;

        &:after {
          top: 3px;
          left: 8px;
          width: 6px;
          height: 12px;
          border-color: #fff;
          border-width: 0 2px 2px 0;
        }
      }
    }
  }

  &--SECONDARY {
    &:checked {
      + .checkmark {
        border: 2px solid $blue;
        background: $blue;

        &:after {
          top: 1px;
          left: 4px;
          width: 5px;
          height: 8px;
          border-color: #fff;
          border-width: 0 2px 2px 0;
        }
      }
    }
  }

  &--TERTIARY {
    + .checkmark {
      border: 1px solid $offWhite;
      border-radius: 50%;
      width: 31px;
      height: 31px;
    }

    &:checked {
      + .checkmark {
        border: 1px solid #fff;
        background: #fff;

        &:after {
          top: 5px;
          left: 10px;
          width: 10px;
          height: 15px;
          border-color: $yellow;
          border-width: 0 4px 4px 0;
        }
      }
    }
  }
}

.checkmark {
  display: inline-flex;
  flex-shrink: 0;
  top: 0;
  left: 0;
  margin-right: 14px;
  border: 2px solid $offWhite3;
  border-radius: 4px;
  width: $checkBoxHeight;
  height: $checkBoxHeight;
  min-width: $checkBoxHeight;
  min-height: $checkBoxHeight;
  background: #fff;
  transition: all 0.25s;

  &--SECONDARY {
    margin-right: $checkBoxGapSecondary;
    width: $checkBoxHeightSecondary;
    height: $checkBoxHeightSecondary;
    min-width: $checkBoxHeightSecondary;
    min-height: $checkBoxHeightSecondary;
  }
}

.error-message {
  color: $lightRed;
  font-size: 14px;
  font-weight: $light;
  font-style: italic;
}
