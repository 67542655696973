@import "../../theme/variables";

.titlebar {
  margin-bottom: 140px;
}

.title {
  font-size: 30px;
  font-style: italic;
  font-weight: normal;
  color: $darkBlue;
}

.notification {
  margin-bottom: $gutterExtraLarge;
  width: 520px;
  max-width: 100%;
  line-height: 30px;
  font-weight: $light;
  color: $lightBlue2;
}

.cart-link {
  min-width: 256px;
  max-width: 100%;
}

.support-message {
  font-weight: $light;
  font-size: 14px;
  color: $lightBlue2;
}

.support-link {
  margin-left: 5px;
  font-style: italic;
  font-weight: $semiBold;
  color: $yellow;
}
