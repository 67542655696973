@import "../../theme/variables";

.wrap {
  display: grid;
  gap: $gutterSmall;
  max-width: 100%;

  @include for-size(tablet-portrait-up) {
    gap: $gutterLarge;
  }
}

.panel {
  border-radius: 15px;
  padding: $gutterSemiLarge;
  text-align: center;
  background: $darkBlue;
}

.value {
  font-size: 40px;
  font-weight: $light;
  color: #fff;
}

.label {
  font-weight: bold;
  font-size: 12px;
  text-align: center;
  color: $sFOrange;
}
