@import "../../../../theme/variables";

.wrap {
  max-width: 680px;

  @include for-size(tablet-landscape-up) {
    padding: 0;
  }
}

.title {
  align-self: flex-start;
  font-size: 30px;
  font-weight: $light;
  color: $sFOrange;

  @include for-size(tablet-landscape-up) {
    text-align: left;
  }
}
