@import "../../theme/variables.scss";

.wrap {
  $dotSize: 10px;

  display: inline-flex;
  align-items: center;

  &:before {
    content: "";
    display: inline-block;
    flex-shrink: 0;
    margin-right: $gutterSmall;
    border-radius: 50%;
    width: $dotSize;
    height: $dotSize;
  }
}

/* colors  */
.GREEN:before {
  background-color: $green2;
}

.GRAY:before {
  background-color: $lightBlue2;
}

.RED:before {
  background-color: $red;
}

.LIGHT_GREY:before {
  background-color: $lightGrey2;
}

.YELLOW:before {
  background-color: $yellow;
}
