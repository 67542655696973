@import "../../theme/variables";

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  box-shadow: 0 1px 36px 0 rgb(119 137 173 / 10%);
  border: 10px solid #fff;
  border-radius: 50%;
  width: 140px;
  height: 140px;
  background: rgb(0 0 0 / 20%);
  backdrop-filter: blur(2px);

  &--center {
    margin: auto;
  }

  &:focus {
    outline: none;
    box-shadow: 0 1px 36px 0 rgb(119 137 173 / 100%) inset;
  }
}

.icon {
  margin-left: 5px;
  width: 35px;
  height: 26px;
  transform: rotate(-90deg);
  fill: $sFOrange;
}
