@import "../../theme/variables";

.wrap {
  text-align: center;

  svg {
    margin-bottom: $gutterSmall;
    width: 60px;
    height: 60px;
    fill: $yellow;
  }
}

.title {
  font-size: 24px;
  font-style: italic;
  line-height: normal;
}
