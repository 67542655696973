@import "../../theme/variables.scss";

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: auto;
  padding-bottom: $gutterSmall;
}

.logo {
  margin: $gutterSemiLarge auto;
}

.link-wrap {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @include for-size(tablet-landscape-up) {
    flex-direction: row-reverse;
    padding: 0 $appSidePadding;
  }
}

.terms-wrap {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  margin-bottom: auto;

  @include for-size(tablet-landscape-up) {
    flex-direction: row;
    margin-right: auto;
  }
}

.navigation {
  display: flex;
  justify-content: space-around;
  margin-bottom: $gutterSemiLarge;
  padding: 0 $appSidePadding;

  @include for-size(tablet-portrait-up) {
    justify-content: center;
    padding: 0;
  }
}

.navigation-column {
  @include for-size(phone-only) {
    display: flex;
    flex-direction: column;
    padding: 0 calc(#{$gutterSmall} / 2);
  }

  @include for-size(tablet-portrait-up) {
    &:not(:last-child) {
      margin-right: 50px;
    }
  }
}

.navigation-item {
  align-self: flex-start;
  font-size: 14px;
  line-height: 30px;
  font-weight: $light;
  text-decoration: none;
  color: $darkBlue;
  white-space: nowrap;
  transition: color 0.2s ease-in-out;

  @include for-size(tablet-portrait-up) {
    margin: 0 $gutterMedium;
    width: inherit;
  }

  &:hover {
    color: $sFOrange;
    transition: color 0.2s ease-in-out;
  }

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}

.counter {
  display: inline-block;
  margin-left: 4px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #fff;
  background-color: $sFOrange;
}

.social-media-wrap {
  display: flex;
  margin: auto auto $gutterSmall;
}
