@import "../../theme/variables";

.radio {
  display: flex;
  align-items: center;
  cursor: pointer;

  &--PRIMARY {
    margin-top: $gutterSmall;
    margin-bottom: $gutterMedium;
    border-radius: $radiusMedium;
    padding: 15px $gutterMedium;
    width: 100%;
    background: $offWhite2;
  }

  &--SECONDARY,
  &--TERTIARY {
    padding: 5px 0;
  }

  &--is-checked {
    color: #fff;
    background: $blue;
  }
}

.checkmark {
  &--PRIMARY {
    flex-shrink: 0;
    border: 1px solid $offWhite;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    background: #fff;

    &:after {
      display: none;
    }
  }

  &--SECONDARY {
    flex-shrink: 0;
    border: 1px solid $lightGrey2;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    background: #fff;

    &:after {
      display: none;
    }
  }

  &--TERTIARY {
    flex-shrink: 0;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    background: $offWhite4;

    &:after {
      display: none;
    }
  }
}

.input {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;

  &:checked {
    ~ .checkmark--PRIMARY {
      position: relative;
      border: none;

      &:after {
        content: url("../../theme/svg/IconCheckmark.svg");
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 16px;
        height: 16px;
        transform: translate(-50%, -50%);
      }
    }

    ~ .checkmark--SECONDARY,
    ~ .checkmark--TERTIARY {
      position: relative;
      border: none;

      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        border-radius: 50%;
        width: 6px;
        height: 6px;
        transform: translate(-50%, -50%);
        background: #fff;
      }
    }

    ~ .checkmark--SECONDARY {
      background: $blue;
    }

    ~ .checkmark--TERTIARY {
      background: $yellow;
    }
  }
}

.label {
  display: flex;
  align-items: center;

  &--SECONDARY {
    font-weight: bold;
  }
}

// gap between radio button and label
.gap {
  &--SMALL {
    padding-left: $gutterSmall;
  }

  &--MEDIUM {
    padding-left: $gutterMedium;
  }

  &--SEMI_LARGE {
    padding-left: $gutterSemiLarge;
  }

  &--LARGE {
    padding-left: $gutterLarge;
  }
}
