@import "../../theme/variables";

.panel {
  border-radius: $radiusMedium;
  padding: $gutterSemiLarge $gutterMedium;
  width: 625px;
  max-width: 100%;

  @include for-size(tablet-landscape-up) {
    padding: 15px $gutterMedium;
  }

  & + & {
    margin-top: $gutterSemiLarge;
  }

  &--primary {
    background: $offWhite2;
  }

  &--secondary {
    border: 1px solid $yellow;
    background: $darkBlue2;
  }
}
