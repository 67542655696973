@import "../../theme/variables";

.wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  height: 100%;
  font-size: 18px;
}

.button {
  margin-top: $gutterMedium;
}
