@import "../../theme/variables.scss";

.notification {
  margin-bottom: $gutterSmall;
}

.submit-button {
  &:hover {
    background-color: $blue;
  }
}
