@import "../../../theme/variables";

.intro-wrap {
  width: calc(#{$mobileWidth} + #{$gutterLarge} * 2);
  max-width: 100%;
}

.paragraph {
  color: $lightGrey;
  line-height: 2;
  margin-bottom: $gutterMedium;
}

.small-paragraph {
  color: $lightGrey;
  font-size: 12px;
  line-height: 1.4;
}

.title {
  color: $sFOrange;
  font-size: 30px;
  font-weight: $light;
}
