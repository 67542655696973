@import "../../../../theme/variables";

$topUpPanelOffset: 80px;

.wrap {
  margin: $topUpPanelOffset $gutterSmall 0;
  border-radius: $radiusExtraLarge;
  width: 340px;
  max-width: 100%;
  background: $offWhite4;

  @include for-size(tablet-portrait-up) {
    margin-right: $gutterMedium;
    margin-left: $gutterMedium;
  }

  @include for-size(desktop-up) {
    margin-right: 0;
    margin-left: $gutterLarge;
  }
}

.wrap-content {
  margin: 0 $gutterMedium $gutterLarge;
}

.panel {
  margin-top: 10px;
  margin-bottom: $gutterSemiLarge;
  width: 300px;
  max-width: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include for-size(tablet-portrait-up) {
    margin: 50px $gutterExtraLarge $gutterLarge;
  }
}

.logo {
  margin-bottom: $gutterLarge;
}

.credit {
  font-size: 30px;
  font-weight: bold;
  color: #fff;
}

.label {
  margin-bottom: $gutterSemiLarge;
  font-size: 20px;
  font-weight: $light;
  text-transform: uppercase;
  color: $sFOrange;
}

.button {
  min-width: 180px;
}

.tip-wrap {
  margin: 0 $gutterSmall;
}

.tip-title {
  display: inline-block;
  margin-bottom: $gutterMedium;
  font-weight: bold;
  color: $sFOrange;

  strong {
    text-transform: uppercase;
  }
}

.tip {
  line-height: 25px;
  font-weight: $light;
  color: $darkBlue2;
}
