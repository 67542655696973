@import "../../theme/variables";

.caption {
  display: block;
  margin: 0 auto $gutterMedium auto;
  max-width: 500px;
  color: $lightBlue2;
  font-weight: $light;
  text-align: center;
  line-height: 30px;
}
